import { createSlice } from "@reduxjs/toolkit";
import { getUserPermissions } from "../services/permissionsServices";

const initialState = {
  permissionsMap: null,
  adminPermissions: null,
  loading: true,
  error: null,
};

const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getUserPermissions.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.adminPermissions = null;
    });
    builder.addCase(getUserPermissions.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      const adminPermissions = action.payload.adminPermissions;
      const permissionsMap = action.payload.permMap;
      state.adminPermissions = adminPermissions;
      state.permissionsMap = permissionsMap;
      // if (adminPermissions.length) {
      //   adminPermissions.forEach((element) => {
      //     permissionsMap.forEach((element1) => {
      //       if (element?.module_name === element1?.moduleName) {
      //         element1?.permissions.forEach((el) => {
      //           if (element.permission === el.key) {
      //             const filteredArr = [
      //               ...permissionsMap.filter(
      //                 (item) => item?.moduleName === element?.module_name
      //               ),
      //             ];
      //             // console.log({ ...permissionsMap });
      //           }
      //         });
      //       }
      //     });
      //   });
      // }
    });
    builder.addCase(getUserPermissions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
      state.adminPermissions = null;
    });
  },
});

export default permissionsSlice.reducer;
