import React from "react";
import { UserLabelInfo } from "../..";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FaRegHeart } from "react-icons/fa6";
import { BiComment } from "react-icons/bi";
import styles from "./.module.scss";

const ViewPostModal = (props) => {
  const { t } = useTranslation();

  return (
    <div className={`d-flex flex-column ${styles.post_container}`}>
      {!props?.media?.length ? (
        <div className={styles.card_image}>
          <img
            className={`${styles.image} d-block mx-auto`}
            src="https://talkcat.vercel.app/assets/notify.png"
            alt="DefaultImage"
          />
        </div>
      ) : (
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          className={"posts_slider w-100"}
        >
          {props?.media?.map((media) => (
            <SwiperSlide key={media?.id}>
              <div
                style={{ padding: "40px" }}
                className={`${styles.card_image} d-flex align-items-center justify-content-center`}
              >
                <img
                  style={{
                    width: "100%",
                    maxHeight: "290px",
                    objectFit: "contain",
                  }}
                  className={styles.image}
                  src={media?.url}
                  alt="postMedia"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <div className="d-flex align-items-center justify-content-between my-4">
        <div
          className={`d-flex align-items-center justify-content-between w-100 ${styles.user_info}`}
        >
          <UserLabelInfo
            img={
              props?.user?.profile_photo ||
              "https://tse2.mm.bing.net/th?id=OIP.vvmpWt0qBu3LeBgZuUfmGAHaFt&pid=Api&P=0&h=220"
            }
            name={props?.user?.full_name || "User Name"}
            userID={props?.user?.id}
          />
          <div className={styles.facts}>
            <div className={styles.fact}>
              <FaRegHeart className={styles.icon} />
              <span className={styles.value}>{props?.count_love}</span>
            </div>
            <div className={styles.fact}>
              <BiComment className={`${styles.icon} ${styles.comment}`} />
              <span className={styles.value}>{props?.count_comment}</span>
            </div>
          </div>
        </div>
      </div>
      <p className={styles.post_text}>{props?.text}</p>
      <button
        // onClick={props.onDelete}
        onClick={props.onClick}
        className={styles.delete_button}
      >
        {t("details.delete")}
      </button>
    </div>
  );
};

export default ViewPostModal;
