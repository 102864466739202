import React, { useState } from "react";
import { Header, Sidebar } from "../../components";
import { Outlet } from "react-router-dom";

const DashboardLayout = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  return (
    <div className={`main_layout`}>
      <Header
        setOpenMobileMenu={setOpenMobileMenu}
        openMobileMenu={openMobileMenu}
      />
      <Sidebar openMobileMenu={openMobileMenu} />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default DashboardLayout;
