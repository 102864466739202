import validators from "../../../util/validators";
import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button, Input, ModalPopup } from "../..";
import { toggleModal } from "../../../redux/features/modalSlice";
import { useApi } from "../../../hooks/useApi";
import { toast } from "react-toastify";
import { getAllMembers } from "../../../redux/services/memberServices";
import SelectAdapter from "./SelectAdapter";

const EditMemberBalance = ({ title, dispatch, item, options }) => {
  const { t } = useTranslation();
  const { loading, onRequest, data } = useApi(
    "/members/update-user-balance",
    "post"
  );

  const formElements = [
    {
      type: "select",
      icon: "gift",
      name: "chargeType",
      placeholder: t("forms.enter_charge_type"),
      label: t("forms.charge_type"),
      component: SelectAdapter,
      options: [
        { label: t("forms.charge"), value: "charge" },
        { label: t("forms.free_charge"), value: "charge-free" },
      ],
      validate: validators.composeValidators(
        validators.required("Charge Type")
      ),
    },
    {
      type: "number",
      icon: "diamond",
      name: "diamond_balance",
      component: Input,
      placeholder: t("forms.enter_diamond_balance"),
      label: t("forms.gold_diamond"),
      validate: validators.composeValidators(
        validators.required("Diamonds Balance"),
        validators.minLength("Diamonds Balance", 0)
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      toast.success(data?.message || t("Saved update successfully"));
      dispatch(toggleModal(""));
      dispatch(getAllMembers(options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ModalPopup title={title}>
      <Form
        initialValues={{ diamond_balance: item?.diamonds_balance }}
        onSubmit={(e) => {
          onRequest({
            userId: item?.id,
            balance: +parseFloat(e?.diamond_balance),
            chargeType: e?.chargeType.value,
          });
        }}
      >
        {({ handleSubmit, _, valid }) => (
          <form className={`w-100`} onSubmit={handleSubmit}>
            <div className={` d-flex flex-column gap-5 mb-5`}>
              {formElements.map((item, i) => (
                <Field
                  key={i}
                  component={item.component}
                  placeholder={item.placeholder}
                  icon={item.icon}
                  type={item.type}
                  name={item.name}
                  id={item.type}
                  label={item.label}
                  validate={item.validate}
                  options={item.options}
                />
              ))}
            </div>
            <div className="d-flex align-items-center justify-content between gap-4">
              <Button
                type="submit"
                disabled={!valid}
                submitting={loading}
                bgColor="#0ACF83"
              >
                {t("details.save_edits")}
              </Button>
              <Button
                onClick={() => dispatch(toggleModal(""))}
                type="button"
                outline
              >
                {t("details.cancel")}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </ModalPopup>
  );
};

export default EditMemberBalance;
