import React from "react";
import styles from "./button.module.scss";

const Button = ({
  children,
  type,
  onClick,
  disabled,
  submitting,
  bgColor,
  outline,
  deleteBtn,
}) => {
  return (
    <button
      disabled={disabled}
      className={`${styles.submit__btn}  ${
        submitting ? styles.submitting : ""
      } ${outline ? styles.outline : ""}`}
      style={{ backgroundColor: bgColor }}
      type={type || "button"}
      onClick={type !== "submit" ? onClick : null}
    >
      {!submitting && children}
      {submitting && (
        <div
          className={`${styles.spinner} ${
            deleteBtn ? styles.deleteSpinner : ""
          }`}
        ></div>
      )}
    </button>
  );
};

export default Button;
