import React, { useEffect, useState } from "react";
import {
  DeleteCell,
  DeleteModal,
  EditCell,
  Error,
  MainPageDesign,
  CopyTextButton,
} from "../../components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getGiftsCategories } from "../../redux/services/giftsServices";
import { toggleModal } from "../../redux/features/modalSlice";
import { useApi } from "../../hooks/useApi";
import { toast } from "react-toastify";
import AddNewCategoryGift from "../../components/Modals/Gifts/AddNewCategoryGift";
import { PiEyeLight } from "react-icons/pi";
import styles from "./.module.scss";
import ViewGiftsPopUp from "../../components/Modals/Gifts/ViewGiftsPopUp";
import moment from "moment";

const GiftsCategories = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentData, setCurrentData] = useState(null);
  const [currentCategoryGifts, setCurrentCategoryGifts] = useState(null);
  const { giftsCategories } = useSelector((state) => state?.gifts);

  const [options, setOptions] = useState({
    isPaginated: giftsCategories?.totalPages > 1,
    pagination: 1,
    itemsPerPage: 20,
    searchKeyWord: null,
    totalPages: giftsCategories?.totalPages,
    sortType: null,
    sortOption: null,
  });

  useEffect(() => {
    setOptions({
      ...options,
      totalPages: giftsCategories?.totalPages,
      isPaginated: giftsCategories?.totalPages > 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftsCategories?.totalPages, giftsCategories?.list]);

  const table = {
    columns: [
      {
        Header: "table.headers.id",
        accessor: "random_id",
        sortKey: "id",
        sorting: true,
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center jsutify-content-center gap-2">
            <CopyTextButton text={cellInfo?.row?.original?.id || "---"} />
          </div>
        ),
      },
      {
        Header: "Name En",
        accessor: "name",
        sorting: true,
        sortKey: "name",
      },
      {
        Header: "Name Ar",
        accessor: "name_ar",
        sorting: true,
        sortKey: "name_ar",
      },
      {
        Header: "table.headers.associated_gifts",
        accessor: "",
        Cell: (cellInfo) => {
          console.log();

          return (
            <div className="d-flex align-items-center justify-content-center gap-2">
              {cellInfo?.row?.original?.gift?.length ? (
                <button
                  className={styles.view__btn}
                  onClick={() => {
                    setCurrentCategoryGifts(cellInfo.row.original.gift);
                    dispatch(toggleModal("view__category__gifts"));
                  }}
                >
                  <PiEyeLight />
                </button>
              ) : (
                <p style={{ margin: 0, textTransform: "capitalize" }}>
                  {t("table.headers.no_associated_gifts")}
                </p>
              )}
            </div>
          );
        },
      },
      {
        Header: "created at",
        accessor: "created_at",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center justify-content-center gap-2">
            {moment(cellInfo?.row?.original?.createdAt).fromNow()}
          </div>
        ),
      },
      {
        Header: "table.headers.action",
        accessor: "",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <EditCell
              onClick={() => {
                dispatch(toggleModal("create-gift-category"));
                setCurrentData(cellInfo?.row?.original);
              }}
            />
            <DeleteCell
              onClick={() => {
                dispatch(toggleModal("delete-modal"));
                setCurrentData(cellInfo?.row?.original);
              }}
            />
          </div>
        ),
      },
    ],
    data: giftsCategories.list,
  };

  useEffect(() => {
    dispatch(getGiftsCategories(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.pagination,
    options.searchKeyWord,
    options.sortOption,
    options?.sortType,
  ]);

  const { id: modalID } = useSelector((state) => state?.modal);

  useEffect(() => {
    !modalID && setCurrentData(null);
  }, [modalID]);

  const {
    loading: isSubmitting,
    onRequest,
    data,
  } = useApi(`gift-category/${currentData?.id}`, "delete");

  useEffect(() => {
    if (data?.error) {
      toast.error(data?.error || "something wend wrong!");
      return;
    }
    if (data) {
      toast.success(t("category deleted successfully"));
      dispatch(getGiftsCategories(options));
      dispatch(toggleModal(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (giftsCategories?.error)
    return <Error msg={giftsCategories?.error?.message} />;

  return (
    <>
      <MainPageDesign
        searchBar
        title={t("gift's categories")}
        no={`${giftsCategories?.list?.length || "0"} ${t("gift's categories")}`}
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        data={table?.data || []}
        columns={table?.columns || []}
        tableLoading={giftsCategories?.loading}
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        options={options || {}}
        setOptions={setOptions}
        addButton
        onOpenPopup={() => dispatch(toggleModal("create-gift-category"))}
      />
      {modalID === "create-gift-category" && (
        <AddNewCategoryGift
          options={options}
          setOptions={setOptions}
          currentData={currentData}
          setCurrentData={setCurrentData}
        />
      )}
      {modalID === "delete-modal" && (
        <DeleteModal
          options={options}
          setOptions={setOptions}
          dispatch={dispatch}
          action={onRequest}
          afterAction={() => {
            setOptions({ ...options, pagination: 1 });
            dispatch(getGiftsCategories(options));
          }}
          itemID={currentData?.id}
          isSubmitting={isSubmitting}
          type={t("category")}
          text={t("Doy You Really Want To Kick Out This Category?")}
          onClose={() => dispatch(toggleModal(""))}
        />
      )}
      {modalID === "view__category__gifts" && (
        <ViewGiftsPopUp gifts={currentCategoryGifts} />
      )}
    </>
  );
};

export default GiftsCategories;
