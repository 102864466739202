import React, { useState } from "react";
import ModalPopup from "../../UI/ModalPopup";
import { useDispatch } from "react-redux";
import validators from "../../../util/validators";
import { useTranslation } from "react-i18next";
import { toggleModal } from "../../../redux/features/modalSlice";
import DragDropFiled from "../../UI/DragDropFiled";
import { Button, Input, MainSelectBox } from "../..";
import { Field, Form } from "react-final-form";
import { toast } from "react-toastify";
import { getAllItems } from "../../../redux/services/categoriesServices";

const CategoryItem = ({ init, id, type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [image, setImage] = useState(init?.image || null);
  //   const [icon, setIcon] = useState(init?.icon || null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // types of Badges
  const options = [
    {
      id: "1",
      label: t("Entry Effect"),
    },
    {
      id: "2",
      label: t("Ride"),
    },
    {
      id: "3",
      label: t("ChatBubble"),
    },
    {
      id: "4",
      label: t("MicFrame"),
    },
  ];

  const [badgeType, setBadgeType] = useState(
    options?.find((option) => option?.id == init?.category_id)
  );

  const formElements = [
    {
      type: "text",
      name: "nameEn",
      placeholder: t("Enter English Name"),
      label: t("English Name"),
      validate: validators.composeValidators(validators.required("")),
    },
    {
      type: "text",
      name: "nameAr",
      placeholder: t("Enter Arabic Name"),
      label: t("Arabic Name"),
      validate: validators.composeValidators(validators.required("")),
    },
    {
      type: "number",
      name: "price",
      placeholder: t("Enter Price"),
      label: t("price"),
      validate: validators.composeValidators(validators.required("")),
    },
    {
      type: "number",
      name: "wear_duration",
      placeholder: t("Enter wear duration"),
      label: t("wear duration"),
      validate: validators.composeValidators(validators.required("")),
    },
  ];

  const elements = [
    {
      type: "text",
      name: "nameEn",
      placeholder: t("Enter English Name"),
      label: t("English Name"),
      validate: validators.composeValidators(validators.required("")),
    },
  ];

  // ====== onSubmit (add or update) ====== //
  const onSubmit = async (e) => {
    const formData = new FormData();

    formData.append("category_id", e?.type);
    formData.append("name_ar", e?.nameAr);
    formData.append("name", e?.nameEn);
    formData.append("price", e?.price);
    formData.append("wear_duration", e?.wear_duration);
    formData.append("image", e?.image);

    const requestOptions = {
      method: id ? "PUT" : "POST",
      body: formData,
      redirect: "follow",
    };

    try {
      setIsSubmitting(true);
      const res = await fetch(
        id
          ? `https://tkstg.t-wasel.com/admin-category-item/item/update`
          : "https://tkstg.t-wasel.com/admin-category-item/item/create",
        requestOptions
      );
      const res2 = await res?.json();
      if (res2?.data) {
        toast.success(
          id ? t("Saved update successfully") : t("Saved successfully")
        );
        dispatch(toggleModal(""));
        dispatch(getAllItems(type));
      } else {
        typeof res2?.message === "object" &&
          res2?.message?.map((msg) => toast.error(msg || "error"));
        typeof res2?.message === "string" &&
          !res2?.status &&
          toast.error(res2?.message || "error");
      }
      setIsSubmitting(false);
    } catch (err) {
      console.log(err);
      setIsSubmitting(false);
    }
    setIsSubmitting(false);
  };

  return (
    <ModalPopup title={init ? t("update") : t("add")}>
      <Form
        initialValues={{
          nameEn: init?.name,
          nameAr: init?.name_ar,
          ...init,
        }}
        onSubmit={async (e) => onSubmit({ ...e, image, type: badgeType?.id })}
      >
        {({ handleSubmit, _, valid }) => (
          <form className={`w-100`} onSubmit={handleSubmit}>
            <div className={` d-flex flex-column gap-5 mb-5`}>
              {!id &&
                formElements.map((item, i) => (
                  <Field
                    key={i}
                    component={Input}
                    placeholder={item.placeholder}
                    icon={item.icon}
                    type={item.type}
                    name={item.name}
                    id={item.type}
                    label={item.label}
                    validate={item.validate}
                  />
                ))}
              {id &&
                elements.map((item, i) => (
                  <Field
                    key={i}
                    component={Input}
                    placeholder={item.placeholder}
                    icon={item.icon}
                    type={item.type}
                    name={item.name}
                    id={item.type}
                    label={item.label}
                    validate={item.validate}
                  />
                ))}
              {!id && (
                <DragDropFiled
                  setImage={setImage}
                  image={image}
                  label={t("Image")}
                />
              )}
              <div className="d-flex align-items-center gap-4">
                <span style={{ fontSize: "14px", color: "#707070" }}>
                  {t("Select badge's type")}
                </span>
                <MainSelectBox
                  // defaultText="select badge's type"
                  options={options}
                  active={badgeType}
                  onActive={setBadgeType}
                  id="gift-categories"
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content between gap-4">
              <Button
                type="submit"
                disabled={!valid || !image || !badgeType}
                submitting={isSubmitting}
                bgColor="#0ACF83"
              >
                {init ? t("details.save_edits") : t("details.add")}
              </Button>
              <Button
                onClick={() => dispatch(toggleModal(""))}
                type="button"
                outline
              >
                {t("details.cancel")}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </ModalPopup>
  );
};

export default CategoryItem;
