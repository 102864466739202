import React from "react";
import styles from "./.module.scss";
import { DropdownIcon } from "../../../icons";
import { useDispatch } from "react-redux";
import { toggleModal } from "../../../redux/features/modalSlice";

const ModalPopup = ({ children, title, noBackBtn, textCenter }) => {
  const dispatch = useDispatch();

  return (
    <div className={`${styles.modal_container}`}>
      <>
        <div
          className={styles.overlay}
          onClick={() => dispatch(toggleModal(""))}
        ></div>
        <div
          className={`${styles.modal} ${
            textCenter ? "text-center" : ""
          } modal-content`}
        >
          <div
            className={`${styles.modal_header} d-flex align-items-center ${
              textCenter ? "justify-content-center" : ""
            }`}
          >
            {noBackBtn ? (
              <></>
            ) : (
              <button
                type="button"
                onClick={() => dispatch(toggleModal(""))}
                className={`${styles.back_btn} d-flex align-items-center justify-content-center`}
              >
                <DropdownIcon />
              </button>
            )}
            <h4 className={styles.modal_title}>{title}</h4>
          </div>
          <div className={styles.popup_content}>{children}</div>
        </div>
      </>
    </div>
  );
};

export default ModalPopup;
