import React, { useEffect, useState } from "react";
import validators from "../../../util/validators";
import { Field, Form } from "react-final-form";
import { Input, Button, DragDropFiled } from "../..";
import { useTranslation } from "react-i18next";
import styles from "./.module.scss";
import { ModalPopup } from "../..";
import { useDispatch } from "react-redux";
import { toggleModal } from "../../../redux/features/modalSlice";
import { toast } from "react-toastify";
import { getDesignStoreItems } from "../../../redux/services/designStoreItemsServices";

const AddNewDesign = ({
  options,
  setOptions,
  init,
  id,
  type,
  categoryType,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [image, setImage] = useState(init?.image);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formElements = [
    {
      type: "text",
      name: "name",
      placeholder: t("forms.enter_design_name"),
      label: t("forms.item_name"),
      validate: validators.composeValidators(
        validators.required("Item Name"),
        validators.minLength("item_name", 4)
      ),
      value: init?.name,
    },
    {
      type: "text",
      icon: "",
      name: "name_ar",
      placeholder: t("forms.enter_design_name"),
      label: t("forms.item_name_ar"),
      validate: validators.composeValidators(
        validators.required("Item Ar Name"),
        validators.minLength("item_name", 4)
      ),
      value: init?.name_ar,
    },
    // {
    //   type: "number",
    //   icon: "",
    //   name: "item_id",
    //   placeholder: `000000`,
    //   label: t("forms.enter_item_id"),
    //   validate: validators.composeValidators(
    //     validators.required("Item Id"),
    //     validators.minLength("item_id", 4)
    //   ),
    // },
    // {
    //   type: "number",
    //   icon: "",
    //   name: "order",
    //   placeholder: `000000`,
    //   label: t("forms.order"),
    //   validate: validators.composeValidators(
    //     validators.required("Order"),
    //     validators.minLength("order", 4)
    //   ),
    // },
    // {
    //   type: "text",
    //   icon: "",
    //   name: "section",
    //   placeholder: t("forms.enter_section"),
    //   label: t("forms.section"),
    //   validate: validators.composeValidators(
    //     validators.required("Section"),
    //     validators.minLength("section", 4)
    //   ),
    // },
    // {
    //   type: "text",
    //   icon: "",
    //   name: "type",
    //   placeholder: t("forms.select_type"),
    //   label: t("forms.type"),
    //   validate: validators.composeValidators(
    //     validators.required("Type"),
    //     validators.minLength("type", 4)
    //   ),
    // },
    {
      type: "number",
      icon: "",
      name: "price",
      placeholder: t("forms.enter_gold_amount"),
      label: t("forms.price"),
      validate: validators.composeValidators(validators.required("price")),
      value: init?.price,
    },
    {
      type: "number",
      icon: "",
      name: "wear_duration",
      placeholder: t("forms.enter_days_no"),
      label: t("forms.available_days"),
      validate: validators.composeValidators(
        validators.required("Available Days")
      ),
      value: init?.wear_duration,
    },
  ];

  // ====== onSubmit ====== //
  const onSubmit = async (e) => {
    const formData = new FormData();
    formData.append("name", e?.name);
    formData.append("name_ar", e?.name_ar);
    formData.append("price", e?.price);
    formData.append("wear_duration", e?.wear_duration);
    formData.append(
      "category_id",
      (() => {
        switch (categoryType) {
          case `entery_effect`:
            return 1;
          case `ride`:
            return 2;
          case `chat_buuble`:
            return 3;
          case `mic_frame`:
            return 4;
          default:
            return 5;
        }
      })()
    );
    formData.append("image", e?.image);
    init?.id && formData.append("id", init?.id);

    const requestOptions = {
      method: init?.id ? "PUT" : "POST",
      body: formData,
      redirect: "follow",
    };

    try {
      setIsSubmitting(true);
      const res = await fetch(
        init?.id
          ? `https://tkstg.t-wasel.com/admin-category-item/item/update`
          : "https://tkstg.t-wasel.com/admin-category-item/item/create",
        requestOptions
      );
      const res2 = await res?.json();
      if (
        res2?.message === "Successfully" ||
        res2.statusCode === 200 ||
        res2.statusCode === 201
      ) {
        toast.success(
          init?.id ? t("Saved update successfully") : t("Saved successfully")
        );
        dispatch(getDesignStoreItems(options));
        dispatch(toggleModal(""));
      }
    } catch (err) {
      setIsSubmitting(false);
    }
    setIsSubmitting(false);
  };

  return (
    <ModalPopup>
      <Form
        initialValues={init}
        currentDataialValues={init}
        onSubmit={(e) =>
          onSubmit({
            ...e,
            image,
            id: init?.id,
          })
        }
      >
        {({ handleSubmit, submitting, valid }) => (
          <form className={`w-100`} onSubmit={handleSubmit}>
            <div
              className={`${styles.inputs_container} d-flex flex-column gap-4 mb-4`}
            >
              <DragDropFiled
                setImage={setImage}
                image={image || init?.image}
                label={t("Image")}
              />
              <div className={styles.inputs_box}>
                {formElements.map((item, i) => (
                  <Field
                    key={i}
                    component={Input}
                    placeholder={item.placeholder}
                    icon={item.icon}
                    type={item.type}
                    name={item.name}
                    id={item.type}
                    label={item.label}
                    validate={item.validate}
                    initialValue={item?.value}
                  />
                ))}
              </div>
            </div>
            <Button type="submit" disabled={!valid} submitting={submitting}>
              {id ? `${t(`details.edit`)}` : `${t(`details.add`)}`}
            </Button>
          </form>
        )}
      </Form>
    </ModalPopup>
  );
};

export default AddNewDesign;
