import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { toast } from "react-toastify";

export const getMemberDetails = createAsyncThunk(
  "members/getMemberDetails",
  async ({ id, filterParam }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await api.get(
        `/members/user-info?id=${id}&transaction_type=${filterParam}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Error !");
      return rejectWithValue(error?.response?.data);
    }
  }
);
