import React, { useEffect } from "react";
import { DateIcon, DeleteIcon, ShowEyeIcon } from "../../../icons";
import { DeleteModal, ModalPopup, UserLabelInfo, ViewPostModal } from "../..";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../../redux/features/modalSlice";
import { useApi } from "../../../hooks/useApi";
import { toast } from "react-toastify";
import { getPosts } from "../../../redux/services/postsServices";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styles from "./.module.scss";
import moment from "moment";
import { FaRegHeart } from "react-icons/fa6";
import { BiComment } from "react-icons/bi";

const SinglePost = (props) => {
  const { t } = useTranslation();
  const { id } = useSelector((state) => state?.modal);

  const {
    loading: isSubmitting,
    onRequest,
    data,
  } = useApi(`admin-post/delete/${props?.id}`, "delete");
  const dispatch = useDispatch();

  const { totalPages } = useSelector((state) => state.posts);

  useEffect(() => {
    if (data) {
      toast.success(data?.message || "Post deleted successfully");
      dispatch(toggleModal(""));
      props.setOptions({
        isPaginated: totalPages > 1,
        pagination: 1,
        itemsPerPage: 20,
        searchKeyWord: null,
        totalPages,
        sortType: null,
        sortOption: null,
      });
      dispatch(getPosts(props.options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className={styles.card}>
        {!props?.media?.length ? (
          <div className={styles.card_image}>
            <img
              className={styles.image}
              src="https://talkcat.vercel.app/assets/notify.png"
              alt="DefaultImage"
            />
          </div>
        ) : (
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            modules={[Navigation, Pagination]}
            navigation
            pagination={{ clickable: true }}
            className={"posts_slider"}
          >
            {props?.media?.map((media) => (
              <SwiperSlide key={media?.id}>
                {media?.url ? (
                  <div className={styles.card_image}>
                    <img
                      className={styles.image}
                      src={media?.url}
                      alt="postMedia"
                    />
                  </div>
                ) : (
                  <></>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        <div
          className={`d-flex align-items-center justify-content-between w-100 ${styles.user_info}`}
        >
          <UserLabelInfo
            img={
              props?.user?.profile_photo ||
              "https://tse2.mm.bing.net/th?id=OIP.vvmpWt0qBu3LeBgZuUfmGAHaFt&pid=Api&P=0&h=220"
            }
            name={props?.user?.full_name || "User Name"}
            userID={props?.user?.id}
          />
          <div className={styles.facts}>
            <div className={styles.fact}>
              <FaRegHeart className={styles.icon} />
              <span className={styles.value}>{props?.count_love}</span>
            </div>
            <div className={styles.fact}>
              <BiComment className={`${styles.icon} ${styles.comment}`} />
              <span className={styles.value}>{props?.count_comment}</span>
            </div>
          </div>
        </div>
        {props?.text && <p className={styles.desc}>{props?.text}</p>}
        <div
          className={`d-flex align-items-center gap-2 justify-content-between ${styles.card_footer}`}
        >
          <div className="d-flex align-items-center gap-1">
            <DateIcon />
            {`Published ${moment(props?.created_at).fromNow()}`}
          </div>
          <div className="d-flex align-items-center jsutify-content-end gap-3">
            <button
              type="button"
              onClick={() =>
                dispatch(toggleModal(`show-post-${props?.id}-modal`))
              }
            >
              <ShowEyeIcon />
            </button>
            <button
              type="button"
              onClick={() =>
                dispatch(toggleModal(`delete-modal__${props?.id}`))
              }
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
      </div>
      {id === `show-post-${props?.id}-modal` && (
        <ModalPopup
          title={t("modal.view_post_title")}
          id={`modal_view_post${id}`}
        >
          <ViewPostModal
            onClick={() => dispatch(toggleModal(`delete-modal__${props?.id}`))}
            userID={props?.user?.id}
            onDelete={() => onRequest()}
            {...props}
          />
        </ModalPopup>
      )}
      {id === `delete-modal__${props?.id}` && (
        <DeleteModal
          options={props.options}
          setOptions={props.setOptions}
          dispatch={dispatch}
          action={onRequest}
          afterAction={props?.action}
          itemID={props?.id}
          text={t("modal.delete_post_text")}
          type={t("post")}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
};

export default SinglePost;
