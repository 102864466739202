import React, { useState } from "react";
import { SwitchActiveIcon, SwitchInActiveIcon } from "../../../icons";
import styles from "./.module.scss";

const SwitchButton = ({
  handleToggle,
  isOn,
  postRequest,
  deleteRequest,
  item,
  el,
  userID,
}) => {
  const [isActive, setIsActive] = useState(isOn);
  return (
    <button
      type="button"
      className={`${styles.switch_btn_container} ${
        isActive ? "" : styles.unchecked
      }`}
      onClick={async () => {
        handleToggle && (await handleToggle());
        !handleToggle &&
          (async function () {
            isActive
              ? await deleteRequest({
                  moduleName: item?.moduleName,
                  permission: el.key,
                  adminId: userID,
                })
              : await postRequest({
                  moduleName: item?.moduleName,
                  permission: el.key,
                  adminId: userID,
                });
          })();
        setIsActive(!isActive);
      }}
    >
      <span className={styles.switch_icon}>
        {isActive ? <SwitchActiveIcon /> : <SwitchInActiveIcon />}
      </span>
    </button>
  );
};

export default SwitchButton;
