import React from "react";
import { ModalPopup } from "../..";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";
import CopyTextButton from "../../UI/CopyTextButton";
import { GoldIcon } from "../../../icons";
import approx from "approximate-number";

const ViewGiftsPopUp = ({ gifts }) => {
  const { t } = useTranslation();
  return (
    <ModalPopup title={t("table.headers.associated_gifts")}>
      <div className={styles.gifts__continer}>
        <div className="row">
          {gifts.map((gift, i) => {
            console.log(gift);
            return (
              <div className={`col-12 col-lg-6`} key={i}>
                <div className={styles.gift}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: ".25rem",
                    }}
                  >
                    <div className={styles.img__wrapper}>
                      <img className={styles.img} src={gift.icon} alt="icon" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p className="mx-3">{gift?.name}</p>
                      <div style={{ transform: "scale(.7)" }}>
                        <CopyTextButton text={gift.id} />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center jsutify-content-center gap-2">
                    <GoldIcon />
                    <div className={styles.gold_cell}>
                      {approx(gift.coin || 0, {
                        capital: true,
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </ModalPopup>
  );
};

export default ViewGiftsPopUp;
