import React from "react";
import styles from "./.module.scss";
import { CircleImage } from "../..";
import { useTranslation } from "react-i18next";

const UserLabelInfo = ({
  userID,
  img,
  alt,
  name,
  online,
  activeStatus,
  label,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.label__container} d-flex align-items-center`}>
      <div className={styles.user_image}>
        <CircleImage img={img} alt={alt} />
        {activeStatus && (
          <div
            className={`${styles.user_notify_active} ${
              !online ? styles.offline : ""
            }`}
          ></div>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {name && <span className={styles.label}>{name || "--"}</span>}
        <span style={{ opacity: ".5", fontSize: "12px", fontWeight: "300" }}>
          {userID && `${t("userinfo.user_id")} : ${userID}`}
        </span>
      </div>
    </div>
  );
};

export default UserLabelInfo;
