import React, { useEffect } from "react";
import { DeleteIcon } from "../../../icons";
import { DeleteModal, ModalPopup } from "../..";
import { useTranslation } from "react-i18next";
import styles from "./.module.scss";
import { toggleModal } from "../../../redux/features/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import AvatarModal from "../../Modals/AvatarModal";
import { useApi } from "../../../hooks/useApi";
import { toast } from "react-toastify";

const ThemeCard = ({ type, path, itemID, options, setOptions, action }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useSelector((state) => state?.modal);

  const { onRequest, data: reqData } = useApi(
    `/admin-${type === "theme" ? "background" : type}/delete/${itemID}`,
    "delete",
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  useEffect(() => {
    if (reqData) {
      setOptions({
        ...options,
        pagination: 1,
      });
      dispatch(toggleModal(""));
      dispatch(action(options));
      toast.success(reqData?.message || t("Saved update successfully"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData]);

  return (
    <>
      <div className={styles.theme_card}>
        <div className={styles.card_image}>
          <img src={path} alt="theme" />
        </div>
        <div className="d-flex align-items-center justify-content-between w-100 gap-3">
          {/* <h4 className={styles.card_title}>{itemID}</h4> */}
          {/* {date && <span className={styles.date}>29/5/2023</span>} */}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className={styles.main_themeXXX}>
            {/* {type ? t(`details.${type}_main`) : t("details.main_theme")} */}
          </div>
          {/* {link && (
            <div className={styles.link}>
              tans link <OpenNewPageIcon />
            </div>
          )} */}
          <div className="d-flex align-items-center gap-3">
            <button
              type="button"
              onClick={() => {
                dispatch(toggleModal(`open__edit__theme__popup_${itemID}`));
              }}
              className="d-flex align-items-center justify-content-center"
            >
              <FiEdit className={styles.icon} />
            </button>
            <button
              className="d-flex align-items-center justify-content-center"
              type="button"
              onClick={() => {
                dispatch(toggleModal(`open__delete__theme__popup_${itemID}`));
              }}
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
      </div>
      {id === `open__edit__theme__popup_${itemID}` && (
        <ModalPopup
          dispatch={dispatch}
          title={t(`modal.edit_${type}`)}
          id={`Modal__edit__${id}`}
        >
          <AvatarModal
            options={options}
            setOptions={setOptions}
            action={action}
            dispatch={dispatch}
            itemID={itemID}
            type={type}
            edit_id={id}
          />
        </ModalPopup>
      )}
      {id === `open__delete__theme__popup_${itemID}` && (
        <DeleteModal
          dispatch={dispatch}
          options={options}
          setOptions={setOptions}
          action={onRequest}
          afterAction={action}
          itemID={itemID}
          text={t("modal.delete_avatar_text")}
          type={type}
        />
      )}
    </>
  );
};

export default ThemeCard;
