import React from "react";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import { PaginationControl } from "react-bootstrap-pagination-control";
import {
  DownSortingIcon,
  SortingTableIcon,
  UpSortingIcon,
} from "../../../icons";
import styles from "./.module.scss";
import TableLoading from "../TableLoading";
import EmptyTable from "../Table/EmptyTable";

const MainTable = ({
  options,
  setOptions,
  data,
  columns,
  getTableProps,
  headerGroups,
  getTableBodyProps,
  page,
  prepareRow,
  tableLoading,
  pageCount,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.table_container}>
        <table className={`${styles.table} table`} {...getTableProps()}>
          <thead className={styles.table_head}>
            {headerGroups?.length &&
              headerGroups?.map((headerGroup, index) => (
                <tr
                  key={index}
                  className={styles.table_header_tr}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup?.headers?.map((column) => {
                    return (
                      <th
                        className={styles.table_th}
                        onClick={() => {
                          if (column.sorting) {
                            setOptions({
                              ...options,
                              sortOption:
                                options.sortOption === null
                                  ? "asc"
                                  : options.sortOption === "asc"
                                  ? "desc"
                                  : null,
                              sortType: column.sortKey,
                            });
                          }
                        }}
                        // {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        <div
                          className={styles.item}
                          style={{
                            cursor: column.sorting ? "pointer" : "initial",
                          }}
                        >
                          {t(column.render("Header"))}
                          <span className={styles.sorting_btn}>
                            {column?.sorting ? (
                              column.sortKey === options?.sortType &&
                              options.sortOption ? (
                                options.sortOption === "desc" ? (
                                  <span className={styles.sorting_icon}>
                                    <DownSortingIcon />
                                  </span>
                                ) : (
                                  <span className={styles.sorting_icon}>
                                    <UpSortingIcon />
                                  </span>
                                )
                              ) : (
                                <span className={styles.sorting_icon}>
                                  <SortingTableIcon />
                                </span>
                              )
                            ) : (
                              <></>
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
          </thead>
          {tableLoading ? (
            <TableLoading columns={columns?.length} />
          ) : (
            <tbody className={styles.table_body} {...getTableBodyProps()}>
              {page?.map((row, i) => {
                prepareRow(row);
                return (
                  <tr className={styles.table_tr} {...row.getRowProps()}>
                    {row.cells?.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>
                          <div className={styles.item}>
                            {cell.render("Cell")}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {!tableLoading && !data?.length ? <EmptyTable /> : <></>}
        {!pageCount && data?.length ? <EmptyTable /> : <></>}
      </div>
      {options?.isPaginated && (
        <div className={styles.PaginationControl}>
          <PaginationControl
            changePage={(current) => {
              setOptions({ ...options, pagination: current });
            }}
            page={options.pagination}
            total={options.totalPages * options.itemsPerPage}
            limit={options?.itemsPerPage}
            ellipsis={1}
          />
        </div>
      )}
    </>
  );
};

export default MainTable;
