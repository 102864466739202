import React, { useEffect, useState } from "react";
import {
  DeleteCell,
  DeleteModal,
  EditCell,
  Error,
  MainPageDesign,
  UserLabelInfo,
  CopyTextButton,
} from "../../components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getGifts } from "../../redux/services/giftsServices";
import { toggleModal } from "../../redux/features/modalSlice";
import { useApi } from "../../hooks/useApi";
import { toast } from "react-toastify";
// import { Svga } from "react-svga";
// import svgaIcon from "./test.svga";
import AddNewGift from "../../components/Modals/Gifts/AddNewGift";
import Cookies from "js-cookie";
import approx from "approximate-number";
import { GoldIcon } from "../../icons";
import styles from "./.module.scss";
// import { Parser, Player } from "svga";
import moment from "moment";

const Gifts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = Cookies.get("lang");
  const [currentData, setCurrentData] = useState(null);

  const { gifts } = useSelector((state) => state?.gifts);

  const [options, setOptions] = useState({
    isPaginated: gifts?.totalPages > 1,
    pagination: 1,
    itemsPerPage: 20,
    searchKeyWord: null,
    totalPages: gifts?.totalPages,
    sortType: null,
    sortOption: null,
  });

  useEffect(() => {
    setOptions({
      ...options,
      totalPages: gifts?.totalPages,
      isPaginated: gifts?.totalPages > 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gifts?.totalPages, gifts?.list]);

  const table = {
    columns: [
      {
        Header: "table.headers.id",
        accessor: "random_id",
        sortKey: "id",
        sorting: true,
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center jsutify-content-center gap-2">
            <CopyTextButton text={cellInfo?.row?.original?.id || "---"} />
          </div>
        ),
      },
      {
        Header: "Name En",
        accessor: "name",
        sorting: true,
        sortKey: "name",
      },
      {
        Header: "Name Ar",
        accessor: "name_ar",
        sorting: true,
        sortKey: "name_ar",
      },
      {
        Header: "category",
        accessor: lang === "ar" ? "category.name_ar" : "category.name",
        // sorting: true,
      },
      {
        Header: "Coins",
        accessor: "coin",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center jsutify-content-center gap-2">
            <GoldIcon />
            <div className={styles.gold_cell}>
              {approx(cellInfo?.row?.original?.coin || 0, {
                capital: true,
              })}
            </div>
          </div>
        ),
        // sorting: true
      },
      {
        Header: "Icon",
        accessor: "icon",
        Cell: (cellInfo) => (
          <UserLabelInfo img={cellInfo?.row?.original?.icon} alt="user image" />
        ),
      },
      // {
      //   Header: "Image",
      //   accessor: "image",
      //   Cell: (cellInfo) => {
      //     // return (
      //     //   <canvas
      //     //     style={{ width: "60px" }}
      //     //     id={`canvas__${cellInfo?.row?.original?.id}__${cellInfo?.row?.original?.name}`}
      //     //   ></canvas>
      //     // );
      //     return <Svga className={"svga"} src={svgaIcon} />;
      //   },
      // },
      {
        Header: "type",
        accessor: "type",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center jsutify-content-center gap-2">
            <div
              style={{
                textTransform: "capitalize",
                fontWeight: 600,
                color: "green",
              }}
            >
              {cellInfo?.row?.original?.type}
            </div>
          </div>
        ),
        // sorting: true
      },
      {
        Header: "created at",
        accessor: "created_at",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center justify-content-center gap-2">
            {moment(cellInfo?.row?.original?.createdAt).fromNow()}
          </div>
        ),
      },
      {
        Header: "table.headers.action",
        accessor: "",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <EditCell
              onClick={() => {
                setCurrentData(cellInfo.row.original);
                dispatch(toggleModal(`create-gift-category`));
              }}
            />
            <DeleteCell
              onClick={() => {
                setCurrentData(cellInfo.row.original);
                dispatch(toggleModal(`delete-modal`));
              }}
            />
          </div>
        ),
      },
    ],
    data: gifts?.list,
  };

  useEffect(() => {
    dispatch(getGifts(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.pagination,
    options.searchKeyWord,
    options.sortOption,
    options?.sortType,
  ]);

  const { id: modalID } = useSelector((state) => state?.modal);
  useEffect(() => {
    !modalID && setCurrentData(null);
  }, [modalID]);

  const {
    loading: isSubmitting,
    onRequest,
    data,
  } = useApi(`gifts/delete/${currentData?.id}`, "delete");

  useEffect(() => {
    if (data) {
      toast.success(data?.message || t("deleted successfully"));
      dispatch(toggleModal(""));
      dispatch(getGifts(options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // useEffect(() => {
  //   for (let i = 0; i < gifts?.list?.length; i++) {
  //     (async () => {
  //       const element = gifts?.list[i];
  //       const parser = new Parser();
  //       const svga = await parser.load(svgaIcon);
  //       const player = new Player(
  //         document.getElementById(`canvas__${element.id}__${element.name}`)
  //       );
  //       console.log(player);
  //       player.mount(svga);
  //       player.start();
  //     })();
  //   }
  // }, [gifts?.list]);

  if (gifts?.error) return <Error msg={gifts?.error?.message} />;

  return (
    <>
      <MainPageDesign
        searchBar
        title={t("Gifts")}
        no={`${gifts?.list?.length || "0"} ${t("Gifts")}`}
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        data={table?.data || []}
        columns={table?.columns || []}
        tableLoading={gifts?.loading}
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        options={options || {}}
        setOptions={setOptions}
        addButton
        onOpenPopup={() => dispatch(toggleModal("create-gift-category"))}
      />
      {modalID === "create-gift-category" && (
        <AddNewGift
          options={options}
          setOptions={setOptions}
          currentData={currentData}
          setCurrentData={setCurrentData}
        />
      )}
      {modalID === "delete-modal" && (
        <DeleteModal
          options={options}
          setOptions={setOptions}
          dispatch={dispatch}
          action={onRequest}
          afterAction={() => {
            setOptions({ ...options, pagination: 1 });
            dispatch(getGifts(options));
          }}
          itemID={currentData?.id}
          isSubmitting={isSubmitting}
          type={t("gift")}
          text={t("Doy You Really Want To Kick Out This Category?")}
          onClose={() => dispatch(toggleModal(""))}
        />
      )}
    </>
  );
};

export default Gifts;
