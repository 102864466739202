import { createSlice } from "@reduxjs/toolkit";
import { getGiftsTransactionsServices } from "../services/giftsTransactionsServices";
const initialState = {
  loading: true,
  error: null,
  totalPages: null,
  totalTransactions: null,
  TP: null,
  transactions: [],
};

const giftsTransactionsSlice = createSlice({
  name: "giftsTransactionsSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getGiftsTransactionsServices.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getGiftsTransactionsServices.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.transactions = action?.payload?.transactions || [];
      state.totalPages = action.payload.totalPages;
      state.TP = action.payload.totalPages;
    });
    builder.addCase(getGiftsTransactionsServices.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default giftsTransactionsSlice.reducer;
