import React, { useEffect, useState } from "react";
import {
  MainCard,
  ModalPopup,
  PageHeader,
  PageTitle,
  Error,
} from "../../../components";

import { useTranslation } from "react-i18next";
import styles from "./.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getMembersAvatars } from "../../../redux/services/membersAvatarsServices";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { AddIcon } from "../../../icons";
import ThemeCard from "../../../components/UI/ThemeCard";
import { toggleModal } from "../../../redux/features/modalSlice";
import Loading from "./loading";
import AddMemberAvatar from "../../../components/Modals/AddMemberAvatar";

const MembersAvatars = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch(0);
  const { id } = useSelector((state) => state?.modal);

  const { avatars, loading, error, totalPages } = useSelector(
    (state) => state.membersAvatars
  );

  const [options, setOptions] = useState({
    isPaginated: totalPages > 1,
    pagination: 1,
    itemsPerPage: 20,
    searchKeyWord: null,
    totalPages,
    sortType: null,
    sortOption: null,
  });

  useEffect(() => {
    setOptions({
      ...options,
      totalPages: totalPages,
      isPaginated: totalPages > 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, avatars]);

  useEffect(() => {
    dispatch(getMembersAvatars(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.pagination,
    options.searchKeyWord,
    options.sortOption,
    options?.sortType,
  ]);

  if (error) return <Error msg={error?.message} />;

  return (
    <>
      <div className={styles.page}>
        <MainCard>
          <PageHeader>
            <div
              className={`d-flex align-items-center justify-content-between w-100`}
            >
              <div className={`d-flex align-items-center gap-3`}>
                <PageTitle title={t("members.avatars")} />
                <span className="dash"></span>
                <div className={`members_no`}>
                  {avatars?.length} {t("members.avatar")}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end gap-3">
                <button
                  type="button"
                  onClick={() => {
                    dispatch(toggleModal("open__add__avatar__popup"));
                  }}
                  className={`main_btn add_btn d-flex align-items-center justify-content gap-2`}
                >
                  <AddIcon />
                  {t("details.add")}
                </button>
              </div>
            </div>
          </PageHeader>
          <div className={styles.notify_cards_container}>
            {loading ? (
              Array(8)
                .fill("")
                .map((_, i) => <Loading key={i} />)
            ) : avatars?.length ? (
              avatars.map((avatar, i) => {
                return (
                  <ThemeCard
                    key={i}
                    type={"avatar"}
                    // ////////////////////////////////
                    // ////////////////////////////////
                    // ////////////////////////////////
                    path={avatar.path}
                    itemID={avatar.id}
                    // ////////////////////////////////
                    // ////////////////////////////////
                    // ////////////////////////////////
                    options={options}
                    setOptions={setOptions}
                    action={getMembersAvatars}
                  />
                );
              })
            ) : (
              <></>
            )}
          </div>
          {!loading && options?.isPaginated && (
            <div className={styles.PaginationControl}>
              <PaginationControl
                changePage={(current) => {
                  setOptions({ ...options, pagination: current });
                }}
                page={options.pagination}
                total={options.totalPages * options.itemsPerPage}
                limit={options.itemsPerPage}
                ellipsis={1}
              />
            </div>
          )}
        </MainCard>
      </div>
      {id === "open__add__avatar__popup" && (
        <ModalPopup
          dispatch={dispatch}
          title={t("modal.add_avatar")}
          id={`Modal__add__member __avatar`}
        >
          <AddMemberAvatar
            options={options}
            setOptions={setOptions}
            dispatch={dispatch}
            getMembersAvatars={getMembersAvatars}
          />
        </ModalPopup>
      )}
    </>
  );
};

export default MembersAvatars;
