import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { toast } from "react-toastify";

export const getAllRoomUsers = createAsyncThunk(
  "rooms/getAllRoomUsers",
  async (
    { pagination, itemsPerPage, searchKeyWord, sortType, sortOption, status },
    thunkAPI
  ) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await api.get(`room-admin/getAllRoomInfo`, {
        params: {
          page: pagination,
          perPage: itemsPerPage,
          keyword: searchKeyWord,
          sortOption,
          sortType,
          status,
        },
      });
      return res?.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Error !");
      return rejectWithValue(error?.response?.data);
    }
  }
);
