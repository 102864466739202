import React from "react";
import { ModalPopup } from "../..";
import styles from "./.module.scss";
import { CopyTextButton } from "../../";
import moment from "moment";
// import { useTranslation } from "react-i18next";
const ViewReportPopUp = ({ currentItem }) => {
  const getReportActions = () => {
    return currentItem.action.split("\n");
  };
  // const { t } = useTranslation();
  return (
    <ModalPopup
    // title={t("table.headers.associated_gifts")}
    >
      <div className={styles.report__details__wrapper}>
        <div className="row">
          <div className="py-3">
            <div className={styles.report_id}>
              <span className={styles.heading}>Report ID:</span>
              <CopyTextButton text={currentItem.id} />
            </div>
          </div>
          {currentItem?.report_image && (
            <div className="py-3">
              <div className={styles.report__img__wrapper}>
                <div className="mb-3">
                  <span className={styles.heading}>Report Image</span>
                </div>
                <img
                  className={styles.report__img}
                  src={currentItem?.report_image}
                  alt="report__img"
                />
              </div>
            </div>
          )}
          {currentItem?.action && (
            <div className="py-3">
              <div className={styles.report__img__wrapper}>
                <div className="mb-3">
                  <span className={styles.heading}>Report Actions:</span>
                  <ul className={styles.actions__list}>
                    {getReportActions().map((field) => {
                      const date = field.split("(&$&)")[0];
                      const value = field.split("(&$&)")[1];
                      return (
                        <li className={styles.action__item}>
                          <span>{value}</span>
                          <span className={styles.date}>
                            {moment(date).fromNow()}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalPopup>
  );
};

export default ViewReportPopUp;
