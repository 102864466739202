import React, { useEffect, useState } from "react";
import { UserIdIcon } from "../../../icons";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";

const CopyTextButton = ({ text }) => {
  const [copied, setCopyied] = useState(false);
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();

  const copyContent = async () => {
    setCopyied(false);
    try {
      await navigator.clipboard.writeText(text);
      console.log("Content copied to clipboard");
      setCopyied(true);
    } catch (err) {
      setCopyied(false);
      console.error("Failed to copy: ", err);
    }
    // setLoading(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) {
        setCopyied(false);
      }
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  return (
    <div>
      <button
        className={styles.button}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={copyContent}
      >
        {hover && (
          <p className={styles.copy}>{copied ? t("copied") : t("copy")}</p>
        )}
        <UserIdIcon />#{text}
      </button>
    </div>
  );
};

export default CopyTextButton;
