import React, { useState } from "react";
import { SwitchActiveIcon, SwitchInActiveIcon } from "../../../../icons";
import styles from "./.module.scss";

const ToggleBtn = ({ status, onRequest }) => {
  const [active, setActive] = useState(status);

  return (
    <button
      type="button"
      className={`${styles.switch_btn_container} ${
        active ? "" : styles.unchecked
      }`}
      onClick={async () => {
        onRequest && (await onRequest());
        setActive(!active);
      }}
    >
      <span className={styles.switch_icon}>
        {active ? <SwitchActiveIcon /> : <SwitchInActiveIcon />}
      </span>
    </button>
  );
};

export default ToggleBtn;
