import React, { useEffect, useState } from "react";
import {
  AddRoomTheme,
  MainCard,
  ModalPopup,
  PageHeader,
  PageTitle,
  Error,
} from "../../../components";
import { AddIcon } from "../../../icons";
import styles from "./.module.scss";
import ThemeCard from "../../../components/UI/ThemeCard";
import { useTranslation } from "react-i18next";
import { toggleModal } from "../../../redux/features/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "./loading";
import { roomThemesServices } from "../../../redux/services/roomThemesServices";
import { PaginationControl } from "react-bootstrap-pagination-control";

const RoomsThemes = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useSelector((state) => state?.modal);
  const { error, loading, themes, totalPages } = useSelector(
    (state) => state?.roomsThemes
  );

  const [options, setOptions] = useState({
    isPaginated: totalPages > 1,
    pagination: 1,
    itemsPerPage: 20,
    searchKeyWord: null,
    totalPages,
    sortType: null,
    sortOption: null,
  });

  useEffect(() => {
    setOptions({
      ...options,
      totalPages: totalPages,
      isPaginated: totalPages > 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, themes]);

  useEffect(() => {
    dispatch(roomThemesServices(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.pagination,
    options.searchKeyWord,
    options.sortOption,
    options?.sortType,
  ]);

  if (error) return <Error msg={error?.message} />;

  return (
    <>
      <div className={styles.page}>
        <MainCard>
          <PageHeader>
            <div
              className={`d-flex align-items-center justify-content-between w-100`}
            >
              <div className={`d-flex align-items-center gap-3`}>
                <PageTitle title={t("rooms.room_themes.title")} />
                <span className="dash"></span>
                <div className={`members_no`}>
                  {themes?.length} {t("details.theme")}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end gap-3">
                <button
                  type="button"
                  onClick={() => {
                    dispatch(toggleModal("open__add__theme__popup"));
                  }}
                  className={`main_btn add_btn d-flex align-items-center justify-content gap-2`}
                >
                  <AddIcon />
                  {t("details.add")}
                </button>
              </div>
            </div>
          </PageHeader>
          <div className={styles.notify_cards_container}>
            {loading ? (
              Array(8)
                .fill("")
                .map((_, i) => <Loading key={i} />)
            ) : themes?.length ? (
              themes.map((theme, i) => {
                return (
                  <ThemeCard
                    type={"theme"}
                    // //////////////////////////////////////////
                    // //////////////////////////////////////////
                    // //////////////////////////////////////////
                    path={theme.path}
                    itemID={theme.id}
                    // //////////////////////////////////////////
                    // //////////////////////////////////////////
                    // //////////////////////////////////////////
                    options={options}
                    setOptions={setOptions}
                    action={roomThemesServices}
                  />
                );
              })
            ) : (
              <></>
            )}
          </div>
          {!loading && options?.isPaginated && (
            <div className={styles.PaginationControl}>
              <PaginationControl
                changePage={(current) => {
                  setOptions({ ...options, pagination: current });
                }}
                page={options.pagination}
                total={options.totalPages * options.itemsPerPage}
                limit={options.itemsPerPage}
                ellipsis={1}
              />
            </div>
          )}
        </MainCard>
      </div>
      {id === "open__add__theme__popup" && (
        <ModalPopup title={t("modal.add_theme")} id={`Modal__add__room__theme`}>
          <AddRoomTheme
            options={options}
            setOptions={setOptions}
            dispatch={dispatch}
            getRoomsThemes={roomThemesServices}
          />
        </ModalPopup>
      )}
    </>
  );
};

export default RoomsThemes;
