import React from "react";
import { Button, ModalPopup } from "../..";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";
import { toggleModal } from "../../../redux/features/modalSlice";

const DeleteModal = ({ action, afterAction, type, isSubmitting, dispatch }) => {
  const { t } = useTranslation();

  return (
    <ModalPopup
      // eslint-disable-next-line no-useless-concat
      title={t("Do You Really Want To Delete This") + ` ${type}` + ` ${t(`?`)}`}
    >
      <p className={styles.text}>{t("delete_des")}</p>
      <div className="d-flex align-items-center justify-content between gap-4">
        <Button
          deleteBtn
          onClick={async () => {
            await action();
            afterAction();
          }}
          submitting={isSubmitting}
          outline
        >
          {t("details.delete")}
        </Button>
        <Button
          onClick={() => dispatch(toggleModal(" "))}
          type="button"
          bgColor="#0ACF83"
        >
          {t("details.cancel")}
        </Button>
      </div>
    </ModalPopup>
  );
};

export default DeleteModal;
