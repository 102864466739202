import React, { useEffect, useState } from "react";
import {
  DeleteCell,
  DeleteModal,
  EditCell,
  Error,
  MainPageDesign,
} from "../../components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllItems } from "../../redux/services/categoriesServices";
import { toggleModal } from "../../redux/features/modalSlice";
// import { Svga } from "react-svga";
// import svgaIcon from "../Gifts/test.svga";
import CategoryItem from "../../components/Modals/CategoryItem";
import { toast } from "react-toastify";
import { useApi } from "../../hooks/useApi";

const MicFrame = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [currentItem, setCurrentItem] = useState(null);
  const {
    error,
    items,
    loading: tableLoading,
  } = useSelector((state) => state?.categories);
  const { id } = useSelector((state) => state?.modal);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllItems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!id) {
      setCurrentItem(null);
    }
  }, [id]);

  const table = {
    columns: [
      { Header: "Name En", accessor: "name", sorting: true },
      { Header: "Name Ar", accessor: "name_ar", sorting: true },
      // {
      //   Header: "Image",
      //   accessor: "img",
      //   Cell: (cellInfo) => {
      //     return (
      //       <>
      //         <Svga className={"svga"} src={svgaIcon} />
      //       </>
      //     );
      //   },
      // },
      { Header: "price", accessor: "price", sorting: true },
      { Header: "number of sales", accessor: "number_of_sales", sorting: true },
      { Header: "wear duration", accessor: "wear_duration", sorting: true },
      {
        Header: "table.headers.action",
        accessor: "",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <EditCell
              onClick={() => {
                dispatch(toggleModal("category-item-modal"));
                setCurrentItem(cellInfo?.row?.original);
              }}
            />
            <DeleteCell
              onClick={() => {
                dispatch(toggleModal("delete-modal"));
                setCurrentItem(cellInfo?.row?.original);
              }}
            />
          </div>
        ),
      },
    ],
    data: items?.filter((item) => item?.category_id === 4),
  };

  // onDelete
  const {
    loading: isSubmitting,
    onRequest,
    data,
  } = useApi(`admin-category-item/item/delete/${currentItem?.id}`, "delete");

  useEffect(() => {
    if (data?.error) {
      toast.error(data?.error || "something wend wrong!");
      return;
    }
    if (data) {
      toast.success(data?.message || t("deleted successfully"));
      dispatch(toggleModal(""));
      dispatch(getAllItems());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error msg={error?.message} />;

  return (
    <>
      <MainPageDesign
        title={t("MicFrame")}
        // no="25k"
        searchBar
        addButton
        data={table?.data || []}
        columns={table?.columns || []}
        onOpenPopup={() => dispatch(toggleModal("category-item-modal"))}
        page={page}
        setPage={setPage}
        tableLoading={tableLoading}
        // ========================
      />
      {id === "delete-modal" && (
        <DeleteModal
          type={t("item")}
          onClose={() => dispatch(toggleModal(""))}
          onDelete={() => onRequest()}
          isSubmitting={isSubmitting}
        />
      )}
      {id === "category-item-modal" && (
        <CategoryItem init={currentItem} id={currentItem?.id} />
      )}
    </>
  );
};

export default MicFrame;
