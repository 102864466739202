import React from "react";
import styles from "./.module.scss";
import { SearchIcon } from "../../../icons";
import { useTranslation } from "react-i18next";
import { useAsyncDebounce } from "react-table";
const SearchBox = ({ globalFilter, setGlobalFilter, options, setOptions }) => {
  const { t } = useTranslation();
  // const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    // setGlobalFilter(value || undefined);
    setOptions({ ...options, pagination: 1, searchKeyWord: value });
  }, 1000);

  return (
    <div className={`${styles.search_box} d-flex align-items-center`}>
      <label htmlFor="search__input" className="d-flex align-items-center">
        <SearchIcon />
      </label>
      <input
        type="text"
        className={styles.search_input}
        placeholder={t("Search")}
        id="search__input"
        // =================
        // value={value || ""}
        onChange={(e) => {
          // setValue(e.target.value);
          onChange(e?.target?.value?.trim());
        }}
      />
    </div>
  );
};

export default SearchBox;
