import React from "react";
import validators from "../../../util/validators";
import { Field, Form } from "react-final-form";
import { Button, Input } from "../..";
import styles from "./module.scss";
import { useTranslation } from "react-i18next";

const AddAgentModal = ({ edit, data }) => {
  const { t } = useTranslation();

  const formElements = [
    {
      type: "text",
      icon: "user",
      name: "user",
      placeholder: t("forms.enter_name"),
      label: t("forms.select_user"),
      validate: validators.composeValidators(
        validators.required("User Or ID"),
        validators.minLength("User Or ID", 4)
      ),
    },
    {
      type: "number",
      icon: "mobile",
      name: "phone",
      placeholder: t("froms.enter_phone_number"),
      label: t("forms.phone_number"),
      validate: validators.composeValidators(
        validators.required("Mobile Number"),
        validators.phoneNumber
      ),
    },
  ];

  return (
    <Form
      initialValues={
        data
          ? {
              user: data?.user_name,
              phone: data?.whatsapp
                .replace(/-/g, "")
                .replace("+", "")
                .replace(" ", ""),
            }
          : {}
      }
      onSubmit={(e) => {
        console.log(e);
      }}
    >
      {({ handleSubmit, submitting, valid }) => (
        <form className={`w-100`} onSubmit={handleSubmit}>
          <div
            className={`${styles.inputs_container} d-flex flex-column gap-5 mb-5`}
          >
            {formElements.map((item, i) => (
              <Field
                key={i}
                component={Input}
                placeholder={item.placeholder}
                icon={item.icon}
                type={item.type}
                name={item.name}
                id={item.type}
                label={item.label}
                validate={item.validate}
              />
            ))}
          </div>
          {edit ? (
            <div className="d-flex align-items-center justify-content between gap-4">
              <Button
                type="submit"
                disabled={!valid}
                submitting={submitting}
                bgColor="#0ACF83"
              >
                {t("details.save_edits")}
              </Button>
              <Button closeModal={true} type="button" outline>
                {t("details.cancel")}
              </Button>
            </div>
          ) : (
            <Button type="submit" disabled={!valid} submitting={submitting}>
              {t("details.add_agent")}
            </Button>
          )}
        </form>
      )}
    </Form>
  );
};

export default AddAgentModal;
