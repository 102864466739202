import { createSlice } from "@reduxjs/toolkit";
import { getGifts, getGiftsCategories } from "../services/giftsServices";

const initialState = {
  gifts: {
    list: null,
    totalPages: null,
    total: null,
    TP: null,
    loading: true,
    error: null,
  },
  giftsCategories: {
    list: null,
    totalPages: null,
    total: null,
    TP: null,
    loading: true,
    error: null,
  },
};

const giftsSlice = createSlice({
  name: "gifts",
  initialState,
  extraReducers: (builder) => {
    // ===== categories ======= //
    builder.addCase(getGiftsCategories.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.gifts = null;
    });
    builder.addCase(getGiftsCategories.fulfilled, (state, action) => {
      state.giftsCategories = {
        ...state.giftsCategories,
        list: action?.payload?.giftCategoryList,
        totalPages: action.payload.totalPages,
        TP: action.payload.totalPages,
        loading: false,
        error: false,
      };
    });
    builder.addCase(getGiftsCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.gifts = null;
    });
    // =====================//
    // ====== gifts ======= //
    // =====================//
    builder.addCase(getGifts.pending, (state) => {
      state.gifts = { ...state.gifts, loading: true, error: null };
    });
    builder.addCase(getGifts.fulfilled, (state, action) => {
      state.gifts = {
        ...state.gifts,
        list: action?.payload?.giftList,
        totalPages: action.payload.totalPages,
        TP: action.payload.totalPages,
        loading: false,
        error: false,
      };
    });
    builder.addCase(getGifts.rejected, (state, action) => {
      state.gifts = {
        ...state.gifts,
        loading: false,
        error: action.payload || { message: "Error!" },
      };
    });
  },
});

// export const { logout } = memberSlice.actions;
export default giftsSlice.reducer;
