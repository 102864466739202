import validators from "../../../util/validators";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button, DragDropFiled, Input, MainSelectBox, ModalPopup } from "../..";
import { toggleModal } from "../../../redux/features/modalSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getLevels } from "../../../redux/services/levelservices";
import { use } from "i18next";

const ModalLevel = ({ init, id, type, options: reqOptions }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const options = [
    { id: "charisma-level", label: t("charisma-level") },
    { id: "charge-level", label: t("charge-level") },
    { id: "personal-level", label: t("personal_level") },
  ];
  const [active, setActive] = useState(
    options?.find((option) => option?.id === type)
  );
  useEffect(() => {
    setActive(options?.find((option) => option?.id === type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const [image, setImage] = useState(init?.level_img || null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formElements = [
    {
      type: "text",
      name: "nameEn",
      placeholder: t("Enter English Name"),
      label: t("English Name"),
      validate: validators.composeValidators(validators.required("")),
      value: init?.name,
    },
    {
      type: "text",
      name: "nameAr",
      placeholder: t("Enter Arabic Name"),
      label: t("Arabic Name"),
      validate: validators.composeValidators(validators.required("")),
      value: init?.nameAr,
    },
    {
      type: "number",
      name: "level_number",
      placeholder: t("Enter Level Number"),
      label: t("Level Number"),
      validate: validators.composeValidators(validators.required("")),
      value: init?.level_number,
    },
    {
      type: "number",
      name: "exp",
      placeholder: t("Enter exp"),
      label: t("exp"),
      validate: validators.composeValidators(validators.required("")),
      value: init?.exp,
    },
  ];

  // // on submit
  // const { loading, onRequest, data } = useApi(
  //   init ? `/levels/update/${id}` : "/levels/create-level",
  //   id ? "put" : "post"
  // );

  // useEffect(() => {
  //   if (data) {
  //     toast.success(
  //       id ? t("Saved update successfully") : t("Saved successfully")
  //     );
  //     dispatch(toggleModal(""));
  //     dispatch(getLevels(type || "charisma-level"));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  // ====== onSubmit (add or update) ====== //
  const onSubmit = async (e) => {
    // console.log(e);
    const formData = new FormData();
    formData.append("typeLevel", e?.typeLevel);
    formData.append("level_number", e?.level_number);
    formData.append("name", e?.name);
    formData.append("nameAr", e?.nameAr);
    formData.append("exp", e?.exp);
    formData.append("level_img", e?.level_img);

    const requestOptions = {
      method: id ? "PUT" : "POST",
      body: formData,
      redirect: "follow",
    };

    try {
      setIsSubmitting(true);
      const res = await fetch(
        id
          ? `https://tkstg.t-wasel.com/levels/update/${id}`
          : "https://tkstg.t-wasel.com/levels/create-level",
        requestOptions
      );
      const res2 = await res?.json();
      if (
        res2?.message === "Level created successfully" ||
        res2?.message === "Level updated successfully"
      ) {
        // toast.success(res2?.massage || "Done");
        toast.success(
          id ? t("Saved update successfully") : t("Saved successfully")
        );
        dispatch(toggleModal(""));
        dispatch(getLevels(reqOptions));
      } else {
        typeof res2?.message === "object" && toast.error(res2?.message[0]);
        typeof res2?.message === "string" && toast.error(res2?.message);
      }
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
    }
    setIsSubmitting(false);
  };

  return (
    <ModalPopup title={init ? t("update level") : t("Add New level")}>
      <Form
        initialValues={{
          nameEn: init?.name,
          ...init,
        }}
        onSubmit={async (e) =>
          onSubmit({
            ...e,
            name: e?.nameEn,
            exp: +e?.exp,
            level_number: +e?.level_number,
            typeLevel: type,
            level_img: image,
          })
        }
      >
        {({ handleSubmit, _, valid }) => (
          <form className={`w-100`} onSubmit={handleSubmit}>
            <div className={` d-flex flex-column gap-5 mb-5`}>
              {formElements.map((item, i) => (
                <Field
                  key={i}
                  component={Input}
                  placeholder={item.placeholder}
                  icon={item.icon}
                  type={item.type}
                  name={item.name}
                  id={item.type}
                  label={item.label}
                  validate={item.validate}
                  initialValue={item.value}
                />
              ))}
              <DragDropFiled
                setImage={setImage}
                image={image || init?.level_img}
                label={t("Image")}
              />
              {!init && (
                <div className="d-flex align-items-center gap-4">
                  <span style={{ fontSize: "14px", color: "#707070" }}>
                    {t("Select level type")}
                  </span>
                  <MainSelectBox
                    // defaultText="select gift's category"
                    options={options}
                    active={active}
                    onActive={setActive}
                    id="gift-categories"
                  />
                </div>
              )}
            </div>
            <div className="d-flex align-items-center justify-content between gap-4">
              <Button
                type="submit"
                // disabled={!valid || !active || (!image && !id)}
                submitting={isSubmitting}
                bgColor="#0ACF83"
              >
                {/* {t("details.save_edits")} */}
                {init ? t("details.save_edits") : t("details.add")}
              </Button>
              <Button
                onClick={() => dispatch(toggleModal(""))}
                type="button"
                outline
              >
                {t("details.cancel")}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </ModalPopup>
  );
};

export default ModalLevel;
