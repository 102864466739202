import { createSlice } from "@reduxjs/toolkit";
import { getPosts } from "../services/postsServices";

const initialState = {
  posts: null,
  loading: true,
  error: null,
  totalPages: null,
  totalPosts: null,
};

const postsSlice = createSlice({
  name: "post",
  initialState,
  extraReducers: (builder) => {
    // ===== login ======= //
    builder.addCase(getPosts.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.posts = null;
    });
    builder.addCase(getPosts.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.posts = action.payload.data;
      state.totalPages = action?.payload?.totalPages;
      state.totalPosts = action?.payload?.totalPosts;
    });
    builder.addCase(getPosts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
      state.posts = null;
    });
  },
});

export default postsSlice.reducer;
