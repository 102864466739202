import { createSlice } from "@reduxjs/toolkit";
import { getAllMembers } from "../services/memberServices";

const initialState = {
  allMembers: null,
  members: null,
  loading: true,
  error: null,
  active: true,
  totalPages: null,
  TP: null,
};

const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    onActiveFilter(state, action) {
      if (action?.payload === "active") {
        state.members = state?.allMembers?.filter((member) => member?.active);
        state.totalPages = state.TP;
        state.active = true;
      } else {
        state.members = state?.allMembers?.filter((member) => !member?.active);
        state.totalPages =
          state?.allMembers?.filter((member) => !member?.active)?.length / 10;
        state.active = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMembers.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.members = null;
      state.allMembers = null;
      state.totalPages = null;
      state.TP = null;
    });
    builder.addCase(getAllMembers.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.allMembers = action.payload?.users;
      state.members = action?.payload?.users;
      state.totalPages = action?.payload?.totalPages;
      state.active = true;
      state.TP = action?.payload?.totalPages;
    });
    builder.addCase(getAllMembers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
      state.members = null;
      state.TP = null;
      state.allMembers = null;
      state.totalPages = null;
    });
  },
});

export const { onActiveFilter } = memberSlice.actions;
export default memberSlice.reducer;
