import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { toast } from "react-toastify";

export const getUserPermissions = createAsyncThunk(
  "permissions/getPermissions",
  async ({ id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await api.get(`/admin-permissions/${id}`);
      console.log(res);
      return res.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Error !");
      return rejectWithValue(error?.response?.data);
    }
  }
);
