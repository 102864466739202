import { Link } from "react-router-dom";
import { IoEyeOutline, IoInformationCircle } from "react-icons/io5";
import approx from "approximate-number";
import ToggleBtn from "../pages/Members/AllMembers/ToggleBtn";
import { useApi } from "../hooks/useApi";
import moment from "moment";

import {
  AllMembersIcon,
  AmountTableIcon,
  BadgesIcon,
  ByManagersIcon,
  CharismaLevels,
  DiscoverIcon,
  GiftsIcon,
  GoldIcon,
  LevelsIcons,
  MasterCardIcon,
  MemberNoIcon,
  MembersBalanceIcon,
  MembersIcon,
  PaypalIcon,
  PinedIcon,
  PostsIcon,
  RoomsIcon,
  ShowEyeIcon,
  VisaIcon,
  WhatsappIcon,
} from "../icons";
import styles from "../components/UI/MainTable/.module.scss";
import { CircleImage, CopyTextButton, UserLabelInfo } from "../components";
import { useTranslation } from "react-i18next";

export const paymentStatics = [
  {
    icon: <MasterCardIcon />,
    title: "home.payment_statics.card_1.title",
    money: "25,000",
  },
  {
    icon: <VisaIcon />,
    title: "home.payment_statics.card_2.title",
    money: "25,000",
  },
  {
    icon: <PaypalIcon />,
    title: "home.payment_statics.card_3.title",
    money: "25,000",
  },
  {
    icon: <DiscoverIcon />,
    title: "home.payment_statics.card_4.title",
    money: "25,000",
  },
];

export const Data = [
  {
    id: 1,
    year: 2016,
    userGain: 80000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345,
  },
  {
    id: 3,
    year: 2018,
    userGain: 78888,
    userLost: 555,
  },
  {
    id: 4,
    year: 2019,
    userGain: 90000,
    userLost: 4555,
  },
  {
    id: 5,
    year: 2020,
    userGain: 4300,
    userLost: 234,
  },
];

export const lastTransColumns = [
  {
    Header: "table.headers.sender_name",
    accessor: "name",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.name}
      />
    ),
  },
  {
    Header: "table.headers.method",
    accessor: "method",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center">
        {cellInfo?.row?.original?.method === "google_pay" ? (
          <img src="/assets/google-pay.png" alt="pay-method" />
        ) : (
          <img src="/assets/visa.png" alt="pay-method" />
        )}
      </div>
    ),
  },
  {
    Header: "table.headers.date",
    accessor: "date",
  },
  {
    Header: "table.headers.trans_id",
    accessor: "transId",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center">
        #{cellInfo?.row?.original?.transId}
      </div>
    ),
  },
  {
    Header: "table.headers.amount",
    accessor: "amount",
    Cell: (cellInfo) => (
      <div className={`${styles.amount_cell} d-flex align-items-center`}>
        <AmountTableIcon />
        {cellInfo?.row?.original?.amount}$
      </div>
    ),
  },
  {
    Header: "table.headers.status",
    accessor: "status",
    Cell: (cellInfo) => {
      const { t } = useTranslation();
      return (
        <div
          className={`${
            cellInfo?.row?.original?.status === "pending"
              ? styles.pending
              : cellInfo?.row?.original?.status === "done"
              ? styles.done
              : ""
          } ${styles.status_cell} d-flex align-items-center`}
        >
          <span className={styles.icon}></span>
          <span>
            {cellInfo?.row?.original?.status === "pending"
              ? t("details.pending")
              : cellInfo?.row?.original?.status === "done"
              ? t("details.done")
              : t("details.failure")}
          </span>
        </div>
      );
    },
  },
];

export const lastTransData = [
  {
    name: "moahmed ahmed",
    method: "google_pay",
    date: "29/9/2023",
    transId: "1551245620",
    amount: 250,
    status: "pending",
  },
  {
    name: "mohamed ali",
    method: "visa",
    date: "29/9/2023",
    transId: "1235987458",
    amount: 150,
    status: "done",
  },
  {
    name: "ahmed taha",
    method: "visa",
    date: "29/7/2023",
    transId: "1256985478",
    amount: 760,
    status: "failure",
  },
  {
    name: "hady mohamed",
    method: "google_pay",
    date: "21/6/2023",
    transId: "5456987459",
    amount: 320,
    status: "pending",
  },
  {
    name: "mustafa ahmed",
    method: "google_pay",
    date: "21/3/2023",
    transId: "3287896542",
    amount: 120,
    status: "failure",
  },
  {
    name: "hesham hamed",
    method: "visa",
    date: "8/7/2023",
    transId: "1235874596",
    amount: 1200,
    status: "done",
  },
];

export const allMembersColumns = [
  {
    Header: "full_name",
    accessor: "full_name",
    sorting: true,
    sortKey: "full_name",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img={cellInfo?.row?.original?.profile_photo}
        alt="user image"
        name={cellInfo?.row?.original?.full_name || "---"}
        activeStatus
        online={cellInfo?.row?.original?.is_online}
      />
    ),
  },
  // {
  //   Header: "table.headers.user_id",
  //   accessor: "user_id",
  //   Cell: (cellInfo) => (
  //     <div className="d-flex align-items-center jsutify-content-center gap-2">
  //       <CopyTextButton text={cellInfo?.row?.original?.random_id || "---"} />
  //     </div>
  //   ),
  // },
  {
    Header: "random_id",
    accessor: "random_id",
    sorting: true,
    sortKey: "random_id",

    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.random_id || "---"} />
      </div>
    ),
    // cellInfo?.row?.original?.random_id || "---",
  },
  {
    Header: "email",
    accessor: "email",
    Cell: (cellInfo) => cellInfo?.row?.original?.email || "---",
  },
  {
    Header: "phone",
    accessor: "phone",
    Cell: (cellInfo) => cellInfo?.row?.original?.phone || "---",
  },
  // {
  //   Header: "profile photo",
  //   accessor: "profile_photo",
  //   Cell: (cellInfo) => (
  //     <UserLabelInfo
  //       img={cellInfo?.row?.original?.profile_photo}
  //       alt="user image"
  //     />
  //   ),
  // },
  // {
  //   Header: "balance",
  //   accessor: "balance",
  //   Cell: (cellInfo) => cellInfo?.row?.original?.balance || "0",
  // },
  {
    Header: "gender",
    accessor: "gender",
    Cell: (cellInfo) => {
      const { t } = useTranslation();
      return cellInfo?.row?.original?.gender
        ? t(`details.${cellInfo?.row?.original?.gender}`)
        : t("details.null");
    },
  },
  {
    Header: "active",
    accessor: "hosting_room",
    Cell: (cellInfo) => {
      const { loading, onRequest, data } = useApi(
        `members/change-user-status/${cellInfo?.row?.original?.id}`,
        "post"
      );
      return (
        <ToggleBtn
          loading={loading}
          onRequest={onRequest}
          data={data}
          status={cellInfo?.row?.original?.active}
        />
      );
      // return cellInfo?.row?.original?.active ? (
      //   <span className={styles.active}>{t("details.yes")}</span>
      // ) : (
      //   <span className={styles.unactive}>{t("details.no")}</span>
      // );
    },
  },
  {
    Header: "age",
    accessor: "age",
    Cell: (cellInfo) => cellInfo?.row?.original?.age || "---",
  },
  {
    Header: "country_code",
    accessor: "country_code",
    Cell: (cellInfo) => cellInfo?.row?.original?.country_code || "---",
  },
  {
    Header: "balance",
    accessor: "balance",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <GoldIcon />
        <div className={styles.gold_cell}>
          {approx(cellInfo?.row?.original?.wallet?.balance || 0, {
            capital: true,
          })}
        </div>
      </div>
    ),
    // cellInfo?.row?.original?.balance || "0",
  },

  {
    Header: "is_register",
    accessor: "is_register",
    Cell: (cellInfo) => {
      const { t } = useTranslation();
      return cellInfo?.row?.original?.is_register ? (
        <span className={styles.active}>{t("details.yes")}</span>
      ) : (
        <span className={styles.unactive}>{t("details.no")}</span>
      );
    },
    // cellInfo?.row?.original?.is_register || "---",
  },
  {
    Header: "dailyLoginCount",
    accessor: "dailyLoginCount",
    Cell: (cellInfo) => {
      const { t } = useTranslation();
      return `${cellInfo?.row?.original?.daily_login_count || "0"} ${t(
        "details.times"
      )}`;
    },
  },
  {
    Header: "Language",
    accessor: "language",
    Cell: (cellInfo) =>
      String(cellInfo?.row?.original?.language).toUpperCase() || "---",
  },
  {
    Header: "table.headers.action",
    accessor: "action",
    Cell: (cellInfo) => {
      return (
        <Link
          style={{ color: "#0d6efd", fontSize: "24px" }}
          to={`/member/${cellInfo?.row?.original?.id}`}
          className="d-flex align-items-center justiy-content-center"
        >
          <IoEyeOutline />
        </Link>
      );
    },
  },
];
export const allMembersSumColumns = [
  {
    Header: "full_name",
    accessor: "full_name",
    sorting: true,
    sortKey: "full_name",

    Cell: (cellInfo) => (
      <UserLabelInfo
        img={cellInfo?.row?.original?.profile_photo}
        alt="user image"
        name={cellInfo?.row?.original?.full_name || "---"}
        activeStatus
        online={cellInfo?.row?.original?.is_online}
      />
    ),
  },
  // {
  //   Header: "table.headers.user_id",
  //   accessor: "user_id",
  //   Cell: (cellInfo) => (
  //     <div className="d-flex align-items-center jsutify-content-center gap-2">
  //       <CopyTextButton text={cellInfo?.row?.original?.random_id || "---"} />
  //     </div>
  //   ),
  // },
  {
    Header: "random_id",
    accessor: "random_id",
    sorting: true,
    sortKey: "random_id",

    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.random_id || "---"} />
      </div>
    ),
    // cellInfo?.row?.original?.random_id || "---",
  },
  {
    Header: "email",
    accessor: "email",
    Cell: (cellInfo) => cellInfo?.row?.original?.email || "---",
  },
  // {
  //   Header: "profile photo",
  //   accessor: "profile_photo",
  //   Cell: (cellInfo) => (
  //     <UserLabelInfo
  //       img={cellInfo?.row?.original?.profile_photo}
  //       alt="user image"
  //     />
  //   ),
  // },
  {
    Header: "country_code",
    accessor: "country_code",
    Cell: (cellInfo) => cellInfo?.row?.original?.country_code || "---",
  },
  {
    Header: "is_register",
    accessor: "is_register",
    Cell: (cellInfo) => {
      const { t } = useTranslation();
      return cellInfo?.row?.original?.is_register ? (
        <span className={styles.active}>{t("details.yes")}</span>
      ) : (
        <span className={styles.unactive}>{t("details.no")}</span>
      );
    },
    // cellInfo?.row?.original?.is_register || "---",
  },
  {
    Header: "dailyLoginCount",
    accessor: "dailyLoginCount",
    Cell: (cellInfo) => {
      const { t } = useTranslation();
      return `${cellInfo?.row?.original?.daily_login_count || "0"} ${t(
        "details.times"
      )}`;
    },
  },
  {
    Header: "Language",
    accessor: "language",
    Cell: (cellInfo) =>
      String(cellInfo?.row?.original?.language).toUpperCase() || "---",
  },
  {
    Header: "balance",
    accessor: "balance",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <GoldIcon />
        <div className={styles.gold_cell}>
          {approx(cellInfo?.row?.original?.wallet?.balance || 0, {
            capital: true,
          })}
        </div>
      </div>
    ),
    // cellInfo?.row?.original?.balance || "0",
  },
  // {
  //   Header: "gender",
  //   accessor: "gender",
  //   no_sorting: true,
  //   Cell: (cellInfo) => cellInfo?.row?.original?.gender || "---",
  // },
];

export const allMembersData = [
  {
    user_name: 12,
    user_id: 155124,
    level: 1,
    gold: 21,
    diamonds: 23,
    charge_agent: true,
    hosting: true,
    phone_status: true,
    profile_status: true,
    online: false,
  },
  {
    user_name: 10,
    user_id: 155124,
    level: 1,
    gold: 32,
    diamonds: 32,
    charge_agent: false,
    hosting: true,
    phone_status: false,
    profile_status: true,
    online: true,
  },
  {
    user_name: 12,
    user_id: 155124,
    level: 1,
    gold: 14,
    diamonds: 40,
    charge_agent: true,
    hosting: false,
    phone_status: true,
    profile_status: true,
    online: false,
  },
  {
    user_name: 8,
    user_id: 155124,
    level: 1,
    gold: 53,
    diamonds: 23,
    charge_agent: true,
    hosting: true,
    phone_status: true,
    profile_status: true,
    online: true,
  },
  {
    user_name: 4,
    user_id: 155124,
    level: 1,
    gold: 71,
    diamonds: 23,
    charge_agent: true,
    hosting: true,
    phone_status: true,
    profile_status: true,
    online: false,
  },
  {
    user_name: 20,
    user_id: 155124,
    level: 1,
    gold: 22,
    diamonds: 23,
    charge_agent: true,
    hosting: true,
    phone_status: true,
    profile_status: true,
    online: true,
  },
  {
    user_name: 31,
    user_id: 155124,
    level: 1,
    gold: 18,
    diamonds: 23,
    charge_agent: true,
    hosting: true,
    phone_status: true,
    profile_status: true,
    online: false,
  },
  {
    user_name: 11,
    user_id: 155124,
    level: 1,
    gold: 25,
    diamonds: 23,
    charge_agent: true,
    hosting: true,
    phone_status: true,
    profile_status: true,
    online: false,
  },
];

// export const newGiftsColumns = [
//   {
//     Header: "Name En",
//     accessor: "name",
//     sorting: true,
//   },
//   {
//     Header: "Name Ar",
//     accessor: "name_ar",
//     sorting: true,
//   },
// ];

// export const giftsAttr = [
//   {
//     Header: "Name En",
//     accessor: "name",
//   },
//   {
//     Header: "Name Ar",
//     accessor: "name_ar",
//   },
//   { Header: "Coin", accessor: "coin" },
//   {
//     Header: "Icon",
//     accessor: "icon",
//     Cell: (cellInfo) => (
//       <UserLabelInfo img={cellInfo?.row?.original?.icon} alt="user image" />
//     ),
//   },
// ];

export const roomsBoardColumns = [
  {
    Header: "table.headers.room_name",
    accessor: "room_name",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.room_name}
      />
    ),
  },
  {
    Header: "table.headers.user_id",
    accessor: "user_id",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.user_id} />
        <div>#{cellInfo?.row?.original?.user_id}</div>
      </div>
    ),
  },
  {
    Header: "table.headers.owner",
    accessor: "owner",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.owner}
      />
    ),
  },
  {
    Header: "table.headers.members_no",
    accessor: "members_no",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <MemberNoIcon />
        <div className={styles.member_cell}>
          {cellInfo?.row?.original?.members_no}K
        </div>
      </div>
    ),
  },
  {
    Header: "table.headers.points",
    accessor: "points",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        {cellInfo?.row?.original?.points}
      </div>
    ),
  },
];

export const roomBoardData = [
  {
    room_name: "Aria Chamber",
    user_id: 155124,
    owner: "ahmed taha",
    members_no: 2.5,
    points: 5200,
  },
  {
    room_name: "Aria Chamber",
    user_id: 155124,
    owner: "ahmed taha",
    members_no: 2.5,
    points: 5200,
  },
  {
    room_name: "Aria Chamber",
    user_id: 155124,
    owner: "ahmed taha",
    members_no: 2.5,
    points: 5200,
  },
  {
    room_name: "Aria Chamber",
    user_id: 155124,
    owner: "ahmed taha",
    members_no: 2.5,
    points: 5200,
  },
  {
    room_name: "Aria Chamber",
    user_id: 155124,
    owner: "ahmed taha",
    members_no: 2.5,
    points: 5200,
  },
  {
    room_name: "Aria Chamber",
    user_id: 155124,
    owner: "ahmed taha",
    members_no: 2.5,
    points: 5200,
  },
];

export const charismaBoardColumns = [
  {
    Header: "full_name",
    accessor: "user_name",
    Cell: (cellInfo) => (
      <div className={`${styles.user_name_cell} d-flex align-items-center`}>
        <div className={styles.user_image}>
          <img src="/assets/image_1.png" alt="user-image" />
        </div>
        <span>{cellInfo?.row?.original?.user_name}</span>
      </div>
    ),
  },
  {
    Header: "table.headers.user_id",
    accessor: "user_id",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.user_id} />
        <div>#{cellInfo?.row?.original?.user_id}</div>
      </div>
    ),
  },
  {
    Header: "table.headers.points",
    accessor: "points",
  },
];

export const charismaBoardData = [
  {
    user_name: "moahmed ahmed",
    user_id: 155124,
    points: 5200,
  },
  {
    user_name: "moahmed ahmed",
    user_id: 155124,
    points: 5200,
  },
  {
    user_name: "moahmed ahmed",
    user_id: 155124,
    points: 5200,
  },
  {
    user_name: "moahmed ahmed",
    user_id: 155124,
    points: 5200,
  },
  {
    user_name: "moahmed ahmed",
    user_id: 155124,
    points: 5200,
  },
  {
    user_name: "moahmed ahmed",
    user_id: 155124,
    points: 5200,
  },
];

// export const membersBalanceColumns = [
//   {
//     Header: "full_name",
//     accessor: "",
//     Cell: (cellInfo) => (
//       <UserLabelInfo
//         img={cellInfo?.row?.original?.profile_photo}
//         alt="user image"
//         name={cellInfo?.row?.original?.full_name || "---"}
//         activeStatus
//         online={cellInfo?.row?.original?.is_online}
//       />
//     ),
//   },
//   {
//     Header: "table.headers.user_id",
//     accessor: "user_id",
//     Cell: (cellInfo) => (
//       <div className="d-flex align-items-center jsutify-content-center gap-2">
//         <CopyTextButton text={cellInfo?.row?.original?.id} />
//         <div>#{cellInfo?.row?.original?.id}</div>
//       </div>
//     ),
//   },
//   {
//     Header: "table.headers.level",
//     accessor: "level",
//     Cell: (cellInfo) => (
//       <div className="d-flex align-items-center jsutify-content-center gap-2">
//         <UserLevelIcon />
//         <div className={styles.level_cell}>
//           l.v{cellInfo?.row?.original?.level}
//         </div>
//       </div>
//     ),
//   },
//   // {
//   //   Header: "table.headers.gold",
//   //   accessor: "gold",
//   //   Cell: (cellInfo) => (
//   //     <div className="d-flex align-items-center jsutify-content-center gap-2">
//   //       <GoldIcon />
//   //       <div className={styles.gold_cell}>{cellInfo?.row?.original?.gold}K</div>
//   //     </div>
//   //   ),
//   // },
//   {
//     Header: "table.headers.diamonds",
//     accessor: "diamonds",
//     Cell: (cellInfo) => (
//       <div className="d-flex align-items-center jsutify-content-center gap-2">
//         <img src="/assets/diamond.svg" />
//         <div className={styles.diamond_cell}>
//           {cellInfo?.row?.original?.diamonds_balance}K
//         </div>
//       </div>
//     ),
//   },
//   {
//     Header: "table.headers.charge_agent",
//     accessor: "charge_agent",
//     Cell: (cellInfo) => {
//       const { t } = useTranslation();
//       return cellInfo?.row?.original?.charge_agent ? (
//         <span className={styles.active}>{t("details.yes")}</span>
//       ) : (
//         <span className={styles.unactive}>{t("details.no")}</span>
//       );
//     },
//   },
//   {
//     Header: "table.headers.hosting",
//     accessor: "hosting",
//     Cell: (cellInfo) => {
//       const { t } = useTranslation();
//       return cellInfo?.row?.original?.hosting_room ? (
//         <span className={styles.active}>{t("details.yes")}</span>
//       ) : (
//         <span className={styles.unactive}>{t("details.no")}</span>
//       );
//     },
//   },
//   // {
//   //   Header: "table.headers.phone_status",
//   //   accessor: "phone_status",
//   //   Cell: (cellInfo) => {
//   //     const { t } = useTranslation();
//   //     return cellInfo?.row?.original?.phone_status ? (
//   //       <span className={styles.active}>{t("details.yes")}</span>
//   //     ) : (
//   //       <span className={styles.unactive}>{t("details.no")}</span>
//   //     );
//   //   },
//   // },
//   // {
//   //   Header: "table.headers.profile_status",
//   //   accessor: "profile_status",
//   //   Cell: (cellInfo) => {
//   //     const { t } = useTranslation();
//   //     return cellInfo?.row?.original?.profile_status ? (
//   //       <span className={styles.active}>{t("details.yes")}</span>
//   //     ) : (
//   //       <span className={styles.unactive}>{t("details.no")}</span>
//   //     );
//   //   },
//   // },
//   {
//     Header: "table.headers.diamonds",
//     accessor: "diamonds",
//     Cell: (cellInfo) => (
//       <div className="d-flex align-items-center jsutify-content-center gap-2">
//         <img src="/assets/diamond.svg" />
//         <div className={styles.diamond_cell}>
//           {cellInfo?.row?.original?.diamonds}K
//         </div>
//       </div>
//     ),
//   },
// ];

// export const membersBalanceData = [
//   {
//     user_name: "moahmed ahmed",
//     user_id: 155124,
//     level: 1,
//     gold: 25,
//     diamonds: 23,
//   },
//   {
//     user_name: "moahmed ahmed",
//     user_id: 155124,
//     level: 1,
//     gold: 10,
//     diamonds: 32,
//   },
//   {
//     user_name: "moahmed ahmed",
//     user_id: 155124,
//     level: 1,
//     gold: 15,
//     diamonds: 40,
//   },
//   {
//     user_name: "moahmed ahmed",
//     user_id: 155124,
//     level: 1,
//     gold: 25,
//     diamonds: 23,
//   },
//   {
//     user_name: "moahmed ahmed",
//     user_id: 155124,
//     level: 1,
//     gold: 25,
//     diamonds: 23,
//   },
//   {
//     user_name: "moahmed ahmed",
//     user_id: 155124,
//     level: 1,
//     gold: 25,
//     diamonds: 23,
//   },
//   {
//     user_name: "moahmed ahmed",
//     user_id: 155124,
//     level: 1,
//     gold: 25,
//     diamonds: 23,
//   },
//   {
//     user_name: "moahmed ahmed",
//     user_id: 155124,
//     level: 1,
//     gold: 25,
//     diamonds: 23,
//   },
// ];

export const chargingAgentsColumns = [
  {
    Header: "full_name",
    accessor: "user_name",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.user_name}
        activeStatus
        online={cellInfo?.row?.original?.online}
      />
    ),
  },
  {
    Header: "table.headers.user_id",
    accessor: "user_id",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.user_id} />
        <div>#{cellInfo?.row?.original?.user_id}</div>
      </div>
    ),
  },
  {
    Header: "table.headers.room_name",
    accessor: "room_name",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.room_name}
      />
    ),
  },
  {
    Header: "table.headers.whatsapp",
    accessor: "whatsapp",
    Cell: (cellInfo) => (
      <button
        className={`${styles.whatsapp_cell} d-flex align-items-center gap-2`}
      >
        <WhatsappIcon />
        {cellInfo?.row?.original?.whatsapp}
      </button>
    ),
  },
  {
    Header: "table.headers.room_status",
    accessor: "room_status",
    Cell: (cellInfo) => {
      const { t } = useTranslation();
      return cellInfo?.row?.original?.room_status ? (
        <span className={styles.active}>{t("details.active")}</span>
      ) : (
        <span className={styles.unactive}>{t("details.unactive")}</span>
      );
    },
  },
];

export const chargingAgentsData = [
  {
    user_name: "mohamed ali",
    user_id: 155124,
    room_name: "Aria Chamber",
    whatsapp: "+1 202-202-2710",
    room_status: true,
    online: true,
  },
  {
    user_name: "mohamed ali",
    user_id: 155124,
    room_name: "Aria Chamber",
    whatsapp: "+1 202-202-2710",
    room_status: false,
    online: true,
  },
  {
    user_name: "mohamed ali",
    user_id: 155124,
    room_name: "Aria Chamber",
    whatsapp: "+1 202-202-2710",
    room_status: true,
  },
  {
    user_name: "mohamed ali",
    user_id: 155124,
    room_name: "Aria Chamber",
    whatsapp: "+1 202-202-2710",
    room_status: false,
  },
  {
    user_name: "mohamed ali",
    user_id: 155124,
    room_name: "Aria Chamber",
    whatsapp: "+1 202-202-2710",
    room_status: true,
  },
  {
    user_name: "mohamed ali",
    user_id: 155124,
    room_name: "Aria Chamber",
    whatsapp: "+1 202-202-2710",
    room_status: true,
  },
];

export const activeRoomsColumns = [
  {
    Header: "table.headers.room_name",
    accessor: "room_name",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.room_name}
      />
    ),
  },
  {
    Header: "table.headers.room_id",
    accessor: "room_id",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.room_id} />
        <div>#{cellInfo?.row?.original?.room_id}</div>
      </div>
    ),
  },
  {
    Header: "table.headers.owner",
    accessor: "owner",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.owner}
      />
    ),
  },
  {
    Header: "table.headers.members_no",
    accessor: "members_no",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <MemberNoIcon />
        <div className={styles.diamond_cell}>
          {cellInfo?.row?.original?.members_no}K
        </div>
      </div>
    ),
  },
  {
    Header: "table.headers.room_status",
    accessor: "room_status",
    Cell: (cellInfo) => {
      const { t } = useTranslation();

      return cellInfo?.row?.original?.room_status ? (
        <span className={styles.active}>{t("details.active")}</span>
      ) : (
        <span className={styles.unactive}>{t("details.unactive")}</span>
      );
    },
  },
  {
    Header: "table.headers.topic",
    accessor: "topic",
    Cell: (cellInfo) => (
      <div className={styles.topic_cell}>{cellInfo?.row?.original?.topic}</div>
    ),
  },
  {
    Header: "table.headers.chairs",
    accessor: "chairs",
    Cell: (cellInfo) => (
      <div className={styles.chairs}>{cellInfo?.row?.original?.chairs}</div>
    ),
  },
];

export const activeRoomsData = [
  {
    room_name: "Aria Chamber",
    room_id: 155124,
    owner: "Aria Chamber",
    members_no: 2.5,
    room_status: true,
    topic: "sports",
    chairs: 3,
  },
  {
    room_name: "Aria Chamber",
    room_id: 155124,
    owner: "Aria Chamber",
    members_no: 2.5,
    room_status: false,
    topic: "sports",
    chairs: 3,
  },
  {
    room_name: "Aria Chamber",
    room_id: 155124,
    owner: "Aria Chamber",
    members_no: 2.5,
    room_status: true,
    topic: "sports",
    chairs: 3,
  },
  {
    room_name: "Aria Chamber",
    room_id: 155124,
    owner: "Aria Chamber",
    members_no: 2.5,
    room_status: false,
    topic: "sports",
    chairs: 3,
  },
  {
    room_name: "Aria Chamber",
    room_id: 155124,
    owner: "Aria Chamber",
    members_no: 2.5,
    room_status: false,
    topic: "sports",
    chairs: 3,
  },
];

export const blockedRoomsColumns = [
  {
    Header: "table.headers.room_name",
    accessor: "room_name",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.room_name}
      />
    ),
  },
  {
    Header: "table.headers.room_id",
    accessor: "room_id",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.room_id} />
        <div>#{cellInfo?.row?.original?.room_id}</div>
      </div>
    ),
  },
  {
    Header: "table.headers.owner",
    accessor: "owner",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.owner}
      />
    ),
  },
  {
    Header: "table.headers.members_no",
    accessor: "members_no",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <MemberNoIcon />
        <div className={styles.diamond_cell}>
          {cellInfo?.row?.original?.members_no}K
        </div>
      </div>
    ),
  },
  {
    Header: "table.headers.room_status",
    accessor: "room_status",
    Cell: (cellInfo) => {
      const { t } = useTranslation();
      return cellInfo?.row?.original?.room_status ? (
        <span className={styles.active}>{t("details.active")}</span>
      ) : (
        <span className={styles.unactive}>{t("details.unactive")}</span>
      );
    },
  },
  {
    Header: "table.headers.topic",
    accessor: "topic",
    Cell: (cellInfo) => (
      <div className={styles.topic_cell}>{cellInfo?.row?.original?.topic}</div>
    ),
  },
  {
    Header: "table.headers.chairs",
    accessor: "chairs",
    Cell: (cellInfo) => (
      <div className={styles.chairs}>{cellInfo?.row?.original?.chairs}</div>
    ),
  },
];

export const PinedRoomsColumns = [
  {
    Header: "table.headers.room_name",
    accessor: "room_name",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.room_name}
      />
    ),
  },
  {
    Header: "table.headers.room_id",
    accessor: "room_id",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.room_id} />
        <div>#{cellInfo?.row?.original?.room_id}</div>
      </div>
    ),
  },
  {
    Header: "table.headers.owner",
    accessor: "owner",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.owner}
      />
    ),
  },
  {
    Header: "table.headers.members_no",
    accessor: "members_no",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <MemberNoIcon />
        <div className={styles.diamond_cell}>
          {cellInfo?.row?.original?.members_no}K
        </div>
      </div>
    ),
  },
  {
    Header: "table.headers.room_status",
    accessor: "room_status",
    Cell: (cellInfo) => {
      const { t } = useTranslation();
      return cellInfo?.row?.original?.room_status ? (
        <span className={styles.active}>{t("details.active")}</span>
      ) : (
        <span className={styles.unactive}>{t("details.unactive")}</span>
      );
    },
  },
  {
    Header: "table.headers.topic",
    accessor: "topic",
    Cell: (cellInfo) => (
      <div className={styles.topic_cell}>{cellInfo?.row?.original?.topic}</div>
    ),
  },
  {
    Header: "table.headers.chairs",
    accessor: "chairs",
    Cell: (cellInfo) => (
      <div className={styles.chairs}>{cellInfo?.row?.original?.chairs}</div>
    ),
  },
  {
    Header: "table.headers.action",
    accessor: "",
    Cell: () => {
      const { t } = useTranslation();
      return (
        <button
          style={{
            color: "#0ACF83",
            fontSize: "18px",
          }}
          className="d-flex align-items-center justiy-content-center gap-2"
        >
          <PinedIcon />
          {t("details.pin")}
        </button>
      );
    },
  },
];

export const AllDesignsColumns = [
  {
    Header: "table.headers.id",
    sorting: true,
    sortKey: "id",
    accessor: "id",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.id} />
      </div>
    ),
  },
  {
    Header: "table.headers.icon",
    accessor: "icon",
    Cell: (cellInfo) => (
      <UserLabelInfo
        label={"test"}
        img={cellInfo?.row?.original?.image}
        alt="user image"
        name={cellInfo?.row?.original?.theme}
      />
    ),
  },
  {
    Header: "Name En",
    accessor: "name",
    sorting: true,
    sortKey: "name",
  },
  {
    Header: "Name Ar",
    accessor: "name_ar",
    sorting: true,
    sortKey: "name_ar",
  },
  {
    Header: "number_of_sales",
    accessor: "number_of_sales",
    Cell: (cellInfo) => {
      const { t } = useTranslation();
      return `${cellInfo?.row?.original?.number_of_sales || "0"} ${t(
        "details.times"
      )}`;
    },
  },
  {
    Header: "price",
    accessor: "price",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <GoldIcon />
        <div className={styles.gold_cell}>
          {approx(cellInfo?.row?.original?.price || 0, {
            capital: true,
          })}
        </div>
      </div>
    ),
  },
  {
    Header: "table.headers.days",
    accessor: "days",
    Cell: (cellInfo) => <span>{cellInfo?.row?.original?.wear_duration}</span>,
  },
  {
    Header: "created at",
    accessor: "created_at",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center justify-content-center gap-2">
        {moment(cellInfo?.row?.original?.created_at).fromNow()}
      </div>
    ),
  },
  //
  // {
  //   Header: "table.headers.item_id",
  //   accessor: "item_id",
  //   Cell: (cellInfo) => (
  //     <div className="d-flex align-items-center jsutify-content-center gap-2">
  //       <CopyTextButton text={cellInfo?.row?.original?.item_id} />
  //       <div>#{cellInfo?.row?.original?.item_id}</div>
  //     </div>
  //   ),
  // },
  // {
  //   Header: "table.headers.creator",
  //   accessor: "creator",
  //   Cell: (cellInfo) => (
  //     <UserLabelInfo
  //       img="/assets/image_1.png"
  //       alt="user image"
  //       name={cellInfo?.row?.original?.creator}
  //     />
  //   ),
  // },
  // {
  //   Header: "table.headers.section",
  //   accessor: "section",
  //   Cell: (cellInfo) => (
  //     <span className="d-flex align-items-center gap-1">
  //       section {cellInfo?.row?.original?.section}
  //     </span>
  //   ),
  // },
  // {
  //   Header: "table.headers.order",
  //   accessor: "order",
  //   Cell: (cellInfo) => <span>{cellInfo?.row?.original?.order}</span>,
  // },
  // {
  //   Header: "table.headers.type",
  //   accessor: "type",
  //   Cell: (cellInfo) => (
  //     <button
  //       className={`${
  //         cellInfo?.row?.original?.type === "animated" ? styles.animated : ""
  //       }`}
  //     >
  //       {cellInfo?.row?.original?.type}
  //     </button>
  //   ),
  // },
  // {
  //   Header: "table.headers.gold",
  //   accessor: "gold",
  //   Cell: (cellInfo) => (
  //     <div className="d-flex align-items-center jsutify-content-center gap-2">
  //       <GoldIcon />
  //       <div className={styles.gold_cell}>{cellInfo?.row?.original?.gold}K</div>
  //     </div>
  //   ),
  // },
  //
  // {
  //   Header: "table.headers.in_store",
  //   accessor: "in_store",
  //   Cell: (cellInfo) => {
  //     const { t } = useTranslation();

  //     return cellInfo?.row?.original?.in_store ? (
  //       <span className={styles.active}>{t("details.yes")}</span>
  //     ) : (
  //       <span className={styles.unactive}>{t("details.no")}</span>
  //     );
  //   },
  // },
];

export const AllDesignsData = [
  {
    theme: "king room",
    item_id: 132123,
    creator: "ayman tarek",
    section: "A",
    order: 44,
    type: "animated",
    gold: 40,
    days: 14,
    in_store: true,
  },
  {
    theme: "king room",
    item_id: 132123,
    creator: "ayman tarek",
    section: "A",
    order: 44,
    type: "animated",
    gold: 40,
    days: 14,
    in_store: false,
  },
  {
    theme: "king room",
    item_id: 132123,
    creator: "ayman tarek",
    section: "A",
    order: 44,
    type: "animated",
    gold: 40,
    days: 14,
    in_store: true,
  },
  {
    theme: "king room",
    item_id: 132123,
    creator: "ayman tarek",
    section: "A",
    order: 44,
    type: "animated",
    gold: 40,
    days: 14,
    in_store: false,
  },
  {
    theme: "king room",
    item_id: 132123,
    creator: "ayman tarek",
    section: "A",
    order: 44,
    type: "animated",
    gold: 40,
    days: 14,
    in_store: false,
  },
  {
    theme: "king room",
    item_id: 132123,
    creator: "ayman tarek",
    section: "A",
    order: 44,
    type: "animated",
    gold: 40,
    days: 14,
    in_store: false,
  },
  {
    theme: "king room",
    item_id: 132123,
    creator: "ayman tarek",
    section: "A",
    order: 44,
    type: "animated",
    gold: 40,
    days: 14,
    in_store: false,
  },
  {
    theme: "king room",
    item_id: 132123,
    creator: "ayman tarek",
    section: "A",
    order: 44,
    type: "animated",
    gold: 40,
    days: 14,
    in_store: false,
  },
];

export const GiftsColumns = [
  {
    Header: "table.headers.id",
    sorting: true,
    sortKey: "id",
    accessor: "id",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.id} />
      </div>
    ),
  },
  {
    Header: "table.headers.sender",
    accessor: "sender",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img={cellInfo?.row?.original?.user?.profile_photo}
        alt="user image"
        name={cellInfo?.row?.original?.user?.full_name}
      />
    ),
  },
  {
    Header: "table.headers.reciver",
    accessor: "reciver",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img={cellInfo?.row?.original?.receive?.profile_photo}
        alt="user image"
        name={cellInfo?.row?.original?.receive?.full_name}
      />
    ),
  },
  {
    Header: "table.headers.room_name",
    // sorting: true,
    // sortKey: "room_name",
    accessor: "room_name",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img={cellInfo?.row?.original?.room?.uploaded_avatar}
        alt="user image"
        name={cellInfo?.row?.original?.room?.name || "-----"}
      />
    ),
  },
  {
    Header: "table.headers.gift",
    accessor: "gift",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img={cellInfo?.row?.original?.gift?.icon}
        alt="user image"
        name={cellInfo?.row?.original?.gift?.name || "-----"}
      />
    ),
  },
  // {
  //   Header: "table.headers.amount",
  //   accessor: "amount",
  //   Cell: (cellInfo) => <span>{cellInfo?.row?.original?.coin}</span>,
  // },
  {
    Header: "table.headers.gold",
    accessor: "gold",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <GoldIcon />
        <div className={styles.gold_cell}>
          {approx(cellInfo?.row?.original?.coin, { capital: true })}
        </div>
      </div>
    ),
  },
  {
    Header: "created at",
    accessor: "created_at",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center justify-content-center gap-2">
        {moment(cellInfo?.row?.original?.created_at).fromNow()}
      </div>
    ),
  },
  // {
  //   Header: "table.headers.time_&_date",
  //   accessor: "time_and_date",
  //   Cell: (cellInfo) => (
  //     <div className={styles.whatsapp_cell}>
  //       {cellInfo?.row?.original?.time_and_date}
  //     </div>
  //   ),
  // },
];

export const GiftsData = [
  {
    sender: "ibrahim ali",
    reciver: "ibrahim ali",
    room_name: "Aria Chamber",
    gift: "king room",
    amount: 44,
    gold: 25,
    time_and_date: "19 mar, 2023 10:00 Am",
  },
  {
    sender: "ibrahim ali",
    reciver: "ibrahim ali",
    room_name: "Aria Chamber",
    gift: "king room",
    amount: 44,
    gold: 25,
    time_and_date: "19 mar, 2023 10:00 Am",
  },
  {
    sender: "ibrahim ali",
    reciver: "ibrahim ali",
    room_name: "Aria Chamber",
    gift: "king room",
    amount: 44,
    gold: 25,
    time_and_date: "19 mar, 2023 10:00 Am",
  },
  {
    sender: "ibrahim ali",
    reciver: "ibrahim ali",
    room_name: "Aria Chamber",
    gift: "king room",
    amount: 44,
    gold: 25,
    time_and_date: "19 mar, 2023 10:00 Am",
  },
  {
    sender: "ibrahim ali",
    reciver: "ibrahim ali",
    room_name: "Aria Chamber",
    gift: "king room",
    amount: 44,
    gold: 25,
    time_and_date: "19 mar, 2023 10:00 Am",
  },
  {
    sender: "ibrahim ali",
    reciver: "ibrahim ali",
    room_name: "Aria Chamber",
    gift: "king room",
    amount: 44,
    gold: 25,
    time_and_date: "19 mar, 2023 10:00 Am",
  },
];

export const contributionColumns = [
  {
    Header: "table.headers.icon",
    accessor: "icon",
    Cell: () => <UserLabelInfo img="/assets/image_1.png" alt="user image" />,
  },
  {
    Header: "table.headers.order",
    accessor: "order",
  },
  {
    Header: "table.headers.points",
    accessor: "points",
  },
  {
    Header: "table.headers.level_room",
    accessor: "level_room",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.level_room}
      />
    ),
  },
  {
    Header: "table.headers.level_entrance",
    accessor: "level_entrance",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.level_entrance}
      />
    ),
  },
];

export const contributionData = [
  {
    icon: "",
    order: 44,
    points: 200,
    level_frame: "king room",
    level_entrance: "king room",
  },
  {
    icon: "",
    order: 44,
    points: 200,
    level_frame: "king room",
    level_entrance: "king room",
  },
  {
    icon: "",
    order: 44,
    points: 200,
    level_frame: "king room",
    level_entrance: "king room",
  },
  {
    icon: "",
    order: 44,
    points: 200,
    level_frame: "king room",
    level_entrance: "king room",
  },
];

export const agenciesColumns = [
  {
    Header: "table.headers.name",
    accessor: "name",
  },
  {
    Header: "table.headers.id",
    accessor: "id",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.id} />
        <div>#{cellInfo?.row?.original?.id}</div>
      </div>
    ),
  },
  {
    Header: "table.headers.host",
    accessor: "host",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.host}
      />
    ),
  },
  {
    Header: "table.headers.status",
    accessor: "status",
    Cell: (cellInfo) => {
      const { t } = useTranslation();

      return cellInfo?.row?.original?.status ? (
        <span className={styles.active}>{t("details.active")}</span>
      ) : (
        <span className={styles.unactive}>{t("details.unactive")}</span>
      );
    },
  },
  {
    Header: "gold",
    accessor: "gold",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <GoldIcon />
        <div className={styles.gold_cell}>{cellInfo?.row?.original?.gold}K</div>
      </div>
    ),
  },
  {
    Header: "table.headers.accept_hosts",
    accessor: "accept_hosts",
    Cell: (cellInfo) => {
      const { t } = useTranslation();

      return cellInfo?.row?.original?.accept_hosts ? (
        <span className={styles.active}>{t("details.yes")}</span>
      ) : (
        <span className={styles.unactive}>{t("details.no")}</span>
      );
    },
  },
  {
    Header: "table.headers.auto_accept_joins",
    accessor: "auto_accept_joins",
    Cell: (cellInfo) => {
      const { t } = useTranslation();
      return cellInfo?.row?.original?.auto_accept_joins ? (
        <span className={styles.active}>{t("details.yes")}</span>
      ) : (
        <span className={styles.unactive}>{t("details.no")}</span>
      );
    },
  },
  {
    Header: "auto accept leaves",
    accessor: "auto_accept_leaves",
    Cell: (cellInfo) => {
      const { t } = useTranslation();

      return cellInfo?.row?.original?.auto_accept_leaves ? (
        <span className={styles.active}>{t("details.yes")}</span>
      ) : (
        <span className={styles.unactive}>{t("details.no")}</span>
      );
    },
  },
];

export const agenciesData = [
  {
    name: "top people",
    id: 155372,
    host: "Mohamed Ahmed",
    status: true,
    gold: 25,
    Accept_hosts: true,
    auto_accept_joins: true,
    auto_accept_leaves: true,
  },
  {
    name: "top people",
    id: 155372,
    host: "Mohamed Ahmed",
    status: true,
    gold: 25,
    Accept_hosts: false,
    auto_accept_joins: false,
    auto_accept_leaves: false,
  },
  {
    name: "top people",
    id: 155372,
    host: "Mohamed Ahmed",
    status: true,
    gold: 25,
    Accept_hosts: true,
    auto_accept_joins: true,
    auto_accept_leaves: true,
  },
  {
    name: "top people",
    id: 155372,
    host: "Mohamed Ahmed",
    status: true,
    gold: 25,
    Accept_hosts: true,
    auto_accept_joins: true,
    auto_accept_leaves: true,
  },
  {
    name: "top people",
    id: 155372,
    host: "Mohamed Ahmed",
    status: false,
    gold: 25,
    Accept_hosts: false,
    auto_accept_joins: false,
    auto_accept_leaves: false,
  },
  {
    name: "top people",
    id: 155372,
    host: "Mohamed Ahmed",
    status: false,
    gold: 25,
    Accept_hosts: false,
    auto_accept_joins: true,
    auto_accept_leaves: false,
  },
];

export const groupsColumns = [
  {
    Header: "table.headers.group_name",
    accessor: "group_name",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.group_name}
      />
    ),
  },
  {
    Header: "table.headers.group_id",
    accessor: "group_id",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.group_id} />
        <div>#{cellInfo?.row?.original?.group_id}</div>
      </div>
    ),
  },
  {
    Header: "table.headers.admin_name",
    accessor: "admin_name",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.admin_name}
      />
    ),
  },
  {
    Header: "table.headers.code",
    accessor: "code",
  },
  {
    Header: "table.headers.members_no",
    accessor: "members_no",
  },
  {
    Header: "table.headers.collected_coins",
    accessor: "collected_coins",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <GoldIcon />
        <div className={styles.gold_cell}>
          {cellInfo?.row?.original?.collected_coins}K
        </div>
      </div>
    ),
  },
  {
    Header: "table.headers.created_date",
    accessor: "created_date",
  },
];

export const groupData = [
  {
    group_name: "Fierce Felines",
    group_id: 125287,
    admin_name: "mohamed ahmed",
    code: 23,
    members_no: "44 of 60",
    collected_coins: 35,
    created_date: "Friday, October 13th 2023,",
  },
  {
    group_name: "Fierce Felines",
    group_id: 125287,
    admin_name: "mohamed ahmed",
    code: 23,
    members_no: "44 of 60",
    collected_coins: 35,
    created_date: "Friday, October 13th 2023,",
  },
  {
    group_name: "Fierce Felines",
    group_id: 125287,
    admin_name: "mohamed ahmed",
    code: 23,
    members_no: "44 of 60",
    collected_coins: 35,
    created_date: "Friday, October 13th 2023,",
  },
  {
    group_name: "Fierce Felines",
    group_id: 125287,
    admin_name: "mohamed ahmed",
    code: 23,
    members_no: "44 of 60",
    collected_coins: 35,
    created_date: "Friday, October 13th 2023,",
  },
  {
    group_name: "Fierce Felines",
    group_id: 125287,
    admin_name: "mohamed ahmed",
    code: 23,
    members_no: "44 of 60",
    collected_coins: 35,
    created_date: "Friday, October 13th 2023,",
  },
  {
    group_name: "Fierce Felines",
    group_id: 125287,
    admin_name: "mohamed ahmed",
    code: 23,
    members_no: "44 of 60",
    collected_coins: 35,
    created_date: "Friday, October 13th 2023,",
  },
];

export const aristocracyColumns = [
  {
    Header: "Name",
    accessor: "Name",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.group_name}
      />
    ),
  },
  {
    Header: "Id",
    accessor: "id",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.id} />
        <div>#{cellInfo?.row?.original?.id}</div>
      </div>
    ),
  },
  {
    Header: "price",
    accessor: "price",
  },
  {
    Header: "designs attached",
    accessor: "designs_attached",
  },
  {
    Header: "Attached powers",
    accessor: "attached_powers",
  },
];

export const aristocracyData = [
  {
    name: "Fierce Felines",
    id: 1235432,
    price: 5423,
    designs_attached: 4,
    attached_powers: "access rooms, kick out users",
  },
  {
    name: "Fierce Felines",
    id: 1235432,
    price: 5423,
    designs_attached: 4,
    attached_powers: "access rooms, kick out users",
  },
  {
    name: "Fierce Felines",
    id: 1235432,
    price: 5423,
    designs_attached: 4,
    attached_powers: "access rooms, kick out users",
  },
  {
    name: "Fierce Felines",
    id: 1235432,
    price: 5423,
    designs_attached: 4,
    attached_powers: "access rooms, kick out users",
  },
  {
    name: "Fierce Felines",
    id: 1235432,
    price: 5423,
    designs_attached: 4,
    attached_powers: "access rooms, kick out users",
  },
  {
    name: "Fierce Felines",
    id: 1235432,
    price: 5423,
    designs_attached: 4,
    attached_powers: "access rooms, kick out users",
  },
];

export const generalColumns = [
  {
    Header: "table.headers.id",
    accessor: "random_id",
    sortKey: "id",
    sorting: true,
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.id || "---"} />
      </div>
    ),
  },
  {
    Header: "table.headers.user_id",
    accessor: "user_id",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <CopyTextButton text={cellInfo?.row?.original?.user_id || "---"} />
      </div>
    ),
  },
  {
    Header: "table.headers.presented_from",
    accessor: "presented_from",
    Cell: (cellInfo) => (
      <div className={`${styles.user_name_cell} d-flex align-items-center`}>
        <img
          src={
            cellInfo?.row?.original?.user_data?.profile_photo ||
            "https://tse1.mm.bing.net/th?id=OIP.tvaMwK3QuFxhTYg4PSNNVAHaHa&pid=Api"
          }
          alt="user"
        />
        <span>{cellInfo?.row?.original?.user_data?.full_name}</span>
      </div>
    ),
  },
  {
    Header: "table.headers.presented_for",
    accessor: "presented_for",
    Cell: (cellInfo) => (
      <div className={`${styles.user_name_cell} d-flex align-items-center`}>
        <img
          src={
            cellInfo?.row?.original?.report_user_data?.profile_photo ||
            cellInfo?.row?.original?.post?.user?.profile_photo ||
            cellInfo?.row?.original?.chatRoom?.user?.profile_photo ||
            "https://tse1.mm.bing.net/th?id=OIP.tvaMwK3QuFxhTYg4PSNNVAHaHa&pid=Api"
          }
          alt="user"
        />
        <span>
          {cellInfo?.row?.original?.report_user_data?.full_name ||
            cellInfo?.row?.original?.post?.user?.full_name ||
            cellInfo?.row?.original?.chatRoom?.user?.full_name}
        </span>
      </div>
    ),
  },
  {
    Header: "created at",
    accessor: "created_at",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center justify-content-center gap-2">
        {moment(cellInfo?.row?.original?.createdAt).fromNow()}
      </div>
    ),
  },
  {
    Header: "table.headers.discription",
    accessor: "discription",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center justify-content-center gap-2">
        {cellInfo?.row?.original?.report_message}
      </div>
    ),
  },
  {
    Header: "table.headers.status",
    accessor: "status",
    Cell: (cellInfo) => {
      const { t } = useTranslation();

      return (
        <div className={styles[cellInfo?.row?.original?.report_status]}>
          {t(`forms.${cellInfo?.row?.original?.report_status}`) || "----"}
        </div>
      );
    },
  },
];

export const generalDate = [
  {
    presented_from: "moahmed ahmed",
    presented_for: "managements",
    creation_date: "Friday, October 13th 2023,",
    reason: "pornography",
    discription: "want to take official age...",
  },
  {
    presented_from: "moahmed ahmed",
    presented_for: "managements",
    creation_date: "Friday, October 13th 2023,",
    reason: "pornography",
    discription: "want to take official age...",
  },
  {
    presented_from: "moahmed ahmed",
    presented_for: "managements",
    creation_date: "Friday, October 13th 2023,",
    reason: "pornography",
    discription: "want to take official age...",
  },
  {
    presented_from: "moahmed ahmed",
    presented_for: "managements",
    creation_date: "Friday, October 13th 2023,",
    reason: "pornography",
    discription: "want to take official age...",
  },
  {
    presented_from: "moahmed ahmed",
    presented_for: "managements",
    creation_date: "Friday, October 13th 2023,",
    reason: "pornography",
    discription: "want to take official age...",
  },
  {
    presented_from: "moahmed ahmed",
    presented_for: "managements",
    creation_date: "Friday, October 13th 2023,",
    reason: "pornography",
    discription: "want to take official age...",
  },
];

export const managersColumns = [
  {
    Header: "table.headers.name",
    accessor: "name",
    Cell: (cellInfo) => (
      <div className={`${styles.user_name_cell} d-flex align-items-center`}>
        <img src="/assets/image_1.png" alt="user-image" />
        <span>{cellInfo?.row?.original?.name}</span>
      </div>
    ),
  },
  {
    Header: "table.headers.mail_address",
    accessor: "mail",
  },
  {
    Header: "table.headers.role",
    accessor: "role",
  },
];

export const managersData = [
  {
    name: "moahmed ahmed",
    mail: "Example@example.com",
    role: "super admin",
  },
  {
    name: "moahmed ahmed",
    mail: "Example@example.com",
    role: "super admin",
  },
  {
    name: "moahmed ahmed",
    mail: "Example@example.com",
    role: "super admin",
  },
  {
    name: "moahmed ahmed",
    mail: "Example@example.com",
    role: "super admin",
  },
  {
    name: "moahmed ahmed",
    mail: "Example@example.com",
    role: "super admin",
  },
  {
    name: "moahmed ahmed",
    mail: "Example@example.com",
    role: "super admin",
  },
];

export const countriesColumns = [
  {
    Header: "table.headers.country_name",
    accessor: "country_name",
    Cell: (cellInfo) => (
      <div className={`${styles.user_name_cell} d-flex align-items-center`}>
        <img src="/assets/image_1.png" alt="user-image" />
        <span>{cellInfo?.row?.original?.country_name}</span>
      </div>
    ),
  },
  {
    Header: "table.headers.call_code",
    accessor: "call_code",
  },
  {
    Header: "table.headers.country_order",
    accessor: "country_order",
  },
  {
    Header: "table.headers.role",
    accessor: "role",
  },
];

export const countriesData = [
  {
    country_name: "palestine",
    call_code: "+020",
    country_order: "20",
    role: "super Admin",
  },
  {
    country_name: "palestine",
    call_code: "+020",
    country_order: "20",
    role: "super Admin",
  },
  {
    country_name: "palestine",
    call_code: "+020",
    country_order: "20",
    role: "super Admin",
  },
  {
    country_name: "palestine",
    call_code: "+020",
    country_order: "20",
    role: "super Admin",
  },
  {
    country_name: "palestine",
    call_code: "+020",
    country_order: "20",
    role: "super Admin",
  },
  {
    country_name: "palestine",
    call_code: "+020",
    country_order: "20",
    role: "super Admin",
  },
];

export const moneyTransfersColumns = [
  {
    Header: "table.headers.member",
    accessor: "member",
    Cell: (cellInfo) => (
      <div className={`${styles.user_name_cell} d-flex align-items-center`}>
        <img src="/assets/image_1.png" alt="user-image" />
        <span>{cellInfo?.row?.original?.member}</span>
      </div>
    ),
  },
  {
    Header: "table.headers.creation_date",
    accessor: "creation_date",
  },
  {
    Header: "table.headers.trans_id",
    accessor: "trans_Id",
  },
  {
    Header: "table.headers.amount",
    accessor: "amount",
    Cell: (cellInfo) => (
      <div className={`${styles.amount_cell} d-flex align-items-center`}>
        <AmountTableIcon />
        {cellInfo?.row?.original?.amount}$
      </div>
    ),
  },
  {
    Header: "offer",
    accessor: "offer",
  },
  {
    Header: "pay gate",
    accessor: "pay_gate",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center">
        {cellInfo?.row?.original?.pay_gate === "google_pay" ? (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <img
              style={{ width: "24px" }}
              src="/assets/google-pay.png"
              alt="pay-method"
            />
            <span>google pay</span>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <img
              style={{ width: "24px" }}
              src="/assets/visa.png"
              alt="pay-method"
            />
            visa card
          </div>
        )}
      </div>
    ),
  },
  {
    Header: "status",
    accessor: "status",
    Cell: (cellInfo) => {
      const { t } = useTranslation();
      return cellInfo?.row?.original?.status ? (
        <span className={styles.active}>{t("details.done")}</span>
      ) : (
        <span className={styles.unactive}>{t("details.failure")}</span>
      );
    },
  },
];

export const moneyTransfersData = [
  {
    member: "moahmed ahmed",
    creation_date: "Friday, October 13th 2023,",
    trans_Id: "#212152362",
    amount: 24,
    offer: "13500coins",
    pay_gate: "google_pay",
    status: true,
  },
  {
    member: "moahmed ahmed",
    creation_date: "Friday, October 13th 2023,",
    trans_Id: "#212152362",
    amount: 24,
    offer: "13500coins",
    pay_gate: "visa_pay",
    status: false,
  },
  {
    member: "moahmed ahmed",
    creation_date: "Friday, October 13th 2023,",
    trans_Id: "#212152362",
    amount: 24,
    offer: "13500coins",
    pay_gate: "google_pay",
    status: true,
  },
  {
    member: "moahmed ahmed",
    creation_date: "Friday, October 13th 2023,",
    trans_Id: "#212152362",
    amount: 24,
    offer: "13500coins",
    pay_gate: "google_pay",
    status: false,
  },
];

export const chargeTargetColumns = [
  {
    Header: "table.headers.icon",
    accessor: "icon",
    Cell: () => <CircleImage img={"/assets/image_1.png"} />,
  },
  {
    Header: "table.headers.order",
    accessor: "order",
  },
  {
    Header: "table.headers.coins_required",
    accessor: "coins_required",
    Cell: (cellInfo) => (
      <div className="d-flex align-items-center jsutify-content-center gap-2">
        <GoldIcon />
        <div className={styles.gold_cell}>
          {cellInfo?.row?.original?.coins_required}K
        </div>
      </div>
    ),
  },
];

export const chargeTargetData = [
  {
    icon: "/assets/image_1.png",
    order: 20,
    coins_required: 25,
  },
  {
    order: 20,
    coins_required: 25,
  },
  {
    order: 20,
    coins_required: 25,
  },
  {
    order: 20,
    coins_required: 25,
  },
  {
    order: 20,
    coins_required: 25,
  },
];

export const byManagersColumns = [
  {
    Header: "table.headers.sender",
    accessor: "sender",
    Cell: (cellInfo) => (
      <div className={`${styles.user_name_cell} d-flex align-items-center`}>
        <img src="/assets/image_1.png" alt="user-image" />
        <span>{cellInfo?.row?.original?.sender}</span>
      </div>
    ),
  },
  {
    Header: "table.headers.creation_date",
    accessor: "creation_date",
  },
  {
    Header: "table.headers.reciver",
    accessor: "reciver",
    Cell: (cellInfo) => (
      <UserLabelInfo
        img="/assets/image_1.png"
        alt="user image"
        name={cellInfo?.row?.original?.reciver}
      />
    ),
  },
  {
    Header: "table.headers.type",
    accessor: "type",
    Cell: (cellInfo) => (
      <span
        className={`${
          cellInfo?.row?.original?.type === "gold"
            ? styles.gold_cell
            : styles.diamond_cell
        }`}
      >
        {cellInfo?.row?.original?.type}
      </span>
    ),
  },
  {
    Header: "table.headers.amount",
    accessor: "amount",
  },
  {
    Header: "table.headers.status",
    accessor: "status",
    Cell: (cellInfo) => {
      const { t } = useTranslation();
      return cellInfo?.row?.original?.status ? (
        <span className={styles.active}>{t("details.add")}</span>
      ) : (
        <span className={styles.unactive}>{t("details.default")}</span>
      );
    },
  },
];

export const byManagersData = [
  {
    sender: "mohamed ahmed",
    creation_date: "Friday, October 13th 2023,",
    reciver: "mohamed ali",
    type: "gold",
    amount: 2500,
    status: true,
  },
  {
    sender: "mohamed ahmed",
    creation_date: "Friday, October 13th 2023,",
    reciver: "mohamed ali",
    type: "gold",
    amount: 2500,
    status: true,
  },
  {
    sender: "mohamed ahmed",
    creation_date: "Friday, October 13th 2023,",
    reciver: "mohamed ali",
    type: "diamond",
    amount: 2500,
    status: false,
  },
  {
    sender: "mohamed ahmed",
    creation_date: "Friday, October 13th 2023,",
    reciver: "mohamed ali",
    type: "diamond",
    amount: 2500,
    status: true,
  },
  {
    sender: "mohamed ahmed",
    creation_date: "Friday, October 13th 2023,",
    reciver: "mohamed ali",
    type: "gold",
    amount: 2500,
    status: true,
  },
  {
    sender: "mohamed ahmed",
    creation_date: "Friday, October 13th 2023,",
    reciver: "mohamed ali",
    type: "diamond",
    amount: 2500,
    status: false,
  },
];

export const badgesByManagersColumns = [
  {
    Header: "table.headers.name",
    accessor: "name",
    // Cell: (cellInfo) => (
    //   <UserLabelInfo
    //     img="/assets/image_1.png"
    //     alt="user image"
    //     name={cellInfo?.row?.original?.name}
    //   />
    // ),
  },
  {
    Header: "table.headers.icon",
    accessor: "icon",
    Cell: () => <UserLabelInfo img="/assets/image_1.png" alt="user image" />,
  },
  {
    Header: "table.headers.description",
    accessor: "description",
  },
];

export const badgesByManagersData = [
  {
    name: "Third place badge Victor Event",
    icon: "",
    description: "Second place badge Event Victor",
  },
  {
    name: "Third place badge Victor Event",
    icon: "",
    description: "Second place badge Event Victor",
  },
  {
    name: "Third place badge Victor Event",
    icon: "",
    description: "Second place badge Event Victor",
  },
  {
    name: "Third place badge Victor Event",
    icon: "",
    description: "Second place badge Event Victor",
  },
  {
    name: "Third place badge Victor Event",
    icon: "",
    description: "Second place badge Event Victor",
  },
];
