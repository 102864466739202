import React, { useEffect, useState } from "react";
import {
  Error,
  MainCard,
  PageHeader,
  PageTitle,
  SearchBox,
  SinglePost,
} from "../../components";
import { useTranslation } from "react-i18next";
import styles from "./.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getPosts } from "../../redux/services/postsServices";
import { PaginationControl } from "react-bootstrap-pagination-control";
import EmptyTable from "../../components/UI/Table/EmptyTable";
import LoadSpinner from "../../components/UI/LoadSpinner";

const Posts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch(0);

  const { posts, loading, error, totalPages } = useSelector(
    (state) => state.posts
  );

  const [options, setOptions] = useState({
    isPaginated: totalPages > 1,
    pagination: 1,
    itemsPerPage: 20,
    searchKeyWord: null,
    totalPages,
    sortType: null,
    sortOption: null,
  });

  useEffect(() => {
    setOptions({
      ...options,
      totalPages: totalPages,
      isPaginated: totalPages > 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, posts]);

  useEffect(() => {
    dispatch(getPosts(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.pagination,
    options.searchKeyWord,
    options.sortOption,
    options?.sortType,
  ]);

  if (error) return <Error msg={error?.message} />;

  return (
    <>
      <div className={styles.page}>
        <MainCard>
          <PageHeader>
            <div
              className={`d-flex align-items-center justify-content-between w-100`}
            >
              <div className={`d-flex align-items-center gap-3`}>
                <PageTitle title={t("posts.title")} />
                <span className="dash"></span>
                <div className={`members_no`}>
                  {posts?.length || "0"} {t("details.post")}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end gap-3">
                <SearchBox options={options} setOptions={setOptions} />
              </div>
            </div>
          </PageHeader>
          <div className={styles.notify_cards_container}>
            {loading && <LoadSpinner />}
            {posts?.map((post, i) => (
              <SinglePost
                key={i}
                type={"post"}
                // ////////////////////////////////
                // ////////////////////////////////
                id={post?.id}
                // ////////////////////////////////
                // ////////////////////////////////
                options={options}
                setOptions={setOptions}
                action={getPosts}
                {...post}
              />
            ))}
            {!loading && !posts?.length && <EmptyTable />}
          </div>
          {!loading && options?.isPaginated && (
            <div className={styles.PaginationControl}>
              <PaginationControl
                changePage={(current) => {
                  setOptions({ ...options, pagination: current });
                }}
                page={options.pagination}
                total={options.totalPages * options.itemsPerPage}
                limit={options.itemsPerPage}
                ellipsis={1}
              />
            </div>
          )}
        </MainCard>
      </div>
    </>
  );
};

export default Posts;
