import React from "react";
import styles from "./.module.scss";

const PageTitle = ({ title, mrBottom }) => {
  return (
    <h2 style={{ marginBottom: mrBottom }} className={styles.title}>
      {title}
    </h2>
  );
};

export default PageTitle;
