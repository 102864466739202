import React from "react";
import styles from "./.module.scss";

export default function ToggleBtn() {
  return (
    <input
      className={styles.toggle__btn}
      type="checkbox"
      name="check"
      id="checkbox"
    />
  );
}
