import { DeleteIcon, SingleRoomIcon } from "../../../icons";
import { Link } from "react-router-dom";
import React from "react";

const DeleteCell = ({ onClick }) => {
  return (
    <button type="button" onClick={onClick}>
      <DeleteIcon />
    </button>
  );
};

export default DeleteCell;
