import React from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const MemberInterests = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.member__interests__wrapper}>
      <p className={styles.section__head}>{t("userinfo.member_interests")}</p>
      <div className={styles.wrapper}>
        {data?.length ? (
          data.map((item, index) => {
            return (
              <span className={styles.interest__card} key={index}>
                {t(`interests.${item.name}`)}
              </span>
            );
          })
        ) : (
          <span className={styles.interest__card}>
            {t("userinfo.no_interests")}
          </span>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberInterests);
