import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import {
  EmailIcon,
  GiftIcon,
  GoldIcon,
  PasswordIcon,
  PhoneIcon,
  UserIcon,
} from "../../../icons";
import styles from "./input.module.scss";

export default function FormInput({
  placeholder,
  icon,
  label,
  id,
  input,
  options,
  meta: { touched, error, warning },
}) {
  const [showPassword, setShowPassword] = useState(false);

  if (input.type === "textarea")
    return (
      <div
        className={`${styles.input_container} ${styles.textarea} ${
          (touched && error) || warning ? styles.error : ""
        } d-flex align-items-center position-relative w-100`}
      >
        <label htmlFor={id} className={`${styles.label} position-absolute`}>
          {label}
        </label>
        <textarea
          maxLength={150}
          {...input}
          className={`${styles.input} ${styles.text_area} ${
            icon ? "" : styles.no_icon
          } d-block`}
          type={showPassword ? "text" : input.type}
          placeholder={placeholder}
        />
        {/* <div>{0} / 150</div> */}
        {touched &&
          ((error && (
            <p className={`${styles.error_msg} position-absolute`}>{error}</p>
          )) ||
            (warning && (
              <p className={`${styles.error_msg} position-absolute`}>
                {warning}
              </p>
            )))}
        {input.type === "password" && (
          <button
            type="button"
            className={styles.show__pass__btn}
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {!showPassword ? (
              <AiOutlineEye className={styles.pass__icon} />
            ) : (
              <AiOutlineEyeInvisible className={styles.pass__icon} />
            )}
          </button>
        )}
      </div>
    );
  if (input.type === "select") {
    return (
      <div
        className={`${styles.input_container} ${
          (touched && error) || warning ? styles.error : ""
        } d-flex align-items-center position-relative w-100`}
      >
        <label htmlFor={id} className={`${styles.label} position-absolute`}>
          {label}
        </label>
        <span className={`${styles.icon} d-flex align-items-center`}>
          <GiftIcon />
        </span>
        <select
          style={{ display: "flex", alignItems: "center" }}
          className={`${styles.input} ${icon ? "" : styles.no_icon} d-block`}
          placeholder={placeholder}
        >
          {options?.map((opt, i) => {
            return (
              <option key={i} value={opt.value}>
                {opt.key}
              </option>
            );
          })}
        </select>
        {touched &&
          ((error && (
            <p className={`${styles.error_msg} position-absolute`}>{error}</p>
          )) ||
            (warning && (
              <p className={`${styles.error_msg} position-absolute`}>
                {warning}
              </p>
            )))}
      </div>
    );
  }
  return (
    <div
      className={`${styles.input_container} ${
        (touched && error) || warning ? styles.error : ""
      } d-flex align-items-center position-relative w-100`}
    >
      <label htmlFor={id} className={`${styles.label} position-absolute`}>
        {label}
      </label>
      <span className={`${styles.icon} d-flex align-items-center`}>
        {(() => {
          switch (icon) {
            case "email":
              return <EmailIcon />;
            case "password":
              return <PasswordIcon />;
            case "user":
              return <UserIcon />;
            case "mobile":
              return <PhoneIcon />;
            case "gold":
              return <GoldIcon />;
            case "diamond":
              return <GoldIcon />;
            case "gift":
              return <GiftIcon />;
            default:
              return null;
          }
        })()}
      </span>

      <input
        {...input}
        className={`${styles.input} ${icon ? "" : styles.no_icon} d-block`}
        type={showPassword ? "text" : input.type}
        placeholder={placeholder}
      />
      {touched &&
        ((error && (
          <p className={`${styles.error_msg} position-absolute`}>{error}</p>
        )) ||
          (warning && (
            <p className={`${styles.error_msg} position-absolute`}>{warning}</p>
          )))}
      {input.type === "password" && (
        <button
          type="button"
          className={styles.show__pass__btn}
          onClick={() => setShowPassword((prev) => !prev)}
        >
          {!showPassword ? (
            <AiOutlineEye className={styles.pass__icon} />
          ) : (
            <AiOutlineEyeInvisible className={styles.pass__icon} />
          )}
        </button>
      )}
    </div>
  );
}
