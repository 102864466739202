import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { toast } from "react-toastify";

export const getMembersAvatars = createAsyncThunk(
  "members/getMembersAvatars",
  async ({ pagination, itemsPerPage }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await api.get(`admin-avatar/list`, {
        params: {
          page: pagination,
          perPage: itemsPerPage,
        },
      });
      return res;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Error !");
      return rejectWithValue(error?.response?.data);
    }
  }
);
