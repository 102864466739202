import React, { useEffect, useState } from "react";
import { MainLink } from "../..";
import { ActiveLinkIcon, ArrowDownIcon } from "../../../icons";
import { motion } from "framer-motion";
import styles from "./.module.scss";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MainDropdown = ({ target, label, links, icon, closed }) => {
  const { pathname } = useLocation();
  const [activeIconTop, setActiveIconTop] = useState(null);
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();

  const closeDropMenu = (e) => {
    if (e?.target?.id !== `close_menu_btn_${label}`) {
      setOpened(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", closeDropMenu);
    return () => {
      document.body.removeEventListener("click", closeDropMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(
  // currentPath.slice(currentPath.indexOf("/", 1), currentPath.lastIndexOf("/"))
  // );
  // console.log(currentPath.indexOf("/", 1));
  // console.log();

  if (closed)
    return (
      <>
        <button
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${target}`}
          // aria-expanded={"fales"}
          aria-controls={`#${target}`}
          className={`${styles.main_link} ${
            pathname.includes(target) ? styles.active : ""
          } ${
            closed ? styles.closed_menu : ""
          } dropdown_menu  d-flex align-items-center position-relative`}
        >
          <div className={`${styles.icon} d-flex align-items-center`}>
            {icon}
          </div>
          <div
            className={`${styles.arrow_icon} ${
              closed ? styles.closed : ""
            } arrow_icon`}
          >
            <ArrowDownIcon />
          </div>
        </button>
        <div
          className={`${styles.dropdow_menu} ${
            closed ? styles.closed : ""
          } collapse`}
          id={target}
          data-bs-parent="#collapseExample"
        >
          {links.map((link, i) => (
            <MainLink
              icon={link.icon}
              key={i}
              href={link?.href}
              label={link?.label}
              nested
              closed
            />
          ))}
        </div>
      </>
    );

  return (
    <>
      <button
        id={`close_menu_btn_${label}`}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setOpened((prev) => !prev);
        }}
        className={`${styles.main_link} ${
          pathname.slice(1, pathname.indexOf("/", 1)) === target
            ? styles.active
            : ""
        } dropdown_menu  d-flex align-items-center position-relative ${
          opened ? styles.opened : ""
        }`}
      >
        <div
          style={{ pointerEvents: "none" }}
          className={`${styles.icon} d-flex align-items-center`}
        >
          {icon}
        </div>
        {t(label)}
        <div
          // style={{ pointerEvents: "none" }}
          className={`${styles.arrow_icon} ${
            opened ? styles.opened : ""
          } arrow_icon`}
        >
          <ArrowDownIcon />
        </div>
      </button>
      <motion.div
        layoutout
        initial={{ height: 0 }}
        animate={{ height: opened ? "auto" : 0 }}
        className={`${styles.dropdow_menu}`}
      >
        {links.map((link, i) => (
          <MainLink
            icon={link.icon}
            key={i}
            href={link?.href}
            label={link?.label}
            setActiveIconTop={setActiveIconTop}
            activeIconTop={activeIconTop}
            nested
            id={`close_menu_btn_${label}`}
          />
        ))}
        {pathname.slice(1, pathname.indexOf("/", 1)) === target && (
          <div
            className={`${styles.active_icon} ${opened ? styles.active : ""}`}
            style={{ top: `${activeIconTop}px` }}
            // style={{ top: `252px` }}
          >
            <ActiveLinkIcon />
          </div>
        )}
      </motion.div>
    </>
  );
};

export default MainDropdown;
