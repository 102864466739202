import React from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import CopyTextButton from "../../../UI/CopyTextButton";
import { FaRankingStar } from "react-icons/fa6";
import { IoGiftSharp } from "react-icons/io5";
import { FiEye } from "react-icons/fi";
import { GoLinkExternal } from "react-icons/go";
import { Link } from "react-router-dom";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const MemberConnectionCard = ({ connect }) => {
  console.log(connect);
  return (
    <div className={styles.card}>
      <div className={styles.user__facts}>
        <div className={styles.user__avatar__wrapper}>
          <img
            src={
              connect.profile_photo ||
              connect?.friend?.profile_photo ||
              connect?.user?.profile_photo ||
              "https://tse1.mm.bing.net/th?id=OIP.tvaMwK3QuFxhTYg4PSNNVAHaHa&pid=Api"
            }
            alt={connect.full_name || connect?.friend?.full_name || "N/A"}
          />
        </div>
        <div className={styles.user__info}>
          <p className={styles.user__name}>
            {connect.full_name ||
              connect?.friend?.full_name ||
              connect?.user?.full_name}
          </p>
          <CopyTextButton
            text={
              connect?.random_id ||
              connect?.friend?.random_id ||
              connect?.user?.random_id
            }
          />
        </div>
      </div>
      {/* <div className={styles.user__level}>
        <FaRankingStar />
        <span>
          {connect?.personal_level?.name ||
            connect?.friend?.personal_level?.name ||
            "N/A"}
        </span>
      </div> */}
      {/* <div className={styles.user__gifts}>
        <IoGiftSharp />
        <span>
          {connect?.charge_level?.exp ||
            connect?.friend?.charge_level?.exp ||
            "N/A"}
        </span>
      </div> */}
      <div className={styles.user__actions}>
        {/* <button>
          <FiEye className={styles.eye__icon} />
        </button> */}
        <Link
          to={`/member/${connect?.friend?.id}`}
          className={styles.link__icon}
        >
          <FiEye className={styles.eye__icon} />

          {/* <GoLinkExternal /> */}
        </Link>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberConnectionCard);
