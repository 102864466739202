import React, { useEffect, useState } from "react";
// import validators from "../../../util/validators";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { DragDropFiled, Button } from "../..";
import styles from "./.module.scss";
import { toggleModal } from "../../../redux/features/modalSlice";
import { useApi } from "../../../hooks/useApi";
import { toast } from "react-toastify";

const AvatarModal = ({
  dispatch,
  options,
  setOptions,
  action,
  itemID,
  type,
  edit_id,
}) => {
  const { t } = useTranslation();
  const [res, setRes] = useState(null);
  const [image, setImage] = useState(null);
  const [data, setData] = useState({
    image,
  });

  useEffect(() => {
    setData({ ...data, image, id: itemID });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, itemID]);

  const {
    onRequest,
    data: reqData,
    error,
  } = useApi(`/admin-${type === "theme" ? "background" : type}/update`, "put", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  useEffect(() => {
    if (reqData) {
      setOptions({
        isPaginated: false,
        pagination: 1,
        itemsPerPage: 20,
        searchKeyWord: null,
        sortType: null,
        sortOption: null,
      });
      dispatch(toggleModal(""));
      dispatch(action(options));
      toast.success(reqData?.message || t("Saved update successfully"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData]);

  const onSubmit = async (values) => {
    const formDate = new FormData();
    formDate.append("id", data.id);
    formDate.append("file", data.image);
    setRes(await onRequest(formDate));
  };

  useEffect(() => {
    (res === false || error) && toast.error("Invalid uploaded image size");
    // toast.success(reqData?.message || t("Saved update successfully"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, res]);

  // const formElements = [
  //   {
  //     type: "text",
  //     icon: "",
  //     name: "banner_name",
  //     placeholder: t("forms.enter_banner_name"),
  //     label: t("forms.banner_name"),
  //     validate: validators.composeValidators(
  //       validators.required("Banner Name"),
  //       validators.minLength("banner_name", 4)
  //     ),
  //   },
  //   {
  //     type: "number",
  //     icon: "",
  //     name: "order",
  //     placeholder: `000000`,
  //     label: t("forms.order"),
  //     validate: validators.composeValidators(
  //       validators.required("Order"),
  //       validators.minLength("order", 4)
  //     ),
  //   },
  // ];

  return (
    <Form initialValues={data} onSubmit={onSubmit}>
      {({ handleSubmit, submitting, valid }) => (
        <form
          encType="multipart/form-data"
          className={`w-100`}
          onSubmit={handleSubmit}
        >
          <div
            className={`${styles.inputs_container} d-flex flex-column gap-4 mb-4`}
          >
            <Field
              component={() => (
                <DragDropFiled image={image} setImage={setImage} />
              )}
              name={"avatar_img"}
            />

            {/* <div className={styles.inputs_box}> */}
            {/* {formElements.map((item, i) => (
                <Field
                  key={i}
                  component={Input}
                  placeholder={item.placeholder}
                  icon={item.icon}
                  type={item.type}
                  name={item.name}
                  id={item.type}
                  label={item.label}
                  validate={item.validate}
                />
              ))} */}
            {/* </div> */}
          </div>
          {edit_id ? (
            <div className="d-flex align-items-center justify-content between gap-4">
              <Button
                bgColor="#0ACF83"
                type="submit"
                disabled={!valid || !image}
                submitting={submitting}
              >
                {t(`details.save_edits`)}
              </Button>
              <Button
                onClick={() => dispatch(toggleModal(" "))}
                type="button"
                outline
              >
                {t("details.cancel")}
              </Button>
            </div>
          ) : (
            <Button
              type="submit"
              disabled={!valid || !image}
              submitting={submitting}
            >
              {t(`details.add`)} {t(`details.${type}`)}
            </Button>
          )}
        </form>
      )}
    </Form>
  );
};

export default AvatarModal;
