import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import Header from "../../../components/content/MemberInfoPage/Header";
import UserInfoWrapper from "../../../components/content/MemberInfoPage/UserInfoWrapper";
import MemberConnections from "../../../components/content/MemberInfoPage/MemberConnections";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Error } from "../../../components";
import { getRoomDetails } from "../../../redux/services/roomDetailsServices";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

const btnsData = [
  {
    id: 1,
    content: "On Mic",
    param: "live_users",
  },
  {
    id: 2,
    content: "Normal Users",
    param: "roomUsers",
  },
  {
    id: 3,
    content: "Blocked",
    param: "blockUser",
  },
];

export const RoomInfo = ({ props }) => {
  const { error, loading, data } = useSelector((state) => state?.roomDetails);
  const {
    error: transactionsError,
    loading: transactionsLoading,
    transactions,
  } = useSelector((state) => state?.memberTransactions);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getRoomDetails({ id }));
  }, [dispatch, id]);

  if (error) return <Error msg={error?.message} />;
  if (loading) return <></>;

  return (
    <div className={styles.member__info__page__wrapper}>
      <Header href={"/rooms/active-rooms"} title={"roomDetails"} />
      <div className={`${styles.page__row} row g-0`}>
        <div className={`${styles.page__column} col-12 col-md-8`}>
          <UserInfoWrapper
            info={data.roomInfo}
            type="room"
            title={"info"}
            at={"created_at"}
          />
        </div>
        <div className={`${styles.page__column} col-12 col-md-4`}>
          <MemberConnections
            title={"room_users"}
            btns={btnsData}
            data={data.connections}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomInfo);
