import React from "react";
import { Input, Button } from "../../components";
import { Field, Form } from "react-final-form";
import validators from "../../util/validators";
import { useTranslation } from "react-i18next";
import styles from "./.module.scss";
import IMAGE from "../../assets/login_bg.png";
import LOGO from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminLogin } from "../../redux/services/adminServices";

const LoginPage = ({ redirect }) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { admin, loading } = useSelector((state) => state?.admin);

  const loginFormElements = [
    {
      type: "text",
      icon: "email",
      name: "email",
      placeholder: `${t("login.form.email_placeholder")}`,
      label: `${t("login.form.email_label")}`,
      validate: validators.composeValidators(
        validators.required("Email")
        // validators.email
      ),
    },
    {
      type: "password",
      icon: "password",
      name: "password",
      placeholder: `${t("login.form.password_placeholder")}`,
      label: `${t("login.form.password_label")}`,
      validate: validators.composeValidators(
        validators.required("Password"),
        validators.minLength("Password", 6)
      ),
    },
  ];

  return (
    <main className="d-flex align-items-center justify-content-center ">
      <div className={`${styles.login_page} h-100 w-100`}>
        <div className="container h-100">
          <div
            className={`${styles.row} row h-100 flex-column-reverse flex-lg-row`}
          >
            <div className={`${styles.login_content} col-12 col-lg`}>
              <div
                className={`${styles.left_content} d-flex flex-column text-center align-items-center justify-content-center h-100`}
              >
                <h1 className={styles.login_title}>{t("login.title")}</h1>
                <p className={styles.login_subtitle}>{t("login.subtitle")}</p>
                <Form
                  onSubmit={async ({ email: phone, password }) => {
                    await dispatch(
                      adminLogin({ phone, password, phone_code: "+20" })
                    );
                    setTimeout(() => {
                      window.location.replace(redirect || "/");
                    }, 100);
                  }}
                >
                  {({ handleSubmit, submitting, valid }) => (
                    <form
                      className={`${styles.login_form} w-100`}
                      onSubmit={handleSubmit}
                    >
                      <div
                        className={`${styles.inputs__container} d-flex flex-column gap-5`}
                      >
                        {loginFormElements.map((item, i) => (
                          <Field
                            key={i}
                            component={Input}
                            placeholder={item.placeholder}
                            icon={item.icon}
                            type={item.type}
                            name={item.name}
                            id={item.type}
                            label={item.label}
                            validate={item.validate}
                          />
                        ))}
                      </div>
                      <Button
                        type="submit"
                        disabled={!valid}
                        submitting={loading || submitting}
                      >
                        {t("login.form.login_btn")}
                      </Button>
                    </form>
                  )}
                </Form>
              </div>
            </div>
            <div className={`${styles.login_content} col-12 col-lg`}>
              <div
                className={`d-flex align-items-center justify-content-center position-relative w-100 h-100 ${styles.login_image}`}
              >
                <img
                  src={LOGO}
                  alt="logo"
                  className={`${styles.logo}`}
                  style={{ zIndex: 1 }}
                />
                <img
                  src={IMAGE}
                  alt="bg_image"
                  className={`position-absolute w-100 h-100 ${styles.login_bg}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
