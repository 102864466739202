import { EditIcon } from "../../../icons";
import React from "react";

const EditCell = ({ onClick }) => {
  return (
    <button type="button" data-bs-toggletype="button" onClick={onClick}>
      <EditIcon />
    </button>
  );
};

export default EditCell;
