import { createSlice } from "@reduxjs/toolkit";
import { getReports } from "../services/reportsServices";

const initialState = {
  reports: null,
  loading: true,
  error: null,
  totalPages: null,
  TP: null,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  extraReducers: (builder) => {
    // ===== login ======= //
    builder.addCase(getReports.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.reports = null;
    });
    builder.addCase(getReports.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.reports = action.payload.reports;
      state.totalPages = action.payload.totalPages;
      state.TP = action.payload.totalPages;
    });
    builder.addCase(getReports.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
      state.reports = null;
    });
  },
});

export default reportsSlice.reducer;
