import React, { useState } from "react";
import { motion } from "framer-motion";
import { SwitchButton } from "../../../";
import styles from "../.module.scss";
import { useApi } from "../../../../hooks/useApi";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const NotificationsSettings = () => {
  const { settings } = useSelector((state) => state.settings);
  const { t } = useTranslation();
  const { onRequest, data: reqData } = useApi("app-settings", "patch", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return (
    <div className={styles.container}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="d-flex align-items-center justify-content-between w-50">
          <p>{t("tabs.force_android")}</p>
          <SwitchButton
            isOn={settings?.force_android}
            handleToggle={async () => {
              await onRequest({
                force_android: settings?.force_android ? false : true,
              });
            }}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between w-50 my-3">
          <p>{t("tabs.force_iphone")}</p>
          <SwitchButton
            isOn={settings?.force_iphone}
            handleToggle={async () => {
              await onRequest({
                force_iphone: settings?.force_iphone ? false : true,
              });
            }}
          />
        </div>
      </motion.div>
    </div>
  );
};

export default NotificationsSettings;
