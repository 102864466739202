import validators from "../../../util/validators";
import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button, Input, ModalPopup } from "../..";
import { toggleModal } from "../../../redux/features/modalSlice";
import { useApi } from "../../../hooks/useApi";
import { toast } from "react-toastify";
import { getReports } from "../../../redux/services/reportsServices";
import SelectAdapter from "./SelectAdapter";
import { useDispatch } from "react-redux";

const EditReportModal = ({ title, options, currentItem }) => {
  console.log(currentItem);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, onRequest, data } = useApi(
    `admin-reports/update/${currentItem.id}`,
    "patch"
  );

  const formElements = [
    {
      type: "select",
      icon: "gift",
      name: "report_status",
      placeholder: t("forms.select_report_status"),
      label: t("forms.report_status"),
      component: SelectAdapter,
      options: [
        { label: t("forms.pending"), value: "pending" },
        { label: t("forms.under_revision"), value: "under_revision" },
        { label: t("forms.action_taken"), value: "action_taken" },
      ],
      validate: validators.composeValidators(
        validators.required("Report Status")
      ),
      // value: currentItem?.report_status,
    },
    {
      type: "text",
      name: "action",
      component: Input,
      placeholder: t("forms.enter_action"),
      label: t("forms.action"),
      validate: validators.composeValidators(
        // validators.required("Report Action"),
        validators.minLength("Report Action", 0)
      ),
      // value: currentItem?.action
    },
  ];

  useEffect(() => {
    if (data) {
      toast.success(data?.message || t("Saved update successfully"));
      dispatch(toggleModal(""));
      dispatch(getReports(options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ModalPopup title={title}>
      <Form
        // initialValues={{ action: currentItem?.action }}
        onSubmit={(e) => {
          onRequest({
            report_status: e?.report_status?.value,
            action: e?.action,
          });
        }}
      >
        {({ handleSubmit, _, valid }) => (
          <form className={`w-100`} onSubmit={handleSubmit}>
            <div className={` d-flex flex-column gap-5 mb-5`}>
              {formElements.map((item, i) => (
                <Field
                  key={i}
                  component={item.component}
                  placeholder={item.placeholder}
                  icon={item.icon}
                  type={item.type}
                  name={item.name}
                  id={item.type}
                  label={item.label}
                  validate={item.validate}
                  options={item.options}
                  // initialValue={item?.value}
                />
              ))}
            </div>
            <div className="d-flex align-items-center justify-content between gap-4">
              <Button
                type="submit"
                disabled={!valid}
                submitting={loading}
                bgColor="#0ACF83"
              >
                {t("details.save_edits")}
              </Button>
              <Button
                onClick={() => dispatch(toggleModal(""))}
                type="button"
                outline
              >
                {t("details.cancel")}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </ModalPopup>
  );
};

export default EditReportModal;
