import React from "react";
import styles from "./.module.scss";

const MainCard = ({ children, style }) => {
  return (
    <div style={style} className={styles.main_card}>
      {children}
    </div>
  );
};

export default MainCard;
