import React, { useEffect, useState } from "react";
import styles from "../.module.scss";
import { motion } from "framer-motion";
import InputField from "../../../../pages/Settings/InputField";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { SwitchButton } from "../../../";
import { useApi } from "../../../../hooks/useApi";

const content = [
  {
    param: "app_name_ar",
    key: "Application name in Arabic",
  },
  {
    param: "app_name",
    key: "Application name in English",
  },
  {
    param: "tech_sup_email",
    key: "Technical Support Email",
  },
  {
    param: "app_desc_ar",
    key: "Description of the application in Arabic",
  },
  {
    param: "app_desc",
    key: "Description of the application in English",
  },
  {
    param: "fire_base_token",
    key: "FIREBASE SECRET TOKEN",
  },
];

const General = () => {
  const { settings } = useSelector((state) => state.settings);
  const [CONTENT, setCONTENT] = useState(null);
  const { t } = useTranslation();
  const { onRequest, data: reqData } = useApi("app-settings", "patch", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  useEffect(() => {
    let arr = [];
    !isEmpty(settings) &&
      content.forEach((element, i) => {
        arr.push({
          param: element.param,
          key: element.key,
          value: settings[element.param] || "-----",
        });
      });

    setCONTENT(arr);
  }, [settings]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles.container}
    >
      <div className={styles.content_container}>
        {CONTENT?.length
          ? CONTENT.map((item, i) => (
              <div key={i} className="d-flex flex-column">
                <p className={styles.main_text}>{t(`tabs.${item?.param}`)}</p>
                <InputField property={item?.param} value={item?.value} />
              </div>
            ))
          : null}
      </div>
      {CONTENT?.length ? (
        <div className="d-flex align-items-center justify-content-between w-50">
          <p>{t("tabs.app_activated")}</p>
          <SwitchButton
            isOn={settings?.app_activated}
            handleToggle={async () => {
              await onRequest({
                app_activated: settings?.app_activated ? false : true,
              });
            }}
          />
        </div>
      ) : null}
    </motion.div>
  );
};

export default General;
