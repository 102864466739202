import React, { useEffect, useRef } from "react";
import styles from "./.module.scss";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MainLink = ({
  label,
  href,
  nested,
  icon,
  closed,
  setActiveIconTop,
  id,
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const linkRef = useRef();

  useEffect(() => {
    if (href !== "/" && pathname.includes(href)) {
      setActiveIconTop && setActiveIconTop(linkRef?.current?.offsetTop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (closed)
    return (
      <Link
        to={href || ""}
        className={`${styles.main_link} ${
          pathname === "/" && pathname === href ? styles.active : ""
        } ${href !== "/" && pathname.includes(href) ? styles.active : ""} ${
          nested ? styles.nested : ""
        } ${styles.closed_menu} d-flex align-items-center position-relative`}
      >
        {icon}
      </Link>
    );

  return (
    <Link
      ref={linkRef}
      to={href || "/"}
      className={`${styles.main_link} ${
        pathname === "/" && pathname === href ? styles.active : ""
      } ${href !== "/" && pathname.includes(href) ? styles.active : ""} ${
        nested ? styles.nested : ""
      } d-flex align-items-center`}
      id={id}
    >
      <div
        className={`${styles.icon} d-flex align-items-center`}
        style={{ pointerEvents: "none" }}
      >
        {icon}
      </div>
      {t(label)}
    </Link>
  );
};

export default MainLink;
