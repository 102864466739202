import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { toast } from "react-toastify";

export const getPosts = createAsyncThunk(
  "post/getPosts",
  async (
    { pagination, itemsPerPage, searchKeyWord, sortType, sortOption },
    thunkAPI
  ) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await api.get(
        `admin-post/all-posts?page=${pagination}&perPage=${itemsPerPage}&keyword=${
          searchKeyWord ? searchKeyWord : ""
        }`
      );
      return res;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Error !");
      return rejectWithValue(error?.response?.data);
    }
  }
);
