import React, { useEffect, useState } from "react";
import { CheckedIcon, DateIcon, DropdownIcon } from "../../../icons";
import { motion } from "framer-motion";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";

const MainSelectBox = ({
  defaultText,
  top,
  //======
  options,
  active,
  onActive,
  id,
  loading,
  current,
}) => {
  const [openMenu, setopenMenu] = useState(false);
  const { t } = useTranslation();

  const closeDropMenu = (e) => {
    if (e?.target?.id !== id) {
      setopenMenu(false);
    } else {
      setopenMenu((prev) => !prev);
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", closeDropMenu);
    return () => {
      document.body.removeEventListener("click", closeDropMenu);
    };
  }, []);

  return (
    <div className={styles.select_box_container}>
      <motion.button
        id={id}
        type="button"
        className={`${styles.select_box} ${
          active ? "" : styles.default
        } d-flex align-items-center justify-content-between`}
      >
        <div
          className="d-flex align-items-center gap-2"
          style={{ pointerEvents: "none" }}
        >
          <span
            className={`${styles.icon} d-flex align-items-center justify-content-center`}
          >
            <DateIcon />
          </span>
          <div>
            {t(active?.label) || (defaultText && defaultText) || t("options")}
          </div>
        </div>
        {loading && (
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ rotateX: openMenu ? 180 : 0 }}
          transition={{ duration: 0.3, delay: openMenu ? 0 : 0.3 }}
          className={`${styles.dropdown_icon} d-flex align-items-center justify-content-center`}
        >
          <DropdownIcon />
        </motion.div>
      </motion.button>

      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: openMenu ? 1 : 0, height: openMenu ? "auto" : 0 }}
        transition={{ delay: openMenu ? 0 : 0.3, height: 0 }}
        className={`${styles.drop_down_menu} ${openMenu ? styles.opened : ""} ${
          top ? styles.top : ""
        }`}
      >
        {!loading &&
          options &&
          options.map((item) => (
            <span
              key={item?.id}
              onClick={() => {
                setopenMenu(false);
                onActive(item);
              }}
              className={`${styles.option} ${
                active?.id === item?.id ? styles.active : ""
              }`}
            >
              {t(item?.label)}
              {item?.id === active?.id && <CheckedIcon />}
            </span>
          ))}
      </motion.div>
    </div>
  );
};

export default MainSelectBox;
