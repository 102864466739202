import { createSlice } from "@reduxjs/toolkit";
import { getMemberTransactionsServices } from "../services/memberTransactionsServices";
const initialState = {
  loading: true,
  error: null,
  transactions: [],
};

const memberTransactionsSlice = createSlice({
  name: "memberTransactions",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getMemberTransactionsServices.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      getMemberTransactionsServices.fulfilled,
      (state, action) => {
        state.loading = false;
        state.error = null;
        state.transactions = action?.payload?.transactions || [];
      }
    );
    builder.addCase(getMemberTransactionsServices.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default memberTransactionsSlice.reducer;
