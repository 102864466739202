import { createSlice } from "@reduxjs/toolkit";
import { adminLogin } from "../services/adminServices";

const initialState = {
  admin: {
    access_token: window.localStorage.getItem("access_token"),
    user: JSON.parse(localStorage.getItem("user")),
  },
  loading: null,
  error: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(adminLogin.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(adminLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.admin = action.payload;
    });
    builder.addCase(adminLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
      state.admin = null;
    });
  },
});

export default adminSlice.reducer;
