import React, { useState, useEffect } from "react";
import styles from "../.module.scss";
import { SwitchButton } from "../../../";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import InputField from "../../../../pages/Settings/InputField";
import { useApi } from "../../../../hooks/useApi";
import { useTranslation } from "react-i18next";

const content = [
  {
    param: "current_android_v",
    key: "Current Android version",
  },
  {
    param: "current_iphone_v",
    key: "Current iPhone version",
  },
];

const VersionsUpdates = () => {
  const { settings } = useSelector((state) => state.settings);
  const [CONTENT, setCONTENT] = useState(null);
  const { t } = useTranslation();

  const {
    loading,
    onRequest,
    data: reqData,
  } = useApi("app-settings", "patch", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  useEffect(() => {
    let arr = [];
    !isEmpty(settings) &&
      content.forEach((element, i) => {
        arr.push({
          param: element.param,
          key: element.key,
          value: settings[element.param] || "-----",
        });
      });

    setCONTENT(arr);
  }, [settings]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles.container}
    >
      <div className={styles.content_container}>
        {CONTENT?.length
          ? CONTENT.map((item, i) => (
              <div key={i} className="d-flex flex-column">
                <p className={styles.main_text}>{t(`tabs.${item?.param}`)}</p>
                <InputField property={item?.param} value={item?.value} />
              </div>
            ))
          : null}
      </div>
      <div className="d-flex align-items-center justify-content-between w-50">
        <p>{t("tabs.force_android")}</p>
        <SwitchButton
          isOn={settings?.force_android}
          handleToggle={async () => {
            await onRequest({
              force_android: settings?.force_android ? false : true,
            });
          }}
        />
      </div>
      <div className="d-flex align-items-center justify-content-between w-50 mt-3">
        <p>{t("tabs.force_iphone")}</p>
        <SwitchButton
          isOn={settings?.force_iphone}
          handleToggle={async () => {
            await onRequest({
              force_iphone: settings?.force_iphone ? false : true,
            });
          }}
        />
      </div>
    </motion.div>
  );
};

export default VersionsUpdates;
