import { createSlice } from "@reduxjs/toolkit";
import { getBadges } from "../services/badgesServices";

const initialState = {
  badges: null,
  loading: true,
  error: null,
  totalPages: null,
  TP: null,
};

const badgesSlice = createSlice({
  name: "badges",
  initialState,
  extraReducers: (builder) => {
    // ===== login ======= //
    builder.addCase(getBadges.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.badges = null;
    });
    builder.addCase(getBadges.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.badges = action.payload.badge;
      state.totalPages = action.payload.totalPages;
      state.TP = action.payload.totalPages;
    });
    builder.addCase(getBadges.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
      state.badges = null;
    });
  },
});

export default badgesSlice.reducer;
