import React from "react";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";
import { toggleModal } from "../../../redux/features/modalSlice";
import { useDispatch } from "react-redux";

const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <g clip-path="url(#clip0_1072_66935)">
      <path
        d="M20.2668 18.1162C20.0839 18.0996 19.8998 18.0996 19.7168 18.1162C17.7772 18.0442 15.9433 17.2138 14.6095 15.8036C13.2758 14.3934 12.5487 12.5162 12.5848 10.5755C12.6208 8.63484 13.417 6.78583 14.8022 5.42614C16.1874 4.06645 18.0508 3.30469 19.9918 3.30469C21.9328 3.30469 23.7963 4.06645 25.1815 5.42614C26.5666 6.78583 27.3629 8.63484 27.3989 10.5755C27.4349 12.5162 26.7079 14.3934 25.3741 15.8036C24.0404 17.2138 22.2065 18.0442 20.2668 18.1162Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9332 24.2719C7.89987 26.9719 7.89987 31.3719 11.9332 34.0552C14.4553 35.5605 17.3377 36.3552 20.2749 36.3552C23.212 36.3552 26.0944 35.5605 28.6165 34.0552C32.6499 31.3552 32.6499 26.9552 28.6165 24.2719C26.0917 22.7745 23.2103 21.9844 20.2749 21.9844C17.3394 21.9844 14.4581 22.7745 11.9332 24.2719Z"
        stroke="white"
        stroke-width="1.5"
        stroke-cap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1072_66935">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PermissionCard = ({ data, setCurrentItem }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const randomStyles = [
    "primary_purple",
    "secondary_cyan",
    "primary_yellow",
    "secondary_green",
    "secondary_blue",
    "secondary_purple",
  ];

  var item = randomStyles[Math.floor(Math.random() * randomStyles.length)];

  return (
    <div
      className={`${styles.card} ${styles[item]} d-flex flex-column align-items-center justify-content-center`}
    >
      <div
        className={`${styles.card_icon} d-flex align-items-center justify-content-center`}
      >
        <Icon />
      </div>
      <div className={styles.role}>{t(`permissions.${data?.moduleName}`)}</div>
      <div className={styles.card_num_premissions}>
        {data?.permissions?.filter((item) => item.active)?.length}{" "}
        {t(`details.of`)} {data?.permissions?.length} {t("details.permissions")}{" "}
      </div>
      <button
        onClick={() => {
          setCurrentItem(data);
          dispatch(toggleModal("view__permissions__details"));
        }}
        className={styles.link}
      >
        {t("permissions.see_details")}{" "}
      </button>
      {/* <Link className={styles.link} to={`${data?.moduleName}`}>
        {t("permissions.see_details")}{" "}
      </Link> */}
    </div>
  );
};

export default PermissionCard;
