import React, { useMemo } from "react";
import { MainCard, PageHeader, PageTitle, SearchBox } from "../../";
import { AddIcon } from "../../../icons";
import { useTranslation } from "react-i18next";
import MainTable from "../../UI/MainTable";
import DefaultColumnFilter from "../../UI/MainTable/DefaultColumnFilter";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import styles from "./.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { onActiveFilter } from "../../../redux/features/memberSlice";

const MainPageDesign = ({
  options,
  setOptions,
  title,
  activeInActive,
  no,
  searchBar,
  data,
  columns,
  addButton,
  onOpenPopup,
  tableLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { active } = useSelector((state) => state?.members);
  const defaultColumn = useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter || [],
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    pageCount,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: options?.itemsPerPage },
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <>
      <MainCard>
        <PageHeader>
          <div
            className={`d-flex align-items-center justify-content-between w-100 flex-wrap flex-md-nowrap gap-4`}
          >
            <div className={`d-flex align-items-center gap-3`}>
              <PageTitle title={title} />
              <span className="dash"></span>
              <div className={`members_no text-nowrap`}>{no || ""}</div>
            </div>
            <div
              className={`d-flex align-items-center justify-content-end gap-3 ms-auto w-100`}
            >
              {searchBar && (
                <SearchBox
                  options={options}
                  setOptions={setOptions}
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              )}
              {addButton && (
                <button
                  onClick={onOpenPopup}
                  type="button"
                  className={`main_btn flex-1 add_btn d-flex align-items-center justify-content gap-2`}
                >
                  <AddIcon />
                  {t("details.add")}
                </button>
              )}
            </div>
          </div>
        </PageHeader>
        {activeInActive && (
          <div className="d-flex align-items-center gap-2 pb-4">
            <button
              className={`${styles.active_btn} ${active ? styles.active : ""}`}
              onClick={() => {
                setOptions({ ...options, pagination: 1 });
                dispatch(onActiveFilter("active"));
              }}
            >
              {t("active")}
            </button>
            <button
              className={`${styles.active_btn} ${active ? "" : styles.active}`}
              onClick={() => dispatch(onActiveFilter("inactive"))}
            >
              {t("inactive")}
            </button>
          </div>
        )}
        <>
          <MainTable
            data={data || []}
            columns={columns || []}
            getTableProps={getTableProps}
            headerGroups={data?.length && headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            tableLoading={tableLoading}
            pageCount={pageCount}
            options={options}
            setOptions={setOptions}
          />
        </>
      </MainCard>
    </>
  );
};

export default MainPageDesign;
