import React, { useEffect, useState } from "react";
import { Error, MainPageDesign } from "../../../components";
import { useTranslation } from "react-i18next";
import { allMembersColumns } from "../../../util/data";
import { useDispatch, useSelector } from "react-redux";
import { getAllMembers } from "../../../redux/services/memberServices";

const AllMembers = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // get members
  const {
    totalPages,
    error,
    members,
    loading: tableLoading,
  } = useSelector((state) => state?.members);

  const [options, setOptions] = useState({
    isPaginated: totalPages > 1,
    pagination: 1,
    itemsPerPage: 20,
    searchKeyWord: null,
    totalPages,
    sortType: null,
    sortOption: null,
  });

  useEffect(() => {
    setOptions({
      ...options,
      totalPages: totalPages,
      isPaginated: totalPages > 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, members]);

  const table = {
    columns: allMembersColumns,
    data: members,
  };

  useEffect(() => {
    dispatch(getAllMembers(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.pagination,
    options.searchKeyWord,
    options.sortOption,
    options?.sortType,
  ]);
  if (error) return <Error msg={error?.message} />;
  return (
    <>
      <MainPageDesign
        searchBar
        title={t("All Members")}
        no={`${members?.length || "0"} ${t("details.member")}`}
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        data={table?.data || []}
        columns={table?.columns || []}
        tableLoading={tableLoading}
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        options={options || {}}
        setOptions={setOptions}
      />
    </>
  );
};

export default AllMembers;
