import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getBadges } from "../../redux/services/badgesServices";
import { useParams } from "react-router-dom";
import {
  BadgesModal,
  DeleteCell,
  DeleteModal,
  EditCell,
  Error,
  MainPageDesign,
  CopyTextButton,
  UserLabelInfo,
} from "../../components";
import { toggleModal } from "../../redux/features/modalSlice";
import { useApi } from "../../hooks/useApi";
import { toast } from "react-toastify";

const BadgesPage = () => {
  const { t } = useTranslation();
  const [currentItem, setCurrentItem] = useState(null);
  const { id } = useSelector((state) => state?.modal);
  const dispatch = useDispatch();
  const { type: badgesType } = useParams();
  const {
    totalPages,
    error,
    badges,
    loading: tableLoading,
  } = useSelector((state) => state?.badges);

  const [options, setOptions] = useState({
    isPaginated: totalPages > 1,
    pagination: 1,
    itemsPerPage: 20,
    searchKeyWord: null,
    totalPages,
    sortType: null,
    sortOption: null,
    badgesType,
  });

  useEffect(() => {
    setOptions({
      isPaginated: totalPages > 1,
      pagination: 1,
      itemsPerPage: 20,
      searchKeyWord: null,
      totalPages,
      sortType: null,
      sortOption: null,
      badgesType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badgesType]);

  useEffect(() => {
    setOptions({
      ...options,
      totalPages: totalPages,
      isPaginated: totalPages > 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, badges]);

  useEffect(() => {
    dispatch(getBadges(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options?.pagination,
    options?.searchKeyWord,
    options?.sortOption,
    options?.sortType,
    options?.badgesType,
  ]);

  useEffect(() => {
    if (!id) {
      setCurrentItem(null);
    }
  }, [id]);

  const table = {
    columns: [
      {
        Header: "table.headers.id",
        accessor: "random_id",
        sortKey: "id",
        sorting: true,
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center jsutify-content-center gap-2">
            <CopyTextButton text={cellInfo?.row?.original?.id || "---"} />
          </div>
        ),
      },
      { Header: "Name En", accessor: "name", sortKey: "name", sorting: true },
      {
        Header: "Name Ar",
        accessor: "nameAr",
        sortKey: "nameAr",
        sorting: true,
      },
      { Header: "Tasks", accessor: "task", sortKey: "task", sorting: true },
      // {
      //   Header: "Image",
      //   accessor: "img",
      //   Cell: (cellInfo) => {
      //     return (
      //       <>
      //         {/* {console.log(cellInfo?.row?.original?.img)} */}
      //         <Svga className={"svga"} src={svgaIcon} />
      //       </>
      //     );
      //   },
      // },
      {
        Header: "Icon",
        accessor: "icon",
        Cell: (cellInfo) => (
          <UserLabelInfo img={cellInfo?.row?.original?.icon} alt="user image" />
        ),
      },
      {
        Header: "table.headers.action",
        accessor: "",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <EditCell
              onClick={() => {
                setCurrentItem(cellInfo?.row?.original);
                dispatch(toggleModal("badge-modal"));
              }}
            />
            <DeleteCell
              onClick={() => {
                setCurrentItem(cellInfo?.row?.original);
                dispatch(toggleModal("delete-modal"));
              }}
            />
          </div>
        ),
      },
    ],
    data: badges,
  };

  // onDelete
  const {
    loading: isSubmitting,
    onRequest,
    data,
  } = useApi(`badges/${currentItem?.id}`, "delete");

  useEffect(() => {
    if (data) {
      toast.success(data?.message || t("deleted successfully"));
      dispatch(toggleModal(""));
      dispatch(getBadges(options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error msg={error?.message} />;

  return (
    <>
      <MainPageDesign
        title={t(`badges.${badgesType}`)}
        no={`${badges?.length || "0"} ${t("badges.badge")}`}
        searchBar
        addButton
        data={table?.data || []}
        columns={table?.columns || []}
        onOpenPopup={() => dispatch(toggleModal("badge-modal"))}
        tableLoading={tableLoading}
        options={options || {}}
        setOptions={setOptions}
        // ========================
      />
      {id === "badge-modal" && (
        <BadgesModal
          type={badgesType}
          options={options}
          setOptions={setOptions}
          init={currentItem}
          id={currentItem?.id}
        />
      )}
      {id === "delete-modal" && (
        <DeleteModal
          type={t("badge")}
          onClose={() => dispatch(toggleModal(""))}
          isSubmitting={isSubmitting}
          options={options}
          setOptions={setOptions}
          dispatch={dispatch}
          action={onRequest}
          afterAction={() => {
            setOptions({ ...options, pagination: 1 });
            dispatch(getBadges(options));
          }}
          itemID={currentItem?.id}
        />
      )}
    </>
  );
};

export default BadgesPage;
