import { createSlice } from "@reduxjs/toolkit";
import { getLevels } from "../services/levelservices";

const initialState = {
  levels: null,
  loading: true,
  error: null,
  totalPages: null,
  TP: null,
};

const levelsSlice = createSlice({
  name: "levels",
  initialState,
  extraReducers: (builder) => {
    // ===== login ======= //
    builder.addCase(getLevels.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.levels = null;
    });
    builder.addCase(getLevels.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.levels = action.payload.levels;
      state.totalPages = action.payload.totalPages;
      state.TP = action.payload.totalPages;
    });
    builder.addCase(getLevels.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
      state.levels = null;
    });
  },
});

export default levelsSlice.reducer;
