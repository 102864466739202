import React from "react";
import styles from "./input.module.scss";
import Select from "react-select";

const SelectAdapter = ({
  input,
  label,
  id,
  icon,
  meta: { touched, error, warning },
  ...rest
}) => {
  return (
    <div
      className={`${styles.fix_container} ${styles.input_container} ${
        (touched && error) || warning ? styles.error : ""
      } d-flex align-items-center position-relative w-100`}
    >
      <label
        htmlFor={id}
        className={`${styles.label} ${styles.fix} position-absolute`}
      >
        {label}
      </label>
      <Select
        className={`${styles.fix} ${styles.input} ${
          icon ? "" : styles.no_icon
        } d-block`}
        {...input}
        {...rest}
      />
    </div>
  );
};
export default SelectAdapter;
