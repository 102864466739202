import React, { useEffect, useState } from "react";
import {
  DeleteCell,
  DeleteModal,
  EditCell,
  Error,
  MainPageDesign,
  ModalLevel,
  CopyTextButton,
} from "../../components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getLevels } from "../../redux/services/levelservices";
import { toggleModal } from "../../redux/features/modalSlice";
import moment from "moment";
import { useApi } from "../../hooks/useApi";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const LevelsPage = () => {
  const { t } = useTranslation();
  const [currentItem, setCurrentItem] = useState(null);
  const { id } = useSelector((state) => state?.modal);
  const dispatch = useDispatch();
  const { type: levelType } = useParams();

  const {
    totalPages,
    error,
    levels,
    loading: tableLoading,
  } = useSelector((state) => state?.levels);

  const [options, setOptions] = useState({
    isPaginated: totalPages > 1,
    pagination: 1,
    itemsPerPage: 20,
    searchKeyWord: null,
    totalPages,
    sortType: null,
    sortOption: null,
    typeLevel: levelType.slice(0, -1),
  });

  useEffect(() => {
    setOptions({
      isPaginated: totalPages > 1,
      pagination: 1,
      itemsPerPage: 20,
      searchKeyWord: null,
      totalPages,
      sortType: null,
      sortOption: null,
      typeLevel: levelType.slice(0, -1),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelType]);

  useEffect(() => {
    setOptions({
      ...options,
      totalPages: totalPages,
      isPaginated: totalPages > 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, levels]);

  useEffect(() => {
    dispatch(getLevels(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.pagination,
    options.searchKeyWord,
    options.sortOption,
    options?.sortType,
    options?.typeLevel,
  ]);

  useEffect(() => {
    if (!id) {
      setCurrentItem(null);
    }
  }, [id]);

  const table = {
    columns: [
      {
        Header: "table.headers.id",
        accessor: "random_id",
        sortKey: "id",
        sorting: true,
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center jsutify-content-center gap-2">
            <CopyTextButton text={cellInfo?.row?.original?.id || "---"} />
          </div>
        ),
      },
      {
        Header: "Name En",
        accessor: "name",
        sorting: true,
        sortKey: "name",
      },
      {
        Header: "Name Ar",
        accessor: "nameAr",
        sorting: true,
        sortKey: "nameAr",
      },
      {
        Header: "Level Number",
        accessor: "level_number",
        sorting: true,
        sortKey: "level_number",
      },
      {
        Header: "exp",
        accessor: "exp",
        sorting: true,
        sortKey: "exp",
      },
      {
        Header: "Image",
        accessor: "level_img",
        Cell: (cellInfo) => (
          <img
            style={{ width: "40px" }}
            src={cellInfo?.row?.original?.level_img}
            alt="levelImage"
          />
        ),
      },
      {
        Header: "created at",
        accessor: "createdAt",
        // sorting: true,
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center justify-content-center gap-2">
            {moment(cellInfo?.row?.original?.createdAt).fromNow()}
          </div>
        ),
      },
      {
        Header: "updated at",
        accessor: "updatedAt",
        // sorting: true,
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center justify-content-center gap-2">
            {moment(cellInfo?.row?.original?.updatedAt).fromNow()}
          </div>
        ),
      },
      {
        Header: "table.headers.action",
        accessor: "",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <EditCell
              onClick={() => {
                setCurrentItem(cellInfo?.row?.original);
                dispatch(toggleModal("create-level"));
              }}
            />
            <DeleteCell
              onClick={() => {
                setCurrentItem(cellInfo?.row?.original);
                dispatch(toggleModal("delete-modal"));
              }}
            />
          </div>
        ),
      },
    ],
    data: levels,
  };

  // onDelete
  const {
    loading: isSubmitting,
    onRequest,
    data,
  } = useApi(`levels/delete/${currentItem?.id}`, "delete");

  useEffect(() => {
    if (data) {
      toast.success(data?.message || t("deleted successfully"));
      dispatch(toggleModal(""));
      dispatch(getLevels(options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error msg={error?.message} />;
  return (
    <>
      <MainPageDesign
        searchBar
        addButton
        title={t(`levels.${levelType}.title`)}
        no={`${table?.data?.length || "0"} ${t("details.levels")}`}
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        data={table?.data || []}
        columns={table?.columns || []}
        onOpenPopup={() => dispatch(toggleModal("create-level"))}
        tableLoading={tableLoading}
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        options={options || {}}
        setOptions={setOptions}
        // ========================
      />
      {id === "create-level" && (
        <ModalLevel
          init={currentItem}
          id={currentItem?.id}
          type={levelType.slice(0, -1)}
          options={options}
          setOptions={setOptions}
        />
      )}
      {id === "delete-modal" && (
        <DeleteModal
          type={t("level")}
          onClose={() => dispatch(toggleModal(""))}
          isSubmitting={isSubmitting}
          options={options}
          setOptions={setOptions}
          dispatch={dispatch}
          action={onRequest}
          afterAction={() => {
            setOptions({ ...options, pagination: 1 });
            dispatch(getLevels(options));
          }}
          itemID={currentItem?.id}
        />
      )}
    </>
  );
};

export default LevelsPage;
