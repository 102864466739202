import React, { useEffect, useState } from "react";
import {
  ChatSettings,
  General,
  MainCard,
  MainTabs,
  NotificationsSettings,
  PageHeader,
  PageTitle,
  VersionsUpdates,
} from "../../components";
import { useTranslation } from "react-i18next";
import styles from "./.module.scss";
import { getSettings } from "../../redux/services/settingsServices";
import { useDispatch, useSelector } from "react-redux";

const tabsContent = {
  general: <General />,
  chat_settings: <ChatSettings />,
  versions_updates: <VersionsUpdates />,
  notifications: <NotificationsSettings />,
};

const tabs = [
  {
    label: "general",
    id: "general",
  },
  {
    label: "chat settings",
    id: "chat_settings",
  },
  {
    label: "versions & updates",
    id: "versions_updates",
  },
  {
    label: "notifications",
    id: "notifications",
  },
];

const SettingsPage = () => {
  const [active, setActive] = useState("general");
  const [activeContent, setActiveContent] = useState(tabsContent.general);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  return (
    <div className={styles.page}>
      <MainCard>
        <PageHeader>
          <div className="d-flex align-items-center gap-2 justify-content-between">
            <PageTitle title={t("details.settings")} />
          </div>
        </PageHeader>
        <MainTabs
          slidesPerView={5}
          noNavigations
          active={active}
          setActive={(e) => {
            setActive(e);
            setActiveContent(tabsContent[[e]]);
          }}
          tabs={tabs}
        />
        {activeContent}
      </MainCard>
    </div>
  );
};

export default SettingsPage;
