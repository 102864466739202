import React, { useEffect, useState } from "react";
import { MainCard, PermissionCard } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import styles from "./.module.scss";
import { getUserPermissions } from "../../../redux/services/permissionsServices";
import { Error } from "../../../components";
import { ViewPermissionsDetails } from "../../../components";
import { useParams } from "react-router-dom";

const MemberSettings = () => {
  const dispatch = useDispatch();
  const { id: userID } = useParams();
  const [currentItem, setCurrentItem] = useState(null);

  const { adminPermissions, permissionsMap, _, error } = useSelector(
    (state) => state.permissions
  );

  const { id } = useSelector((state) => state?.modal);

  useEffect(() => {
    dispatch(getUserPermissions({ id: userID }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) return <Error msg={error?.message} />;

  return (
    <>
      <MainCard>
        <div className={styles.cards_container}>
          {permissionsMap?.length &&
            permissionsMap.map((data, i) => (
              <PermissionCard
                userID={userID}
                adminPermissions={adminPermissions}
                permissionsMap={permissionsMap}
                setCurrentItem={setCurrentItem}
                data={data}
                key={i}
                slug={i}
              />
            ))}
        </div>
      </MainCard>
      {id === "view__permissions__details" && (
        <ViewPermissionsDetails
          userID={userID}
          adminPermissions={adminPermissions}
          permissionsMap={permissionsMap}
          item={currentItem}
        />
      )}
    </>
  );
};

export default MemberSettings;
