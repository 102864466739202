import { createSlice } from "@reduxjs/toolkit";
import { getSettings } from "../services/settingsServices";

const initialState = {
  settings: null,
  loading: true,
  error: null,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSettings.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.settings = null;
    });
    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.settings = action.payload.setting;
    });
    builder.addCase(getSettings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
      state.settings = null;
    });
  },
});

export default settingsSlice.reducer;
