import { createSlice } from "@reduxjs/toolkit";
import { getAllItems } from "../services/categoriesServices";

const initialState = {
  items: null,
  loading: true,
  error: null,
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: (builder) => {
    // ===== getAllItems ======= //
    builder.addCase(getAllItems.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.items = null;
    });
    builder.addCase(getAllItems.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.items = action.payload;
    });
    builder.addCase(getAllItems.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
      state.items = null;
    });
  },
});

export default categoriesSlice.reducer;
