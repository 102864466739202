import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";

import {
  Error,
  MainPageDesign,
  UserLabelInfo,
  CopyTextButton,
} from "../../components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoomUsers } from "../../redux/services/roomsServices";
import { useLocation } from "react-router-dom";
import { RiGroupFill } from "react-icons/ri";
import approx from "approximate-number";
import ToggleBtn from "../../pages/Members/AllMembers/ToggleBtn";
import moment from "moment";
import { useApi } from "../../hooks/useApi";

const AllRooms = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const location = useLocation();
  const [isActive, setisActive] = useState(null);

  useEffect(() => {
    const path = String(location.pathname.split("/")[2])
      .toLocaleLowerCase()
      .split("-")[0];
    setisActive(path === "active");
  }, [location]);

  // get members
  const {
    totalPages,
    error,
    rooms,
    loading: tableLoading,
  } = useSelector((state) => state?.rooms);

  const [options, setOptions] = useState({
    isPaginated: totalPages > 1,
    pagination: 1,
    itemsPerPage: 20,
    searchKeyWord: null,
    totalPages,
    sortType: null,
    sortOption: null,
    status: null,
  });

  useEffect(() => {
    setOptions({
      ...options,
      totalPages: totalPages,
      isPaginated: totalPages > 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, rooms]);

  useEffect(() => {
    setOptions({
      ...options,
      pagination: 1,
      searchKeyWord: null,
      sortType: null,
      sortOption: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    isActive !== null &&
      setOptions({ ...options, status: isActive ? "active" : "not-Active" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const table = {
    columns: [
      {
        Header: "room_name",
        accessor: "full_name",
        sorting: true,
        sortKey: "name",
        Cell: (cellInfo) => (
          <UserLabelInfo
            img={cellInfo?.row?.original?.uploaded_avatar}
            alt="room image"
            name={cellInfo?.row?.original?.name}
            activeStatus
            online={cellInfo?.row?.original?.status !== "active" ? false : true}
          />
        ),
      },
      {
        Header: "table.headers.room_id",
        accessor: "room_id",
        sorting: true,
        sortKey: "id",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center jsutify-content-center gap-2">
            <CopyTextButton text={cellInfo?.row?.original?.id || "---"} />
          </div>
        ),
      },
      {
        Header: "table.headers.room_owner",
        accessor: "room_owner",
        // sorting: true,
        Cell: (cellInfo) => (
          <UserLabelInfo
            img={cellInfo?.row?.original?.owner?.profile_photo}
            alt="room image"
            name={cellInfo?.row?.original?.owner?.full_name}
            activeStatus
            online={cellInfo?.row?.original?.owner?.is_online}
          />
        ),
      },
      {
        Header: "random_id",
        accessor: "random_id",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center jsutify-content-center gap-2">
            <CopyTextButton text={cellInfo?.row?.original?.user_id || "---"} />
          </div>
        ),
        // cellInfo?.row?.original?.random_id || "---",
      },
      {
        Header: "description",
        accessor: "data.description",
        Cell: (cellInfo) => (
          <span style={{ maxWidth: "200px", overflow: "hidden" }}>
            {cellInfo?.row?.original?.description || "--"}
          </span>
        ),
      },
      {
        Header: "users count",
        accessor: "rooms.length",
        Cell: (cellInfo) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: ".75rem",
            }}
          >
            <RiGroupFill style={{ color: "#6278ff", fontSize: "24px" }} />
            <span>
              {approx(cellInfo?.row?.original?.roomUsers?.length || 0, {
                capital: true,
              })}
            </span>
          </div>
        ),
      },
      {
        Header: "active",
        accessor: "hosting_room",
        Cell: (cellInfo) => {
          const { loading, onRequest, data } = useApi(
            `room-admin/updateRoom/${cellInfo?.row?.original?.id}`,
            "patch"
          );
          return (
            <ToggleBtn
              loading={loading}
              onRequest={() =>
                onRequest({
                  status:
                    cellInfo?.row?.original?.status === "active"
                      ? "not-Active"
                      : "active",
                })
              }
              data={data}
              status={cellInfo?.row?.original?.status === "active"}
            />
          );
          // return cellInfo?.row?.original?.active ? (
          //   <span className={styles.active}>{t("details.yes")}</span>
          // ) : (
          //   <span className={styles.unactive}>{t("details.no")}</span>
          // );
        },
      },
      {
        Header: "topic name",
        accessor: "topic.name",
        Cell: (cellInfo) => (
          <span
            style={{
              color: "#1363e2",
              textTransform: "capitalize",
              fontWeight: "600",
            }}
          >
            {cellInfo?.row?.original?.topic?.name
              ? t(`interests.${cellInfo?.row?.original?.topic?.name}`)
              : "---"}
          </span>
        ),
      },
      // {
      //   Header: "pin",
      //   accessor: "data.pin",
      //   Cell: (cellInfo) =>
      //     !cellInfo?.row?.original?.pin ? "Not Pinned" : "Pinned" || "--",
      // },
      {
        Header: "created at",
        accessor: "created_at",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center justify-content-center gap-2">
            {moment(cellInfo?.row?.original?.created_at).fromNow()}
          </div>
        ),
      },
      {
        Header: "table.headers.action",
        accessor: "action",
        Cell: (cellInfo) => {
          return (
            <Link
              style={{ color: "#0d6efd", fontSize: "24px" }}
              to={`/room/${cellInfo?.row?.original?.id}`}
              className="d-flex align-items-center justiy-content-center"
            >
              <IoEyeOutline />
            </Link>
          );
        },
      },
    ],
    data: rooms,
  };

  useEffect(() => {
    (async () => {
      options?.status && dispatch(getAllRoomUsers(options));
      // dispatch(onActiveFilter(isActive ? "active" : "inactive"));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.pagination,
    options.searchKeyWord,
    options.sortOption,
    options?.sortType,
    options?.status,
  ]);

  if (error) return <Error msg={error?.message} />;

  return (
    <>
      <MainPageDesign
        searchBar
        title={t("rooms.active_rooms.title")}
        no={`${rooms?.length || "0"} ${t("details.rooms")}`}
        // //////////////////////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////////////////////
        data={table?.data || []}
        columns={table?.columns || []}
        tableLoading={tableLoading}
        // //////////////////////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////////////////////
        options={options || {}}
        setOptions={setOptions}
      />
    </>
  );
};

export default AllRooms;
