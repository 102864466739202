import React, { useState, useEffect } from "react";
import {
  AddNewDesign,
  DeleteCell,
  DeleteModal,
  EditCell,
  MainPageDesign,
  ModalPopup,
  Error,
} from "../../components";
import { useApi } from "../../hooks/useApi";
import { toast } from "react-toastify";
import { AllDesignsColumns } from "../../util/data";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getDesignStoreItems } from "../../redux/services/designStoreItemsServices";
import { toggleModal } from "../../redux/features/modalSlice";
import { useParams } from "react-router-dom";

const AllDesignsStorePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState(null);
  const { id } = useSelector((state) => state?.modal);
  const { type: categoryType } = useParams();

  const {
    totalPages,
    error,
    items,
    loading: tableLoading,
  } = useSelector((state) => state?.designStore);

  const [options, setOptions] = useState({
    isPaginated: totalPages > 1,
    pagination: 1,
    itemsPerPage: 20,
    searchKeyWord: null,
    totalPages,
    sortType: null,
    sortOption: null,
    categoryType,
  });

  useEffect(() => {
    setOptions({
      ...options,
      totalPages: totalPages,
      isPaginated: totalPages > 1,
      categoryType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, items, categoryType]);

  const table = {
    columns: [
      ...AllDesignsColumns,
      {
        Header: "table.headers.action",
        accessor: "",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <EditCell
              onClick={() => {
                setCurrentItem(cellInfo?.row?.original);
                dispatch(toggleModal("create-store__item"));
              }}
            />
            <DeleteCell
              onClick={() => {
                setCurrentItem(cellInfo?.row?.original);
                dispatch(toggleModal("delete-item--modal"));
              }}
            />
          </div>
        ),
      },
    ],
    data: items,
  };

  useEffect(() => {
    if (!id) {
      setCurrentItem(null);
    }
  }, [id]);

  useEffect(() => {
    dispatch(getDesignStoreItems(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.pagination,
    options.searchKeyWord,
    options.sortOption,
    options?.sortType,
    options?.categoryType,
  ]);

  // onDelete
  const {
    loading: isSubmitting,
    onRequest,
    data,
  } = useApi(`admin-category-item/item/delete/${currentItem?.id}`, "delete");

  useEffect(() => {
    if (data) {
      toast.success(data?.message || t("deleted successfully"));
      dispatch(toggleModal(""));
      dispatch(getDesignStoreItems(options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error msg={error?.message} />;

  return (
    <>
      <MainPageDesign
        addButton
        searchBar
        title={t(`${"details.items"}`)}
        no={`${items?.length || "0"} ${t(`details.${"item"}`)}`}
        onOpenPopup={() => dispatch(toggleModal("create-store__item"))}
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        data={table?.data || []}
        columns={table?.columns || []}
        tableLoading={tableLoading}
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        options={options || {}}
        setOptions={setOptions}
      />
      {id === "delete-item--modal" && (
        <DeleteModal
          text={t("modal.delete_theme_text")}
          onClose={() => dispatch(toggleModal(""))}
          isSubmitting={isSubmitting}
          options={options}
          setOptions={setOptions}
          dispatch={dispatch}
          action={onRequest}
          afterAction={() => {
            setOptions({ ...options, pagination: 1 });
            dispatch(getDesignStoreItems(options));
          }}
          itemID={currentItem?.id}
        />
      )}
      {id === "create-store__item" && (
        <AddNewDesign
          type={categoryType}
          options={options}
          setOptions={setOptions}
          init={currentItem}
          id={currentItem?.id}
          categoryType={categoryType}
        />
      )}
    </>
  );
};

export default AllDesignsStorePage;
