import React, { useState, useEffect } from "react";
import { Field, Form } from "react-final-form";
import { Button, DragDropFiled } from "../..";
import { useTranslation } from "react-i18next";
import styles from "./.module.scss";
import { useApi } from "../../../hooks/useApi";
import { toast } from "react-toastify";
import { toggleModal } from "../../../redux/features/modalSlice";

const AddMemberAvatar = ({
  dispatch,
  options,
  setOptions,
  getMembersAvatars,
}) => {
  const { onRequest, data: reqData } = useApi("/admin-avatar/create ", "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  const { t } = useTranslation();

  const [image, setImage] = useState(null);
  const [data, setData] = useState({
    image,
  });
  useEffect(() => {
    setData({ ...data, image });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  useEffect(() => {
    if (reqData) {
      setOptions({
        ...options,
        pagination: 1,
      });
      dispatch(toggleModal(""));
      dispatch(getMembersAvatars(options));
      toast.success(reqData?.message || t("Saved update successfully"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData]);

  // const formElements = [
  // {
  //   type: "text",
  //   icon: "",
  //   name: "title",
  //   placeholder: t("forms.enter_theme_title"),
  //   label: t("forms.title"),
  //   validate: validators.composeValidators(
  //     validators.required("Title"),
  //     validators.minLength("title", 4)
  //   ),
  // },
  // ];
  const onSubmit = async (values) => {
    const formDate = new FormData();
    formDate.append("file", data.image);
    await onRequest(formDate);
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting, valid }) => (
        <form
          encType="multipart/form-data"
          className={`w-100`}
          onSubmit={handleSubmit}
        >
          <div
            className={`${styles.inputs_container} d-flex flex-column gap-5 mb-5`}
          >
            <Field
              component={() => (
                <DragDropFiled image={image} setImage={setImage} />
              )}
              name={"notify_photo"}
            />
            {/* {formElements.map((item, i) => (
              <Field
                key={i}
                component={Input}
                placeholder={item.placeholder}
                icon={item.icon}
                type={item.type}
                name={item.name}
                id={item.type}
                label={item.label}
                validate={item.validate}
              />
            ))} */}
          </div>
          {/* <div
            className={`${styles.switch} d-flex align-items-center justify-content-between gap-3 w-100 mb-4`}
          >
            <p>{t("details.mark_main_theme_rooms")}</p>
            <SwitchButton
              isOn={data?.main_avatar}
              handleToggle={() =>
                setData({
                  ...data,
                  main_avatar: data?.main_room ? false : true,
                })
              }
            />
          </div> */}
          <div
            className={`${styles.form_footer} d-flex align-items-center justify-content-between w-100`}
          >
            <Button
              type="submit"
              disabled={!valid || !image}
              submitting={submitting}
            >
              {t("details.add_avatar")}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

export default AddMemberAvatar;
