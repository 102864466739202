import React, { useRef } from "react";
import { ArrowIcon } from "../../../icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/bundle";
import "./swiper.scss";

const MainTabs = ({
  tabs,
  setActive,
  active,
  slidesPerView,
  noNavigations,
}) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const { t } = useTranslation();

  return (
    <div className={"swiper_container"}>
      <Swiper
        slidesPerView={slidesPerView || 8}
        grabCursor={true}
        className={"swiper"}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        modules={[Navigation]}
      >
        {tabs.map((tab) => (
          <SwiperSlide key={tab.id}>
            <button
              className={`${tab.id === active ? "active__" : ""} tab`}
              onClick={() => setActive(tab.id)}
            >
              {t(`details.${tab.id}`)}
            </button>
          </SwiperSlide>
        ))}
        <button
          className={`prev__button ${noNavigations ? "no_navigation_web" : ""}`}
          ref={prevRef}
        >
          <ArrowIcon />
        </button>
        <button
          className={`next__button ${noNavigations ? "no_navigation_web" : ""}`}
          ref={nextRef}
        >
          <ArrowIcon />
        </button>
      </Swiper>
    </div>
  );
};

export default MainTabs;
