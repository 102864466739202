import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { toast } from "react-toastify";

export const getRoomDetails = createAsyncThunk(
  "rooms/getRoomDetails",
  async ({ id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await api.get(`room-admin/getAllRoomInfo`, {
        params: {
          keyword: id,
          status: "ALL",
        },
      });
      return { ...res?.data, ID: id };
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Error !");
      return rejectWithValue(error?.response?.data);
    }
  }
);
