import { createSlice } from "@reduxjs/toolkit";
import { getMemberDetails } from "../services/memberDetailsServices";
const initialState = {
  loading: true,
  error: null,
  data: {
    userInfo: {},
    roomInfo: {},
    walletInfo: {},
    interests: [],
    connections: {
      friends: [],
      following: [],
      followers: [],
    },
  },
};

const memberSlice = createSlice({
  name: "memberDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getMemberDetails.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getMemberDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.connections = {
        friends: action?.payload?.friends,
        following: action?.payload?.following,
        followers: action?.payload?.follow,
      };
      state.data.walletInfo = action?.payload?.wallet;
      state.data.interests = action?.payload?.interested;
      state.data.roomInfo = {
        name: action?.payload?.room?.name,
        avatar: action?.payload?.room?.uploaded_avatar,
        created_at: action?.payload?.room?.created_at,
        facts: [
          { fact_name: "room_id", fact_value: action?.payload?.room?.id },
          {
            fact_name: "topic",
            fact_value: action?.payload?.room?.topic?.name,
          },
          {
            fact_name: "room_status",
            fact_value:
              action?.payload?.room?.status !== "not-Active"
                ? "Active"
                : "Inactive",
            type:
              action?.payload?.room?.status !== "not-Active"
                ? "active"
                : "inactive",
          },
          {
            fact_name: "room_chat",
            fact_value:
              action?.payload?.room?.room_chat !== "not-Active"
                ? "Active"
                : "Inactive",
            type:
              action?.payload?.room?.room_chat !== "not-Active"
                ? "active"
                : "inactive",
          },
          {
            fact_name: "charisma_level",
            fact_value: action?.payload?.room?.charisma_level
              ? "Active"
              : "Inactive",
            type: action?.payload?.room?.charisma_level ? "active" : "inactive",
          },
          {
            fact_name: "special_room",
            fact_value: action?.payload?.room?.special ? "Active" : "Inactive",
            type: action?.payload?.room?.special ? "active" : "inactive",
          },
        ],
      };
      state.data.userInfo = {
        name: action?.payload?.full_name,
        avatar: action?.payload?.profile_photo,
        created_at: action?.payload?.created_at,

        facts: [
          { fact_name: "user_id", fact_value: action?.payload?.random_id },
          { fact_name: "phone_number", fact_value: action?.payload?.phone },
          { fact_name: "country", fact_value: action?.payload?.country_code },
          { fact_name: "email", fact_value: action?.payload?.email },
          // {
          //   fact_name: "activity",
          //   fact_value: action?.payload?.is_online ? "Active" : "Inactive",
          //   type: action?.payload?.is_online ? "active" : "inactive",
          // },
          { fact_name: "gender", fact_value: action?.payload?.gender },
          {
            fact_name: "daily_login",
            fact_value: action?.payload?.dailyLoginCount,
          },
          {
            fact_name: "is_online",
            fact_value: action?.payload?.is_online ? "Active" : "Inactive",
            type: action?.payload?.is_online ? "active" : "inactive",
          },
          {
            fact_name: "is_register",
            fact_value: action?.payload?.is_online ? "Active" : "Inactive",
            type: action?.payload?.is_online ? "active" : "inactive",
          },
          { fact_name: "age", fact_value: action?.payload?.age },
          // { fact_name: "bio", fact_value: action?.payload?.bio },
          // {
          //   fact_name: "room_chats",
          //   fact_value: !isEmpty(action?.payload?.room) ? "Active" : "Inactive",
          //   type: !isEmpty(action?.payload?.room) ? "active" : "inactive",
          // },
        ],
      };
    });
    builder.addCase(getMemberDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default memberSlice.reducer;
