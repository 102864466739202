import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import Header from "../../../components/content/MemberInfoPage/Header";
import UserInfoWrapper from "../../../components/content/MemberInfoPage/UserInfoWrapper";
import MemberInterest from "../../../components/content/MemberInfoPage/MemberInterest";
import MemberWallet from "../../../components/content/MemberInfoPage/MemberWallet";
import MemberConnections from "../../../components/content/MemberInfoPage/MemberConnections";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Error } from "../../../components";
import { getMemberDetails } from "../../../redux/services/memberDetailsServices";
import { getMemberTransactionsServices } from "../../../redux/services//memberTransactionsServices";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const MemberInfo = ({ props }) => {
  const { error, loading, data } = useSelector((state) => state?.memberDetails);
  const {
    error: transactionsError,
    loading: transactionsLoading,
    transactions,
  } = useSelector((state) => state?.memberTransactions);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [filterParam, setFilterParam] = useState("");

  useEffect(() => {
    dispatch(getMemberDetails({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getMemberTransactionsServices({ id, filterParam }));
  }, [dispatch, filterParam, id]);

  if (error) return <Error msg={error?.message} />;
  if (loading) return <></>;

  return (
    <div className={styles.member__info__page__wrapper}>
      <Header title={"userDetails"} />
      <div className={`${styles.page__row} row g-0`}>
        <div className={`${styles.page__column} col-12 col-md-8`}>
          <UserInfoWrapper
            info={data.userInfo}
            type="member"
            title={"userInfo"}
            at={"member_since"}
          />
          {data?.roomInfo?.name && (
            <UserInfoWrapper
              info={data.roomInfo}
              type="room"
              title={"roomInfo"}
              at={"created_at"}
            />
          )}
          <MemberWallet
            filterParam={filterParam}
            setFilterParam={setFilterParam}
            transactions={transactions}
            wallet={data.walletInfo}
            loading={transactionsLoading}
            error={transactionsError}
          />
        </div>
        <div className={`${styles.page__column} col-12 col-md-4`}>
          <MemberInterest data={data.interests} />
          <MemberConnections
            title={"member_connections"}
            data={data.connections}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberInfo);
