import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { UpdateMemberStatusModal } from "../../../../components";
import { toggleModal } from "../../../../redux/features/modalSlice";
import { IoSettingsOutline, IoEyeSharp } from "react-icons/io5";
import CopyTextButton from "../../../UI/CopyTextButton";
import { Link } from "react-router-dom";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const UserInfoWrapper = ({ title, at, type, info }) => {
  const [facts, setFacts] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const dispatch = useDispatch();
  const { id: modalId } = useSelector((state) => state?.modal);
  useEffect(() => {
    setFacts(info.facts);
  }, [info]);

  const { t } = useTranslation();
  return (
    <div className={styles.user__info__wrapper}>
      <p className={styles.section__head}>{t(`userinfo.${title}`)}</p>
      <div className={styles.user__info}>
        <div className={styles.user__data}>
          <div className={styles.user__avatar__wrapper}>
            <img
              src={
                info.avatar ||
                "https://tse1.mm.bing.net/th?id=OIP.tvaMwK3QuFxhTYg4PSNNVAHaHa&pid=Api"
              }
              alt={`${info.name}_avatar`}
            />
          </div>
          <div className={styles.user__details}>
            <p className={styles.user__name}>{info.name || "N/A"}</p>
            <span className={styles.user__join__date}>{` ${
              type === "member"
                ? `${t("userinfo.member_since")} ${moment(
                    info?.created_at
                  ).fromNow()}`
                : `${t("userinfo.room_created_at")} ${moment(
                    info?.created_at
                  ).fromNow()}`
            }`}</span>
          </div>
        </div>
        <div className={styles.settings__btn__wrapper}>
          {type === "member" && (
            // <button
            //   onClick={() => {
            //     dispatch(toggleModal("user__settings__modal"));
            //     setCurrentItem("");
            //   }}
            //   className={styles.settings__btn}
            // >
            //   <IoSettingsOutline />
            //   <span className="d-none d-sm-flex">{t("userinfo.settings")}</span>
            // </button>\
            <Link className={styles.settings__btn} to={"permissions"}>
              <IoSettingsOutline />
              <span className="d-none d-sm-flex">{t("userinfo.settings")}</span>
            </Link>
          )}
          {/* {type === "room" && (
            <button
              className={`${styles.settings__btn} ${styles.room__info__btn}`}
            >
              <IoEyeSharp />
              <span className="d-none d-sm-flex">{t("userinfo.see_info")}</span>
            </button>
          )} */}
        </div>
      </div>
      <div className={styles.user__facts}>
        <div className="row g-0">
          <div className="col-6 col-lg-4">
            <div className={styles.user__fact__card}>
              <p className={styles.fact__name}>
                {t(`userinfo.${facts[0]?.fact_name}`)}
              </p>
              <div className="d-flex align-items-center jsutify-content-center gap-2">
                <CopyTextButton text={facts[0]?.fact_value} />
              </div>
            </div>
          </div>
          {facts
            .filter((_, index) => index > 0)
            .filter((_) => _.fact_value !== null)
            .map((fact, index) => {
              return (
                <div className="col-6 col-lg-4" key={index}>
                  <div className={styles.user__fact__card}>
                    <p className={styles.fact__name}>
                      {t(`userinfo.${fact?.fact_name}`)}
                    </p>
                    <p
                      className={`${styles.fact__value} ${
                        fact.type === "active" && styles.active
                      }
                      ${fact.type === "inactive" && styles.inactive}
                      `}
                    >
                      {fact.type
                        ? t(`userinfo.${fact.type}`)
                        : fact?.fact_name === "country"
                        ? fact?.fact_value
                        : // ? t(`country.${fact?.fact_value}`)
                        fact?.fact_name === "gender"
                        ? t(`userinfo.${fact?.fact_value}`)
                        : fact?.fact_name === "topic"
                        ? t(`interests.${fact?.fact_value}`)
                        : fact?.fact_value || "N/A"}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {modalId === "user__settings__modal" && (
        <UpdateMemberStatusModal
          // options={options}
          title={t("modal.edit_member_balance")}
          dispatch={dispatch}
          item={currentItem}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoWrapper);
