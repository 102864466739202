import React from "react";

import styles from "./.module.scss";
import { motion } from "framer-motion";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import ENFlag from "../../../assets/en_image.png";
import ARFlag from "../../../assets/ar_flag.png";

const DropdownLang = ({ toggleLang }) => {
  const lang = Cookies.get("lang");
  const { t } = useTranslation();

  return (
    <motion.div
      className={`${styles.dropdown} position-absolute`}
      initial={{ height: 0, x: "-25%", opacity: 0.7 }}
      animate={{ height: "auto", x: "-25%", opacity: 1 }}
      exit={{ height: 0, x: "-25%", opacity: 0.7 }}
      transition={{ duration: 0.2, type: "tween" }}
    >
      <button
        onClick={() => toggleLang("en")}
        className={`d-flex dropdown-item align-items-center gap-2 ${
          lang === "en" || lang === undefined ? styles.active : ""
        }`}
      >
        <img
          className={`${styles.lang_image}`}
          src={ENFlag}
          width={32}
          height={32}
          alt="en falg"
        />
        {t("header.en_lang")}
      </button>
      <button
        onClick={() => toggleLang("ar")}
        className={`d-flex dropdown-item align-items-center gap-2 ${
          lang === "ar" ? styles.active : ""
        }`}
      >
        <img
          className={`${styles.lang_image}`}
          src={ARFlag}
          width={32}
          height={32}
          alt="ar falg"
        />
        {t("header.ar_lang")}
      </button>
    </motion.div>
  );
};

export default DropdownLang;
