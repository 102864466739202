import React from "react";
import styles from "./.module.scss";
import { NotificationItem } from "../..";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const NotificationsDropdown = () => {
  const { t } = useTranslation();

  return (
    <motion.div
      className={`${styles.dropdown} position-absolute`}
      initial={{ height: 0, x: "-25%", opacity: 0.7 }}
      animate={{ height: "auto", x: "-25%", opacity: 1 }}
      exit={{ height: 0, x: "-25%", opacity: 0.7 }}
      transition={{ duration: 0.2, type: "tween" }}
    >
      <div className={styles.dropdown_content}>
        <div
          className={`d-flex align-items-center justify-content-between mb-4 ${styles.dropdown_header}`}
        >
          <h5 className={styles.notify_title}>
            {t("header.notifications_title")}
          </h5>
          <button className={styles.mark_all_read}>
            {t("header.mark_all_read")}
          </button>
        </div>
        <div className={styles.notifications_items}>
          {Array(4)
            .fill("")
            .map((_, i) => (
              <NotificationItem key={i} />
            ))}
        </div>
      </div>
    </motion.div>
  );
};

export default NotificationsDropdown;
