import validators from "../../../util/validators";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button, DragDropFiled, Input, MainSelectBox, ModalPopup } from "../..";
import { toggleModal } from "../../../redux/features/modalSlice";
import { useApi } from "../../../hooks/useApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getGifts } from "../../../redux/services/giftsServices";
import Cookies from "js-cookie";

const AddNewGift = ({ currentData, setCurrentData, options }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [icon, setIcon] = useState(currentData?.icon);
  const [image, setImage] = useState(currentData?.image);
  const [activeCategory, setActiveCategory] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const lang = Cookies.get("lang");

  const typeOptions = [
    { label: "animated", id: "animated", value: "animated" },
    { label: "Fixed", id: "Fixed", value: "Fixed" },
  ];
  const [activeType, setActiveType] = useState(null);

  const formElements = [
    {
      type: "text",
      name: "nameEn",
      placeholder: t("Enter English Name"),
      label: t("English Name"),
      validate: validators.composeValidators(validators.required("")),
      value: currentData?.name,
    },
    {
      type: "text",
      name: "nameAr",
      placeholder: t("Enter Arabic Name"),
      label: t("Arabic Name"),
      validate: validators.composeValidators(validators.required("")),
      value: currentData?.name_ar,
    },
    {
      type: "text",
      name: "coin",
      placeholder: t("Enter Coins"),
      label: t("Coins"),
      validate: validators.composeValidators(validators.required("")),
      value: currentData?.coin,
    },
  ];

  // ==== get all categories ====
  const {
    loading: categoryLoading,
    onRequest: onGetCategories,
    data: allCategories,
  } = useApi("gift-category/list", "get");

  useEffect(() => {
    onGetCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ====== onSubmit ====== //
  const onSubmit = async (e) => {
    const formData = new FormData();
    formData.append("nameAr", e?.nameAr);
    formData.append("nameEn", e?.nameEn);
    formData.append("coin", e?.coin);
    formData.append("categoryId", e.categoryId);
    formData.append("image", e?.image);
    formData.append("icon", e?.icon);
    formData.append("type", e?.type);

    const requestOptions = {
      method: currentData?.id ? "PATCH" : "POST",
      body: formData,
      redirect: "follow",
    };

    try {
      setIsSubmitting(true);
      const res = await fetch(
        currentData?.id
          ? `https://tkstg.t-wasel.com/gifts/update/${currentData?.id}`
          : "https://tkstg.t-wasel.com/gifts/create",
        requestOptions
      );
      const res2 = await res?.json();
      if (
        res2?.message === "Successfully" ||
        res2.statusCode === 200 ||
        res2.statusCode === 201
      ) {
        toast.success(
          currentData?.id
            ? t("Saved update successfully")
            : t("Saved successfully")
        );
        dispatch(getGifts(options));
        dispatch(toggleModal(""));
      }
    } catch (err) {
      setIsSubmitting(false);
    }
    setIsSubmitting(false);
  };

  // default category
  useEffect(() => {
    let defaultValue;
    if (currentData?.category_id && allCategories?.data?.giftCategoryList) {
      defaultValue = allCategories?.data?.giftCategoryList?.find(
        (item) => item?.id === currentData?.category_id
      );
    }
    setActiveCategory({
      label: lang === "ar" ? defaultValue?.name_ar : defaultValue?.name,
      id: defaultValue?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCategories?.data?.giftCategoryList]);

  useEffect(() => {
    let defaultValue;
    if (currentData?.type && typeOptions) {
      defaultValue = typeOptions?.find(
        (item) => item?.value === currentData?.type
      );
    }
    setActiveType({
      label: lang === "ar" ? defaultValue?.label : defaultValue?.label,
      id: defaultValue?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalPopup title={currentData?.id ? t("update gift") : t("Add New gift")}>
      <Form
        currentDataialValues={currentData}
        onSubmit={(e) =>
          onSubmit({
            ...e,
            icon,
            image,
            categoryId: String(activeCategory?.id),
            type: String(activeType?.id),
          })
        }
      >
        {({ handleSubmit, _, valid }) => (
          <form className={`w-100`} onSubmit={handleSubmit}>
            <div className={` d-flex flex-column gap-5 mb-5`}>
              {formElements.map((item, i) => (
                <Field
                  key={i}
                  component={Input}
                  placeholder={item.placeholder}
                  icon={item.icon}
                  type={item.type}
                  name={item.name}
                  id={item.type}
                  label={item.label}
                  validate={item.validate}
                  initialValue={item.value}
                />
              ))}
              <DragDropFiled
                setImage={setIcon}
                image={icon || currentData?.icon}
                label={t("Icon")}
              />
              <DragDropFiled
                setImage={setImage}
                image={image || currentData?.image}
                label={t("Image")}
              />
              <div className="d-flex align-items-center gap-4">
                <span style={{ fontSize: "14px", color: "#707070" }}>
                  {t("Select Gift's Category")}
                </span>
                <MainSelectBox
                  loading={categoryLoading}
                  options={allCategories?.data?.giftCategoryList?.map(
                    (category) => {
                      return {
                        label:
                          lang === "ar" ? category?.name_ar : category?.name,
                        id: category?.id,
                      };
                    }
                  )}
                  active={activeCategory}
                  onActive={setActiveCategory}
                  id="gift-categories"
                />
              </div>
              <div className="d-flex align-items-center gap-4">
                <span style={{ fontSize: "14px", color: "#707070" }}>
                  {t("Select Gift's Type")}
                </span>
                <MainSelectBox
                  loading={categoryLoading}
                  // options={typeOptions}
                  options={typeOptions?.map((type) => {
                    return {
                      label: lang === "ar" ? type?.label : type?.label,
                      id: type?.id,
                    };
                  })}
                  active={activeType}
                  onActive={setActiveType}
                  id="gift-types"
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content between gap-4">
              <Button
                type="submit"
                disabled={
                  !valid || !activeCategory || !activeType || !image || !icon
                }
                submitting={isSubmitting}
                bgColor="#0ACF83"
              >
                {currentData ? t("details.save_edits") : t("add")}
              </Button>
              <Button
                onClick={() => {
                  setCurrentData(null);
                  dispatch(toggleModal(""));
                }}
                type="button"
                outline
              >
                {t("details.cancel")}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </ModalPopup>
  );
};

export default AddNewGift;
