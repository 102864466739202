import { configureStore } from "@reduxjs/toolkit";
import memberSlice from "./features/memberSlice";
import modalSlice from "./features/modalSlice";
import giftsSlice from "./features/giftsSlice";
import postsSlice from "./features/postsSlice";
import adsSlice from "./features/adsSlice";
import levelsSlice from "./features/levelsSlice";
import badgesSlice from "./features/badgesSlice";
import categoriesSlice from "./features/categoriesSlice";
import roomsSlice from "./features/roomsSlice";
import memberDetailsSlice from "./features/memberDetailsSlice";
import memberTransactionsSlice from "./features/memberTransactionsSlice";
import roomsThemesSlice from "./features/roomsThemesSlice";
import membersAvatarsSlice from "./features/membersAvatarsSlice";
import roomDetailsSlice from "./features/roomDetailsSlice";
import reportsSlice from "./features/reportsSlice";
import designStoreItemsSlice from "./features/designStoreItemsSlice";
import giftsTransactionsSlice from "./features/giftsTransactionsSlice";
import settingsSlice from "./features/settingsSlice";
import permissionsSlice from "./features/permissionsSlice";
import adminSlice from "./features/adminSlice";

export const store = configureStore({
  reducer: {
    members: memberSlice,
    modal: modalSlice,
    gifts: giftsSlice,
    posts: postsSlice,
    ads: adsSlice,
    levels: levelsSlice,
    badges: badgesSlice,
    categories: categoriesSlice,
    rooms: roomsSlice,
    memberDetails: memberDetailsSlice,
    memberTransactions: memberTransactionsSlice,
    roomsThemes: roomsThemesSlice,
    membersAvatars: membersAvatarsSlice,
    roomDetails: roomDetailsSlice,
    reports: reportsSlice,
    designStore: designStoreItemsSlice,
    giftsTransactions: giftsTransactionsSlice,
    settings: settingsSlice,
    permissions: permissionsSlice,
    admin: adminSlice,
  },
});
