import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { toast } from "react-toastify";

export const getGiftsTransactionsServices = createAsyncThunk(
  "gifts/giftsTransactionsServices",
  async (
    { pagination, itemsPerPage, searchKeyWord, sortType, sortOption },
    thunkAPI
  ) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await api.get(`gifts/gift-transactions`, {
        params: {
          page: pagination,
          perPage: itemsPerPage,
          keyword: searchKeyWord,
          sortOption,
          sortType,
        },
      });
      return res.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error !");
      return rejectWithValue(error?.response?.data);
    }
  }
);
