import React, { useState, useEffect } from "react";
import validators from "../../../util/validators";
import { Field, Form } from "react-final-form";
import { Input, Button, DragDropFiled, SwitchButton } from "../..";
import { useTranslation } from "react-i18next";
import styles from "./.module.scss";
import { useApi } from "../../../hooks/useApi";
import { toast } from "react-toastify";
import { toggleModal } from "../../../redux/features/modalSlice";

const AddRoomTheme = ({ dispatch, options, setOptions, getRoomsThemes }) => {
  const { t } = useTranslation();

  const {
    loading,
    onRequest,
    data: reqData,
  } = useApi("/admin-background/create ", "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  // const option = {
  //   id: "main_theme",
  //   status: true,
  // };

  const [image, setImage] = useState(null);
  const [data, setData] = useState({
    image,
  });
  useEffect(() => {
    setData({ ...data, image });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  useEffect(() => {
    if (reqData) {
      setOptions({
        ...options,
        pagination: 1,
      });
      dispatch(toggleModal(""));
      dispatch(getRoomsThemes(options));
      toast.success(reqData?.message || t("Saved update successfully"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData]);

  const onSubmit = async (values) => {
    const formDate = new FormData();
    formDate.append("file", data.image);
    await onRequest(formDate);
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting, valid }) => (
        <form className={`w-100`} onSubmit={handleSubmit}>
          <div
            className={`${styles.inputs_container} d-flex flex-column gap-5 mb-5`}
          >
            <Field
              component={() => (
                <DragDropFiled image={image} setImage={setImage} />
              )}
              name={"theme_img"}
            />
            {/* {formElements.map((item, i) => (
              <Field
                key={i}
                component={Input}
                placeholder={item.placeholder}
                icon={item.icon}
                type={item.type}
                name={item.name}
                id={item.type}
                label={item.label}
                validate={item.validate}
              />
            ))} */}
          </div>
          {/* <div
            className={`${styles.switch} d-flex align-items-center justify-content-between gap-3 w-100 mb-4`}
          >
            <p>{t("details.mark_main_theme_rooms")}</p>
            <SwitchButton
              isOn={data?.main_room}
              handleToggle={() =>
                setData({
                  ...data,
                  main_room: data?.main_room ? false : true,
                })
              }
            />
          </div> */}
          <div
            className={`${styles.form_footer} d-flex align-items-center justify-content-between w-100`}
          >
            <Button
              type="submit"
              disabled={!valid || !image}
              submitting={submitting}
            >
              {t("details.add_theme")}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

export default AddRoomTheme;
