import React from "react";
import styles from "./.module.scss";

const EmptyTable = () => {
  return (
    <div className={styles.empty_table}>No results matches your filters</div>
  );
};

export default EmptyTable;
