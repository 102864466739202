import React, { useState, useEffect } from "react";
import styles from "../.module.scss";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import InputField from "../../../../pages/Settings/InputField";
import { useTranslation } from "react-i18next";

const content = [
  {
    param: "agora_app_id",
    key: "Agora APP ID",
  },
  {
    param: "agora_secret_key",
    key: "Agora Secret Key",
  },
];

const ChatSettings = () => {
  const { settings } = useSelector((state) => state.settings);
  const [CONTENT, setCONTENT] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    let arr = [];
    !isEmpty(settings) &&
      content.forEach((element, i) => {
        arr.push({
          param: element.param,
          key: element.key,
          value: settings[element.param] || "-----",
        });
      });

    setCONTENT(arr);
  }, [settings]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles.container}
    >
      <div className={styles.content_container}>
        {CONTENT?.length
          ? CONTENT.map((item, i) => (
              <div key={i} className="d-flex flex-column">
                <p className={styles.main_text}>{t(`tabs.${item?.param}`)}</p>
                <InputField property={item?.param} value={item?.value} />
              </div>
            ))
          : null}
      </div>
    </motion.div>
  );
};

export default ChatSettings;
