import React from "react";
import { CircleImage } from "../..";
import styles from "./.module.scss";

const NotificationItem = () => {
  return (
    <div className={`${styles.notify_item}`}>
      <div className={`${styles.notify_header} d-flex justify-content-between`}>
        <div className={`d-flex align-items-center ${styles.user_info}`}>
          <CircleImage img="http://pluspng.com/img-png/user-png-icon-young-user-icon-2400.png" />
          {/* <img src="assets/user.png" /> */}
          <h4 className={styles.user_name}>new posts added by mohamed ahmed</h4>
        </div>
        <span className={styles.notify_date}>7 hours ago</span>
      </div>
      <p className={styles.notify_text}>
        Scientists anticipated getting a cupful but are still unsure how much
        was grabbed from the carbon-rich asteroid named Bennu, almost 60 million
        miles (97 million kilometers) away. That’s because the main sample
        chamber has yet to be opened, officials said during an event at Johnson
        Space Center in Houston.
      </p>
    </div>
  );
};

export default NotificationItem;
