import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";
import MemberConnectionCard from "../MemberConnection";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

const connectBtns = [
  {
    id: 1,
    content: "Friends",
    param: "friends",
  },
  {
    id: 2,
    content: "Followers",
    param: "followers",
  },
  {
    id: 3,
    content: "Following",
    param: "following",
  },
];

export const MemberConnections = ({ data, btns, title }) => {
  const [btnsData, setBtnsData] = useState(btns || connectBtns);
  const [connections, setConnections] = useState([]);
  const [filterParam, setfilterParam] = useState(btnsData[0]?.param);

  useEffect(() => {
    setBtnsData(btns || connectBtns);
  }, [btns]);

  useEffect(() => {
    setConnections(data[filterParam]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParam]);

  const { t } = useTranslation();

  return (
    <div className={styles.member__connections__wrapper}>
      <p className={styles.section__head}>{t(`userinfo.${title}`)}</p>
      <ul className={styles.member__connections__btns__wrapper}>
        {btnsData.map((btn, index) => (
          <li key={index} className={styles.filter__btn__item}>
            <button
              onClick={() => setfilterParam(btn.param)}
              className={`${styles.filter__btn} ${
                btn.param === filterParam ? styles.active : null
              }`}
            >
              {t(`userinfo.${btn.param}`)}{" "}
              {btn.param === filterParam && connections?.length
                ? `(${connections?.length})`
                : null}
            </button>
          </li>
        ))}
      </ul>
      <div className={styles.connections__wrapper}>
        {connections?.length
          ? connections?.map((connect, index) => (
              <MemberConnectionCard key={index} connect={connect} />
            ))
          : t(`userinfo.no_${filterParam}`)}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberConnections);
