import React, { useEffect, useState } from "react";
import { GiftsColumns } from "../../util/data";
import { useTranslation } from "react-i18next";
import { getGiftsTransactionsServices } from "../../redux/services/giftsTransactionsServices";
import { useDispatch, useSelector } from "react-redux";
import { Error, MainPageDesign } from "../../components";

const GiftsTransHistoryPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    totalPages,
    error,
    transactions,
    loading: tableLoading,
  } = useSelector((state) => state?.giftsTransactions);

  const [options, setOptions] = useState({
    isPaginated: totalPages > 1,
    pagination: 1,
    itemsPerPage: 20,
    searchKeyWord: null,
    totalPages,
    sortType: null,
    sortOption: null,
  });

  useEffect(() => {
    setOptions({
      ...options,
      totalPages: totalPages,
      isPaginated: totalPages > 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, transactions]);

  const table = {
    columns: GiftsColumns,
    data: transactions,
  };
  useEffect(() => {
    dispatch(getGiftsTransactionsServices(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.pagination,
    options.searchKeyWord,
    options.sortOption,
    options?.sortType,
  ]);
  if (error) return <Error msg={error?.message} />;

  return (
    <MainPageDesign
      searchBar
      title={t("details.all_gift_transactions")}
      no={`${transactions?.length || "0"} ${t("details.transactions")}`}
      // //////////////////////////////////////////////////////////////////////
      // //////////////////////////////////////////////////////////////////////
      // //////////////////////////////////////////////////////////////////////
      data={table?.data || []}
      columns={table?.columns || []}
      tableLoading={tableLoading}
      // //////////////////////////////////////////////////////////////////////
      // //////////////////////////////////////////////////////////////////////
      // //////////////////////////////////////////////////////////////////////
      options={options || {}}
      setOptions={setOptions}
    />
  );
};

export default GiftsTransHistoryPage;
