import React, { useState, useEffect } from "react";
import {
  Error,
  DeleteCell,
  DeleteModal,
  EditCell,
  MainPageDesign,
} from "../../components";
import { IoEyeOutline } from "react-icons/io5";
import { generalColumns } from "../../util/data";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getReports } from "../../redux/services/reportsServices";
import { toggleModal } from "../../redux/features/modalSlice";
import { useApi } from "../../hooks/useApi";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import EditReportModal from "../../components/Modals/EditReportModal";
import ViewReportPopUp from "../../components/Modals/ViewReportPopUp";

const ReportsPage = () => {
  const { t } = useTranslation();
  const [currentItem, setCurrentItem] = useState(null);
  const { id } = useSelector((state) => state?.modal);
  const dispatch = useDispatch();
  const { type: reportType } = useParams();

  const {
    totalPages,
    error,
    reports,
    loading: tableLoading,
  } = useSelector((state) => state?.reports);

  const [options, setOptions] = useState({
    isPaginated: totalPages > 1,
    pagination: 1,
    itemsPerPage: 20,
    searchKeyWord: null,
    totalPages,
    sortType: null,
    sortOption: null,
    reportType: reportType.slice(0, -1),
  });

  useEffect(() => {
    setOptions({
      isPaginated: totalPages > 1,
      pagination: 1,
      itemsPerPage: 20,
      searchKeyWord: null,
      totalPages,
      sortType: null,
      sortOption: null,
      reportType: reportType.slice(0, -1),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType]);

  useEffect(() => {
    setOptions({
      ...options,
      totalPages: totalPages,
      isPaginated: totalPages > 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, reports]);

  useEffect(() => {
    dispatch(getReports(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.pagination,
    options.searchKeyWord,
    options.sortOption,
    options?.sortType,
    options?.reportType,
  ]);

  useEffect(() => {
    if (!id) {
      setCurrentItem(null);
    }
  }, [id]);

  const table = {
    columns: [
      ...generalColumns,
      {
        Header: "table.headers.action",
        accessor: "",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <EditCell
              onClick={() => {
                dispatch(toggleModal("edit__report__modal"));
                setCurrentItem(cellInfo?.row?.original);
              }}
            />
            <button
              onClick={() => {
                setCurrentItem(cellInfo?.row?.original);
                dispatch(toggleModal("view__details"));
              }}
              style={{ color: "#0d6efd", fontSize: "24px" }}
              className="d-flex align-items-center justiy-content-center"
            >
              <IoEyeOutline />
            </button>
            {/* <DeleteCell
              onClick={() => {
                dispatch(toggleModal("delete-modal"));
                setCurrentItem(cellInfo?.row?.original);
              }}
            /> */}
          </div>
        ),
      },
    ],
    data: reports,
  };

  // onDelete
  const {
    loading: isSubmitting,
    onRequest,
    data,
  } = useApi(`levels/delete/${currentItem?.id}`, "delete");

  useEffect(() => {
    if (data?.error) {
      toast.error(data?.error || "something wend wrong!");
      return;
    }
    if (data) {
      toast.success(data?.message || t("deleted successfully"));
      dispatch(toggleModal(""));
      dispatch(getReports(options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error msg={error?.message} />;
  return (
    <>
      <MainPageDesign
        title={t(`details.${reportType}_reports`)}
        no={`${reports?.length || "0"} ${t("details.reports")}`}
        searchBar
        data={table?.data || []}
        columns={table?.columns || []}
        tableLoading={tableLoading}
        options={options}
        setOptions={setOptions}
      />
      {id === "delete-modal" && (
        <DeleteModal
          type={t("details.report")}
          onClose={() => dispatch(toggleModal(""))}
          onDelete={() => onRequest()}
          isSubmitting={isSubmitting}
          action={onRequest}
          afterAction={() => {
            setOptions({ ...options, pagination: 1 });
            dispatch(getReports(options));
          }}
        />
      )}
      {id === `edit__report__modal` && (
        <EditReportModal currentItem={currentItem} options={options} />
      )}
      {id === "view__details" && <ViewReportPopUp currentItem={currentItem} />}
    </>
  );
};

export default ReportsPage;
