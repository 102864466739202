import validators from "../../../util/validators";
import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button, Input, ModalPopup } from "../..";
import { toggleModal } from "../../../redux/features/modalSlice";
import { useApi } from "../../../hooks/useApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getGiftsCategories } from "../../../redux/services/giftsServices";

const AddNewCategoryGift = ({
  options,
  setOptions,
  currentData,
  setCurrentData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formElements = [
    {
      type: "text",
      icon: "gift",
      name: "nameEn",
      placeholder: t("Enter English Name"),
      label: t("English Name"),
      validate: validators.composeValidators(
        validators.required("This field is Required")
      ),
      value: currentData?.name,
    },
    {
      type: "text",
      icon: "gift",
      name: "nameAr",
      placeholder: t("Enter Arabic Name"),
      label: t("Arabic Name"),
      validate: validators.composeValidators(
        validators.required("This field is Required")
      ),
      value: currentData?.name_ar,
    },
  ];

  const { loading, onRequest, data } = useApi(
    currentData?.id
      ? `/gift-category/update/${currentData?.id}`
      : "/gift-category/create",
    currentData?.id ? "patch" : "post"
  );

  useEffect(() => {
    if (data) {
      toast.success(
        currentData?.id
          ? t("Saved update successfully")
          : t("Saved successfully")
      );
      dispatch(toggleModal(""));
      dispatch(getGiftsCategories(options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ModalPopup
      title={
        currentData?.id
          ? t("update gift's Category")
          : t("Add New gift's Category")
      }
    >
      <Form
        initialValues={currentData}
        onSubmit={async (e) => {
          onRequest(e);
        }}
      >
        {({ handleSubmit, _, valid }) => (
          <form className={`w-100`} onSubmit={handleSubmit}>
            <div className={` d-flex flex-column gap-5 mb-5`}>
              {formElements.map((item, i) => (
                <Field
                  key={i}
                  component={Input}
                  placeholder={item.placeholder}
                  icon={item.icon}
                  type={item.type}
                  name={item.name}
                  id={item.type}
                  label={item.label}
                  validate={item.validate}
                  initialValue={item?.value}
                />
              ))}
            </div>
            <div className="d-flex align-items-center justify-content between gap-4">
              <Button
                type="submit"
                disabled={!valid}
                submitting={loading}
                bgColor="#0ACF83"
              >
                {/* {t("details.save_edits")} */}
                {currentData?.id ? t("details.save_edits") : t("details.add")}
              </Button>
              <Button
                onClick={() => {
                  setCurrentData(null);
                  dispatch(toggleModal(""));
                }}
                type="button"
                outline
              >
                {t("details.cancel")}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </ModalPopup>
  );
};

export default AddNewCategoryGift;
