import { createSlice } from "@reduxjs/toolkit";
import { getRoomDetails } from "../services/roomDetailsServices";
const initialState = {
  loading: true,
  error: null,
  data: {
    roomInfo: {},
    connections: {
      blockUser: [],
      live_users: [],
      roomUsers: [],
    },
  },
};

const roomDetailsSlicejs = createSlice({
  name: "roomDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getRoomDetails.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getRoomDetails.fulfilled, (state, action) => {
      console.log(action.payload);
      if (action.payload.rooms?.length) {
        const room = action.payload.rooms.filter(
          (item) => item.id == action.payload.ID
        )[0];
        state.loading = false;
        state.error = null;

        state.data.roomInfo = {
          name: room?.name,
          avatar: room?.uploaded_avatar,
          created_at: room?.created_at,
          facts: [
            { fact_name: "room_id", fact_value: room?.id },
            {
              fact_name: "topic",
              fact_value: room?.topic?.name,
            },
            {
              fact_name: "room_status",
              fact_value: room?.status !== "not-Active" ? "Active" : "Inactive",
              type: room?.status !== "not-Active" ? "active" : "inactive",
            },
            {
              fact_name: "room_chat",
              fact_value:
                room?.room_chat !== "not-Active" ? "Active" : "Inactive",
              type: room?.room_chat !== "not-Active" ? "active" : "inactive",
            },
            {
              fact_name: "charisma_level",
              fact_value: room?.charisma_level ? "Active" : "Inactive",
              type: room?.charisma_level ? "active" : "inactive",
            },
            {
              fact_name: "special_room",
              fact_value: room?.special ? "Active" : "Inactive",
              type: room?.special ? "active" : "inactive",
            },
          ],
        };
        state.data.connections = {
          blockUser: room?.blockUser,
          live_users: room?.live_users,
          roomUsers: room?.roomUsers,
        };
      } else {
        state.loading = false;
        state.error = action.payload || { message: "Error!" };
      }
    });
    builder.addCase(getRoomDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
    });
  },
});

export default roomDetailsSlicejs.reducer;
