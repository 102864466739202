import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ModalPopup } from "../..";
import { toggleModal } from "../../../redux/features/modalSlice";
import { useApi } from "../../../hooks/useApi";
import { toast } from "react-toastify";
import Content from "./Content";

// if (adminPermissions.length) {
//   adminPermissions.forEach((element) => {
//     permissionsMap.forEach((element1) => {
//       if (element?.module_name === element1?.moduleName) {
//         element1?.permissions.forEach((el) => {
//           if (element.permission === el.key) {
//           }
//         });
//       }
//     });
//   });
// }

const ViewPermissionsDetails = ({
  userID,
  adminPermissions,
  permissionsMap,
  item,
}) => {
  const { t } = useTranslation();
  // const { loading, onRequest, data } = useApi(
  //   "/members/update-user-balance",
  //   "post"
  // );

  // useEffect(() => {
  //   if (data) {
  //     toast.success(data?.message || t("Saved update successfully"));
  //     dispatch(toggleModal(""));
  //     dispatch(getAllMembers(options));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  return (
    <ModalPopup title={t("details.permissions")}>
      <Content
        userID={userID}
        adminPermissions={adminPermissions}
        permissionsMap={permissionsMap}
        item={item}
      />
    </ModalPopup>
  );
};

export default ViewPermissionsDetails;
