import React from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";
import approx from "approximate-number";
import moment from "moment";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const UserBalanceWrapper = ({ wallet }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.overlay}>
        <img src="/assets/icons/circle.svg" alt="bg" />
      </div>
      <div className={styles.user__balance__wrapper}>
        <p className={styles.wrapper__title}>{t("userinfo.member_balance")}</p>
        <div className={styles.balance__wrapper}>
          <span className={styles.icon}>
            <img
              width={33}
              src="/assets/icons/diamond.svg"
              alt="diamond__icon"
            />
          </span>
          {
            <span className={styles?.balance}>
              {approx(wallet?.balance, {
                capital: true,
              }) || "0"}
            </span>
          }
        </div>
        {wallet?.last_recharge_date && (
          <p className={styles.last__update__date}>
            {moment(wallet?.last_recharge_date).format("LL")}
          </p>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBalanceWrapper);
