import { createSlice } from "@reduxjs/toolkit";
import { getDesignStoreItems } from "../services/designStoreItemsServices";

const initialState = {
  items: null,
  loading: true,
  error: null,
  TP: null,
  totalPages: null,
};

const designStoreItemsSlice = createSlice({
  name: "items",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDesignStoreItems.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.themes = null;
    });
    builder.addCase(getDesignStoreItems.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.items = action.payload.itemList;
      state.TP = action.payload.totalPages;
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(getDesignStoreItems.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
      state.themes = null;
    });
  },
});
export const { onActiveFilter } = designStoreItemsSlice.actions;
export default designStoreItemsSlice.reducer;
