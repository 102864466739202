import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { toast } from "react-toastify";
import { capitalize } from "lodash";

export const getReports = createAsyncThunk(
  "reports/getReports",
  async (
    {
      pagination,
      itemsPerPage,
      searchKeyWord,
      sortOption,
      sortType,
      reportType,
    },
    thunkAPI
  ) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await api.get(`admin-reports/list`, {
        params: {
          page: pagination,
          perPage: itemsPerPage,
          keyword: searchKeyWord,
          sortOption,
          sortType,
          report_type: capitalize(reportType),
        },
      });
      return res?.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Error !");
      return rejectWithValue(error?.response?.data);
    }
  }
);
