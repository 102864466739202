import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import LoginPage from "../../pages/Login";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export const AuthGuard = ({ children, redirect }) => {
  const { admin } = useSelector((state) => state?.admin);
  const location = useLocation();

  useEffect(() => {
    if (admin?.access_token && admin?.user) {
      localStorage.setItem("user", JSON.stringify(admin.user));
      localStorage.setItem("access_token", admin.access_token);
      // setTimeout(() => {
      // window.location.replace(redirect || "/");
      // }, 100);
    }
  }, [admin]);

  if (isEmpty(admin?.user) || !admin?.access_token) {
    return <LoginPage redirect={redirect || location.pathname} />;
  } else {
    return children;
  }
};

export default AuthGuard;
