import React from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";
import UserBalanceWrapper from "./UserBalanceWrapper";
import FilterBtn from "./FilterBtn";
import TransactionCard from "./TransactionCard";
import LoadSpinner from "../../../UI/LoadSpinner";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

const btnsData = [
  {
    id: 1,
    content: "all",
    active: true,
    param: "",
  },
  {
    id: 2,
    content: "gifts_log",
    active: false,
    param: "gift",
  },
  {
    id: 3,
    content: "charging_log",
    active: false,
    param: "retcharge",
  },
  {
    id: 4,
    content: "store_log",
    active: false,
    param: "storeItem",
  },
  {
    id: 5,
    content: "daily_log",
    active: false,
    param: "dailyLogin",
  },
];

export const MemberWallet = ({
  wallet,
  transactions,
  filterParam,
  setFilterParam,
  loading,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.member__wallet__wrapper}>
      <p className={styles.section__head}>{t(`userinfo.wallet_details`)}</p>
      <UserBalanceWrapper wallet={wallet} />
      <div className={styles.member__transactions__wrapper}>
        <p className={styles.section__head}>{t(`userinfo.transactions`)}</p>
        <div className={styles.transactions__filter__btns}>
          {btnsData.map((btn, index) => (
            <FilterBtn
              key={index}
              param={btn.param}
              content={btn.content}
              onClick={setFilterParam}
              active={btn.param === filterParam}
            />
          ))}
        </div>
      </div>
      <div className={styles.transactions__sets__wrapper}>
        {!loading ? (
          transactions.length ? (
            transactions?.map((set, index) => (
              <div key={index} className={styles.transactions__set}>
                <p className={styles.set_date}>{set.date}</p>
                <div className={styles.transactions__wrapper}>
                  {set?.transactions?.map((transaction, index) => {
                    return (
                      <TransactionCard key={index} transaction={transaction} />
                    );
                  })}
                </div>
              </div>
            ))
          ) : (
            <p>{t("userinfo.no_transactions")}</p>
          )
        ) : (
          <LoadSpinner />
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberWallet);
