import { createSlice } from "@reduxjs/toolkit";
import { getAds } from "../services/adsServices";

const initialState = {
  ads: null,
  loading: true,
  error: null,
  totalPages: null,
  TP: null,
};

const adsSlice = createSlice({
  name: "ads",
  initialState,
  extraReducers: (builder) => {
    // ===== login ======= //
    builder.addCase(getAds.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.ads = null;
    });
    builder.addCase(getAds.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.ads = action.payload.ads;
      state.totalPages = action.payload.totalPages;
      state.TP = action.payload.totalPages;
    });
    builder.addCase(getAds.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
      state.ads = null;
    });
  },
});

export default adsSlice.reducer;
