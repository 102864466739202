import { createSlice } from "@reduxjs/toolkit";
import { roomThemesServices } from "../services/roomThemesServices";

const initialState = {
  themes: null,
  loading: true,
  error: null,
  totalBackgrounds: null,
  totalPages: null,
};

const roomsThemesSlice = createSlice({
  name: "roomsThemes",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(roomThemesServices.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.themes = null;
    });
    builder.addCase(roomThemesServices.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.themes = action.payload.data;
      state.totalBackgrounds = action.payload.totalBackgrounds;
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(roomThemesServices.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
      state.themes = null;
    });
  },
});
export const { onActiveFilter } = roomsThemesSlice.actions;
export default roomsThemesSlice.reducer;
