import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { toast } from "react-toastify";

export const getGiftsCategories = createAsyncThunk(
  "gifts/getGiftsCategories",
  async (
    { pagination, itemsPerPage, searchKeyWord, sortOption, sortType },
    thunkAPI
  ) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await api.get(`gift-category/list`, {
        params: {
          page: pagination,
          perPage: itemsPerPage,
          keyword: searchKeyWord,
          sortType: sortType,
          sortOption: sortOption,
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Error !");
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getGifts = createAsyncThunk(
  "gifts/getGifts",
  async (
    { pagination, itemsPerPage, searchKeyWord, sortOption, sortType },
    thunkAPI
  ) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await api.get(`gifts/list`, {
        params: {
          page: pagination,
          perPage: itemsPerPage,
          keyword: searchKeyWord,
          sortType: sortType,
          sortOption: sortOption,
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Error !");
      return rejectWithValue(error?.response?.data);
    }
  }
);
