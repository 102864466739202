import React, { useEffect, useState } from "react";
import { NotificationIcon } from "../../../icons";
import { DropdownLang, NotificationsDropdown } from "../..";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { AnimatePresence, motion } from "framer-motion";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdOutlineClose } from "react-icons/md";
import { Link } from "react-router-dom";
import Logo from "../../../assets/side_logo.png";
import ARFlag from "../../../assets/ar_flag.png";
import ENFlag from "../../../assets/en_image.png";
import styles from "./header.module.scss";
import { useSelector } from "react-redux";
import { IoLogOutOutline } from "react-icons/io5";

const Header = ({ setOpenMobileMenu, openMobileMenu }) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [showLangMenu, setShowLangMenu] = useState(false);
  const { i18n } = useTranslation();
  const lang = Cookies.get("lang");
  const { t } = useTranslation();

  const { admin, loading } = useSelector((state) => state?.admin);

  const toggleLang = (currentLang) => {
    i18n.changeLanguage(currentLang);
    Cookies.set("lang", currentLang, { expires: 20 });
    document.dir = currentLang === "ar" ? "rtl" : "ltr";
    document.getElementsByTagName("html")[0].setAttribute("lang", currentLang);
  };

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
      document.dir = lang === "ar" ? "rtl" : "ltr";
      document.getElementsByTagName("html")[0].setAttribute("lang", lang);
    }
  }, [lang]);

  const closeDropMenu = (e) => {
    if (e?.target?.id !== "notification-button") {
      setShowNotifications(false);
    } else {
      setShowNotifications((prev) => !prev);
    }
    if (e?.target?.id !== "lang-button") {
      setShowLangMenu(false);
    } else {
      setShowLangMenu((prev) => !prev);
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", closeDropMenu);
    return () => {
      document.body.removeEventListener("click", closeDropMenu);
    };
  }, []);

  return (
    <nav
      className={`${styles.nav} w-100 d-flex align-items-center justify-content-between`}
    >
      <div
        className={`${styles.logo} d-flex align-items-center position-relative`}
      >
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
        <button
          className={`${styles.mobile_menu_btn} ${
            openMobileMenu ? styles.closed : ""
          }`}
          onClick={() => setOpenMobileMenu((prev) => !prev)}
        >
          {openMobileMenu ? <MdOutlineClose /> : <RxHamburgerMenu />}
        </button>
      </div>
      <div
        className={`${styles.header_details} d-flex align-items-center jsutify-content-end`}
      >
        <motion.div className="dropdown">
          {/* <button
            className={`${styles.notify_btn} position-relative`}
            type="button"
            id="notification-button"
          >
            <div style={{ pointerEvents: "none" }}>
              <span
                className={`${styles.notify_icon} position-absolute`}
              ></span>
              <NotificationIcon />
            </div>
          </button> */}
          <AnimatePresence>
            {showNotifications && <NotificationsDropdown />}
          </AnimatePresence>
        </motion.div>

        <motion.div className="dropdown">
          <button
            id="lang-button"
            className={`${styles.lang_btn} d-flex align-items-center position-relative`}
            type="button"
          >
            <img
              className={`${styles.lang_image}`}
              src={lang === "ar" ? ARFlag : ENFlag}
              width={32}
              height={32}
              alt="en flag"
            />
            {lang === "ar" ? "ع" : "EN"}
          </button>
          <AnimatePresence>
            {showLangMenu && <DropdownLang toggleLang={toggleLang} />}
          </AnimatePresence>
        </motion.div>

        <button
          className={`${styles.user_btn} d-flex align-items-center gap-3`}
          to="/user"
        >
          <img
            src={
              admin?.user?.profile_photo ||
              "https://tse1.mm.bing.net/th?id=OIP.tvaMwK3QuFxhTYg4PSNNVAHaHa&pid=Api"
            }
            alt="User Image"
            className={`${styles.user_image}`}
            aria-hidden
          />

          <div style={{ display: "flex", columnGap: "1rem" }}>
            <div className="d-flex justify-content-center flex-column">
              <h4 className={styles.user_name}>{admin?.user?.full_name}</h4>
              <span className={styles.user_role}>{t("details.admin")}</span>
            </div>
            <button
              onClick={() => {
                localStorage.removeItem("user");
                localStorage.removeItem("access_token");
                window.location.replace("/");
              }}
            >
              <IoLogOutOutline style={{ fontSize: "1.5rem" }} />
            </button>
          </div>
        </button>
      </div>
    </nav>
  );
};

export default Header;
