import { createSlice } from "@reduxjs/toolkit";
import { getAllRoomUsers } from "../services/roomsServices";

const initialState = {
  rooms: null,
  loading: true,
  error: null,
  TP: null,
  totalPages: null,
};

const roomsSlice = createSlice({
  name: "rooms",
  initialState,
  reducers: {
    onActiveFilter(state, action) {
      if (action?.payload === "active") {
        state.rooms = state?.rooms?.filter((room) => room?.rooms?.length);

        state.active = true;
      } else {
        state.rooms = state?.rooms?.filter((room) => !room?.rooms?.length);
        state.active = false;
      }
    },
  },

  extraReducers: (builder) => {
    // ===== getAllRooms ======= //
    builder.addCase(getAllRoomUsers.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.rooms = null;
    });
    builder.addCase(getAllRoomUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.rooms = action.payload.rooms;
      state.totalPages = action?.payload.totalPages;
      state.TP = action.payload.totalPages;
    });
    builder.addCase(getAllRoomUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
      state.rooms = null;
      state.TP = null;
      state.totalPages = null;
    });
  },
});
export const { onActiveFilter } = roomsSlice.actions;
export default roomsSlice.reducer;
