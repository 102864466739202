import React, { useState } from "react";
import styles from "./.module.scss";
import { useApi } from "../../../hooks/useApi";

export default function InputField({ value, property }) {
  const [focused, setFocused] = useState(false);
  const [initialValue, setInitialValue] = useState(value);
  const [newValue, setNewValue] = useState(null);

  const {
    loading,
    onRequest,
    data: reqData,
  } = useApi("app-settings", "patch", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return (
    <div>
      {!focused && <p onClick={() => setFocused(true)}>{initialValue}</p>}
      {focused && (
        <input
          disabled={loading}
          focused={true}
          className={styles.input}
          onChange={(e) => {
            setNewValue(e.target?.value);
          }}
          onBlur={async (e) => {
            setFocused(false);
            if (newValue) {
              await onRequest({ [property]: newValue });
              String(newValue).trim() && setInitialValue(e.target.value);
            }
          }}
          type="text"
        />
      )}
    </div>
  );
}
