import { createSlice } from "@reduxjs/toolkit";
import { getMembersAvatars } from "../services/membersAvatarsServices";

const initialState = {
  avatars: null,
  loading: true,
  error: null,
  totalBackgrounds: null,
  totalPages: null,
};

const membersAvatarsSlice = createSlice({
  name: "membersAvatars",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getMembersAvatars.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.avatars = null;
    });
    builder.addCase(getMembersAvatars.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.avatars = action.payload.data;
      state.totalBackgrounds = action.payload.totalBackgrounds;
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(getMembersAvatars.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || { message: "Error!" };
      state.avatars = null;
    });
  },
});
export const { onActiveFilter } = membersAvatarsSlice.actions;
export default membersAvatarsSlice.reducer;
