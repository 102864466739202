import React, { useState } from "react";
import { MainDropdown, MainLink } from "../..";
import styles from "./sidebar.module.scss";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../hooks/useApi";
import { useSelector } from "react-redux";

import {
  AllMembersIcon,
  BadgesIcon,
  ByManagersIcon,
  CharismaLevels,
  GiftsIcon,
  LevelsIcons,
  MembersBalanceIcon,
  MembersIcon,
  PostsIcon,
  ReportsIcon,
  RoomsIcon,
  SettingsIcon,
  ActiveRoomsIcon,
  UnactiveRoomsIcon,
  PinedRoomsIcon,
  DesignsStoreIcon,
  Coversicon,
  EffectsIcon,
  FramesIcon,
} from "../../../icons";
import { useEffect } from "react";

const Sidebar = ({ openMobileMenu }) => {
  const [storeCategories, setStoreCategories] = useState(null);
  const [storeCategoriesLinks, setStoreCategoriesLinks] = useState(null);
  const { admin } = useSelector((state) => state?.admin);

  const {
    loading: isSubmitting,
    onRequest,
    data,
  } = useApi(`admin-category-item/category/list`, "get");

  useEffect(() => {
    setTimeout(() => {
      admin?.access_token && admin?.user && onRequest();
    }, 1000);
  }, [admin]);

  useEffect(() => {
    data?.data?.length && setStoreCategories(data.data);
  }, [data]);

  useEffect(() => {
    const icons = [
      <FramesIcon />,
      <EffectsIcon />,
      <GiftsIcon />,
      <Coversicon />,
    ];
    let array = [];
    for (let i = 0; i < storeCategories?.length; i++) {
      // array.push(storeCategories[i]);
      array.push({
        label: `sidebar.${storeCategories[i].type}`,
        href: `/designs-store/${storeCategories[i].type}`,
        icon: icons[i],
      });
    }
    setStoreCategoriesLinks(array);
  }, [storeCategories]);

  useEffect(() => {}, [storeCategoriesLinks]);

  const { t } = useTranslation();

  const Links = [
    {
      label: "members_",
      icon: <MembersIcon />,
      dropdown: true,
      target: "members",
      links: [
        {
          label: "allMembers",
          href: "/members/all-members",
          icon: <AllMembersIcon />,
        },
        {
          label: "sidebar.members-balance",
          href: "/members/members-balance",
          icon: <MembersBalanceIcon />,
        },
        {
          label: "sidebar.members-avatars",
          href: "/members/members-avatars",
          icon: <PinedRoomsIcon />,
        },
      ],
    },
    {
      label: "sidebar.rooms",
      icon: <RoomsIcon />,
      dropdown: true,
      target: "rooms",
      links: [
        {
          label: "sidebar.active-rooms",
          href: "/rooms/active-rooms",
          icon: <ActiveRoomsIcon />,
        },
        {
          label: "sidebar.unactive-rooms",
          href: "/rooms/unactive-rooms",
          icon: <UnactiveRoomsIcon />,
        },
        {
          label: "sidebar.rooms_themes",
          href: "/rooms/rooms-themes",
          icon: <PinedRoomsIcon />,
        },
      ],
    },
    {
      label: "sidebar.posts",
      href: "/posts",
      icon: <PostsIcon />,
    },
    {
      label: "sidebar.gifts",
      icon: <GiftsIcon />,
      dropdown: true,
      target: "gifts",
      links: [
        {
          label: "gift's categories",
          href: "/gifts/gifts-category",
          icon: <GiftsIcon />,
        },
        {
          label: "Gifts",
          href: "/gifts/all",
          icon: <GiftsIcon />,
        },
      ],
    },
    {
      label: "sidebar.levels",
      icon: <LevelsIcons />,
      dropdown: true,
      target: "levels",
      links: [
        {
          label: "sidebar.charisma-levels",
          href: "/levels/charisma-levels",
          icon: <CharismaLevels />,
        },
        {
          label: "charge-level",
          href: "/levels/charge-levels",
          icon: <CharismaLevels />,
        },
        {
          label: "personal_level",
          href: "/levels/personal-levels",
          icon: <CharismaLevels />,
        },
      ],
    },
    {
      label: "sidebar.badges",
      icon: <BadgesIcon />,
      dropdown: true,
      target: "badges",
      links: [
        {
          label: "badges.charge",
          href: "/badges/charge",
          icon: <ByManagersIcon />,
        },
        {
          label: "badges.gifts",
          href: "/badges/gifts",
          icon: <ByManagersIcon />,
        },
        {
          label: "badges.achievement",
          href: "/badges/achievement",
          icon: <ByManagersIcon />,
        },
      ],
    },
    {
      label: "sidebar.ADS",
      href: "/ads",
      icon: <BadgesIcon />,
    },
    {
      label: "sidebar.reports",
      icon: <ReportsIcon />,
      dropdown: true,
      target: "reports",
      links: [
        {
          label: "sidebar.rooms",
          href: "/reports/rooms",
          icon: <RoomsIcon />,
        },
        {
          label: "sidebar.chats",
          href: "/reports/chats",
          icon: <AllMembersIcon />,
        },
        {
          label: "sidebar.posts",
          href: "/reports/posts",
          icon: <PostsIcon />,
        },
      ],
    },
    {
      label: "sidebar.designs-store",
      icon: <DesignsStoreIcon />,
      // href: "/designs-store",
      dropdown: true,
      target: "designs-store",
      links: storeCategoriesLinks || [],
    },
    {
      label: "sidebar.gifts-trans-history",
      href: "/gifts-history",
      icon: <GiftsIcon />,
    },

    // {
    //   label: "categories",
    //   icon: <BadgesIcon />,
    //   dropdown: true,
    //   target: "categories",
    //   links: [
    //     {
    //       label: "Entry Effect",
    //       href: "/categories/enter-effect",
    //       icon: <ByManagersIcon />,
    //     },
    //     {
    //       label: "Ride",
    //       href: "/categories/ride",
    //       icon: <ByManagersIcon />,
    //     },
    //     {
    //       label: "ChatBubble",
    //       href: "/categories/chat-bubble",
    //       icon: <ByManagersIcon />,
    //     },
    //     {
    //       label: "MicFrame",
    //       href: "/categories/mic-frame",
    //       icon: <ByManagersIcon />,
    //     },
    //   ],
    // },

    {
      label: "sidebar.settings",
      href: "/settings",
      icon: <SettingsIcon />,
    },
  ];
  return (
    <>
      <div
        className={`${styles.sidebar_container} ${
          openMobileMenu ? styles.opened : ""
        }`}
      >
        <div className={`${styles.sidebar_content}`}>
          <ul>
            {Links.map((item, i) => (
              <li key={i} className="">
                {!item?.dropdown ? (
                  <>
                    <MainLink
                      icon={item?.icon}
                      key={i}
                      label={item?.label}
                      href={item?.href}
                    />
                  </>
                ) : (
                  <MainDropdown
                    key={i}
                    i={i}
                    icon={item?.icon}
                    links={item?.links}
                    label={item?.label}
                    target={item?.target}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
