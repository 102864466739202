import React from "react";
import styles from "./.module.scss";

const TableLoading = ({ columns }) => {
  return (
    <>
      {Array(20)
        .fill("")
        .map((_, i) => (
          <tr key={i}>
            {Array(columns || 5)
              .fill("")
              .map((_, i) => (
                <td className={styles.row} key={i}>
                  <div className={`${styles.skeleton}`}></div>
                </td>
              ))}
          </tr>
        ))}
    </>
  );
};

export default TableLoading;
