import React from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const FilterBtn = ({ active, content, onClick, param }) => {
  const { t } = useTranslation();
  return (
    <button
      onClick={() => onClick(param)}
      className={`${styles.filter__btn__style} ${active && styles.active}`}
    >
      {t(`userinfo.${content}`)}
    </button>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterBtn);
