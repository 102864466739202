import { Routes, Route } from "react-router-dom";
import DashboardLayout from "./layout/DashboardLayout";
import {
  Ads,
  AllMembers,
  LevelsPage,
  Gifts,
  GiftsCategories,
  MembersBalance,
  Posts,
  EnteryEffect,
  Ride,
  ChatBubble,
  MicFrame,
  AllRooms,
  ReportsPage,
  SettingsPage,
  GiftsHistoryPage,
  RoomThemes,
  MembersInfo,
  MembersAvatars,
  RoomInfo,
  DesignStore,
  BadgesPage,
  MemberSettings,
} from "./pages";
import AuthGuard from "./components/Auth";

function App() {
  return (
    <>
      <AuthGuard>
        <Routes>
          <Route path="/" element={<DashboardLayout />}>
            {/*////////////////////////      MEMBERS      //////////////////////////// */}
            <Route path="" element={<AllMembers />} />
            <Route path="members/all-members" element={<AllMembers />} />
            <Route
              path="members/members-balance"
              element={<MembersBalance />}
            />
            <Route
              path="members/members-avatars"
              element={<MembersAvatars />}
            />
            <Route path="member/:id" element={<MembersInfo />} />
            <Route path="member/:id/permissions" element={<MemberSettings />} />
            {/*////////////////////////      ROOMS      //////////////////////////// */}
            <Route path="rooms/active-rooms" element={<AllRooms />} />
            <Route path="rooms/unactive-rooms" element={<AllRooms />} />
            <Route path="rooms/rooms-themes" element={<RoomThemes />} />
            <Route path="room/:id" element={<RoomInfo />} />
            {/*////////////////////////      POSTS      //////////////////////////// */}
            <Route path="posts" element={<Posts />} />
            {/*////////////////////////      GIFTS      //////////////////////////// */}
            <Route path="gifts/gifts-category" element={<GiftsCategories />} />
            <Route path="gifts/all" element={<Gifts />} />
            {/*////////////////////////      DESIGN STORE      //////////////////////////// */}
            <Route path="designs-store/:type" element={<DesignStore />} />
            {/*////////////////////////      LEVELS      //////////////////////////// */}
            <Route path="levels/:type" element={<LevelsPage />} />
            {/*////////////////////////      ADS      //////////////////////////// */}
            <Route path="ads" element={<Ads />} />
            {/*////////////////////////      REPORTS      //////////////////////////// */}
            <Route path="reports/:type" element={<ReportsPage />} />
            {/*////////////////////////      BADGES      //////////////////////////// */}
            <Route path="badges/:type" element={<BadgesPage />} />

            {/*////////////////////////      SETTING      //////////////////////////// */}
            <Route path="settings" element={<SettingsPage />} />
            <Route path="gifts-history" element={<GiftsHistoryPage />} />

            <Route path="categories/enter-effect" element={<EnteryEffect />} />
            <Route path="categories/ride" element={<Ride />} />
            <Route path="categories/chat-bubble" element={<ChatBubble />} />
            <Route path="categories/mic-frame" element={<MicFrame />} />
          </Route>
          {/* <Route path="/" element={<Login />} /> */}
        </Routes>
      </AuthGuard>
    </>
  );
}

export default App;
