import styles from "./.module.scss";
export default function Loading() {
  return (
    <div
      className={`d-flex flex-column gap-4`}
      style={{ overflow: "hidden", padding: "24px" }}
    >
      <div className={styles.notify_cards_container}>
        {Array(1)
          .fill("")
          .map((_, i) => (
            <div key={i}>
              <div
                className="skeleton w-100 mb-3"
                style={{ height: "180px" }}
              ></div>
              <div
                className="skeleton skeleton_text mb-4"
                style={{ width: "200px" }}
              ></div>
              <div className="d-flex align-items-center justify-content-between w-100">
                <div
                  className="skeleton"
                  style={{
                    width: "100px",
                    height: "30px",
                    borderRadius: "20px",
                  }}
                ></div>
                <div className="d-flex align-items-center gap-2">
                  <div
                    className="skeleton"
                    style={{ width: "20px", height: "30px" }}
                  ></div>
                  <div
                    className="skeleton"
                    style={{ width: "20px", height: "30px" }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
