import React from "react";
import { Button, ModalPopup } from "../..";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";
import ToggleBtn from "./toggleBtn";

const rows = [
  "hide from trend lists",
  "make as special",
  "pin room",
  "active messages",
  "lock room",
];

const UpdateMemberStatusModal = ({
  item,
  type,
  onClose,
  onDelete,
  isSubmitting,
}) => {
  const { t } = useTranslation();

  return (
    <ModalPopup title={t("Member Settings")}>
      <div className="d-flex align-items-center justify-content between gap-4">
        <div className={styles.settings__wrapper}>
          {rows.map((_) => (
            <div className={styles.settings__row}>
              <p>{_}</p>
              <ToggleBtn />
            </div>
          ))}
        </div>
      </div>
      <Button submitting={false} deleteBtn outline>
        Block This Room
      </Button>
    </ModalPopup>
  );
};

export default UpdateMemberStatusModal;
