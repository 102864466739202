import validators from "../../../util/validators";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button, DragDropFiled, Input, ModalPopup } from "../..";
import { toggleModal } from "../../../redux/features/modalSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getAds } from "../../../redux/services/adsServices";

const ADS = ({ init, id, options, setOptions }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [file, setFile] = useState(init?.path || null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formElements = [
    {
      type: "text",
      name: "nameEn",
      placeholder: t("Enter English Name"),
      label: t("English Name"),
      validate: validators.composeValidators(
        validators.required("This field is Required")
      ),
      value: init?.nameEn,
    },
    {
      type: "text",
      name: "nameAr",
      placeholder: t("Enter Arabic Name"),
      label: t("Arabic Name"),
      validate: validators.composeValidators(
        validators.required("This field is Required")
      ),
      value: init?.nameAr,
    },
    {
      type: "text",
      name: "url",
      placeholder: t("Enter url"),
      label: t("url"),
      validate: validators.composeValidators(
        validators.required("This field is Required")
      ),
      value: init?.url,
    },
  ];

  // ====== onSubmit (add or update) ====== //
  const onSubmit = async (e) => {
    const formData = new FormData();
    formData.append("url", e?.url);
    formData.append("nameEn", e?.nameEn);
    formData.append("nameAr", e?.nameAr);
    formData.append("file", e?.file);

    const requestOptions = {
      method: id ? "PUT" : "POST",
      body: formData,
      redirect: "follow",
    };

    try {
      setIsSubmitting(true);
      const res = await fetch(
        id
          ? `https://tkstg.t-wasel.com/admin-ads/update/${id}`
          : "https://tkstg.t-wasel.com/admin-ads/create",
        requestOptions
      );
      const res2 = await res?.json();
      if (res2?.statusCode === 200 || res2?.statusCode === 201) {
        // toast.success(res2?.massage || "Done");
        toast.success(
          id ? t("Saved update successfully") : t("Saved successfully")
        );
        dispatch(toggleModal(""));
        dispatch(getAds(options));
      }
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
    }
    setIsSubmitting(false);
  };

  //

  return (
    <ModalPopup title={init ? t("update ADS") : t("Add New ADS")}>
      <Form
        initialValues={init}
        onSubmit={async (e) => onSubmit({ ...e, file })}
      >
        {({ handleSubmit, _, valid }) => (
          <form className={`w-100`} onSubmit={handleSubmit}>
            <div className={` d-flex flex-column gap-5 mb-5`}>
              {formElements.map((item, i) => (
                <Field
                  key={i}
                  component={Input}
                  placeholder={item.placeholder}
                  icon={item.icon}
                  type={item.type}
                  name={item.name}
                  id={item.type}
                  label={item.label}
                  validate={item.validate}
                  initialValue={item?.value}
                />
              ))}
              <DragDropFiled
                setImage={setFile}
                image={file}
                label={t("icon")}
              />
            </div>
            <div className="d-flex align-items-center justify-content between gap-4">
              <Button
                type="submit"
                disabled={!valid || !file}
                submitting={isSubmitting}
                bgColor="#0ACF83"
              >
                {/* {t("details.save_edits")} */}
                {init ? t("details.save_edits") : t("details.add")}
              </Button>
              <Button
                onClick={() => dispatch(toggleModal(""))}
                type="button"
                outline
              >
                {t("details.cancel")}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </ModalPopup>
  );
};

export default ADS;
