import React from "react";
import styles from "./.module.scss";

const CircleImage = ({ img, alt }) => {
  return (
    <div className={styles.image_container}>
      <img
        className={styles.image}
        src={
          img ||
          "https://tse1.mm.bing.net/th?id=OIP.tvaMwK3QuFxhTYg4PSNNVAHaHa&pid=Api"
        }
        alt={alt || ""}
      />
      <img
        className={styles.img_shadow}
        src={
          img ||
          "https://tse1.mm.bing.net/th?id=OIP.tvaMwK3QuFxhTYg4PSNNVAHaHa&pid=Api"
        }
        alt="image-shadow"
      />
    </div>
  );
};

export default CircleImage;
