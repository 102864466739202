import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { allMembersSumColumns } from "../../../util/data";
import { getAllMembers } from "../../../redux/services/memberServices";
import { EditMemberBalance, Error, MainPageDesign } from "../../../components";
import { EditIcon } from "../../../icons";
import { toggleModal } from "../../../redux/features/modalSlice";

const MembersBalance = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state?.modal);
  const [currentItem, setCurrentItem] = useState(null);

  const { error, members, loading, totalPages } = useSelector(
    (state) => state?.members
  );

  const [options, setOptions] = useState({
    isPaginated: totalPages > 1,
    pagination: 1,
    itemsPerPage: 20,
    searchKeyWord: null,
    totalPages,
    sortType: null,
    sortOption: null,
  });

  useEffect(() => {
    setOptions({
      ...options,
      totalPages: totalPages,
      isPaginated: totalPages > 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, members]);

  const table = {
    columns: [
      ...allMembersSumColumns,
      {
        Header: "table.headers.action",
        accessor: "",
        Cell: (cellInfo) => (
          <button
            style={{ color: "#fcb042" }}
            onClick={() => {
              dispatch(toggleModal("update_member_balance_modal"));
              setCurrentItem(cellInfo?.row?.original);
            }}
            // className={`${styles.edit_cell} d-flex align-items-center gap-1`}
          >
            <EditIcon />
            {t("details.edit_balance")}
          </button>
        ),
      },
    ],
    data: members,
  };

  useEffect(() => {
    dispatch(getAllMembers(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.pagination,
    options.searchKeyWord,
    options.sortOption,
    options?.sortType,
  ]);

  if (error) return <Error msg={error?.message} />;

  return (
    <>
      <MainPageDesign
        searchBar
        title={t("members.members_balance.title")}
        no={`${members?.length || "0"} ${t("details.member")}`}
        data={table?.data || []}
        columns={table?.columns || []}
        tableLoading={loading}
        options={options}
        setOptions={setOptions}
      />
      {id === "update_member_balance_modal" && (
        <EditMemberBalance
          options={options}
          title={t("modal.edit_member_balance")}
          dispatch={dispatch}
          item={currentItem}
        />
      )}
    </>
  );
};

export default MembersBalance;
