import React, { useCallback } from "react";
// import Dropzone from "react-dropzone";
import { AddIcon } from "../../../icons";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";

const DragDropFiled = ({ image, setImage, label }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setImage(acceptedFiles[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".png", ".gif", ".svg", ".svga"],
    },
  });

  const { t } = useTranslation();

  // const changeImageHandler = (e) => {
  //   setImage(e[0]);
  // };

  return (
    <>
      {/* <Dropzone onDrop={changeImageHandler}> */}
      {/* {({ getRootProps, getInputProps }) => ( */}
      <div {...getRootProps({ className: "dropzone" })}>
        {image ? (
          <div>
            <label className="w-100" htmlFor="img__input">
              <div className={styles.file_details}>
                <img
                  className={styles.image}
                  src={
                    typeof image === "string"
                      ? image
                      : window.URL.createObjectURL(image) || ""
                  }
                  alt=""
                />
                <p className={styles.file_name}>
                  <span>
                    {t("change")} {label}
                  </span>{" "}
                  {image?.name || t(label)}
                </p>
              </div>
            </label>
            <input className="d-none" type="text" {...getInputProps()} />
          </div>
        ) : (
          <div className={styles.filed_container}>
            {/* <span className={styles.label}>{t("forms.add_photo")}</span> */}
            <span className={styles.label}>
              {t("add")} {label}
            </span>
            <input {...getInputProps()} />
            <div
              className={`${styles.filed_text} d-flex flex-column align-items-center gap-2`}
            >
              <span>{t("forms.drag_&_drop")}</span>
              <span>{t("details.or")}</span>
              <span className="d-flex align-items-center justify-content-center gap-2">
                <AddIcon />
                {t("forms.brows_files")}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* )} */}
      {/* </Dropzone> */}
    </>
  );
};

export default DragDropFiled;
