import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const TransactionCard = ({ transaction: { coin, type, price, id } }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    id,
    coin,
    type,
    icon: "diamond",
    content: "",
  });

  useEffect(() => {
    setData({
      ...data,
      cost: price,
      quantity: type === "sendGift" || type === "storeItem" ? -coin : coin,
      icon:
        type?.includes("Gift") || type === "dailyLogin" ? "gift" : "diamond",
      content:
        type === "sendGift"
          ? t(`userinfo.sendGift`)
          : type === "dailyLogin"
          ? t(`userinfo.dailyLogin`)
          : type === "retcharge" || type === "recharge"
          ? t(`userinfo.recharge`)
          : type === "receiveGift"
          ? t(`userinfo.receiveGift`)
          : t(`userinfo.storeItem`),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coin, price, type, t]);

  return (
    <div className={styles.transaction__card}>
      <div className={styles.transaction__details}>
        <div className={styles.transaction__title__wrapper}>
          <img width={22} src={`/assets/icons/${data.icon}.svg`} alt="icon" />
          <p className={styles.transaction__title}>{data.content}</p>
        </div>
        <div className={styles.transaction__facts}>
          <span className={styles.transaction__id}>
            {t("userinfo.id")}: {data.id}
          </span>
          {Boolean(data.cost) && (
            <span className={styles.transaction__id}>
              {t(`userinfo.cost`)}: {data.cost}$
            </span>
          )}
        </div>
      </div>
      <div
        className={`${styles.transaction__quantity} ${
          data.quantity < 0 && styles.lose
        }`}
      >{`${data.quantity < 0 ? data.quantity : `+${data.quantity}`}`}</div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionCard);
