import axios from "axios";
import config from "../config";

const instance = axios.create({
  baseURL: config.SERVER_API_URL || "https://tkstg.t-wasel.com",
  timeout: 50000,
  headers: {
    Accept: "application/json",

    Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
    "Content-Type": "application/x-www-form-urlencoded",
    // "x-api-key":
    //   "gUkXp2r5u8x/A?D(G+KbPeShVmYq3t6v9y$B&E)H@McQfTjWnZr4u7x!z%C*F-Ja",
  },
});

// Add a request interceptor
// instance.interceptors.request.use(function (config) {
//   let token;

//   if (Cookies.get("token")) {
//     token = Cookies.get("token");
//   }

//   return {
//     ...config,
//     headers: {
//       authorization: token ? `Bearer ${token}` : null,
//     },
//   };
// });

const responseBody = (response) => response.data;

const api = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody),

  post: (url, body) => instance.post(url, body).then(responseBody),

  put: (url, body, headers) =>
    instance.put(url, body, headers).then(responseBody),

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url, body) => instance.delete(url, body).then(responseBody),
};

export default api;
