import React from "react";
import styles from "./.module.scss";
import { IoIosArrowBack } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Header({ href, title }) {
  const { t } = useTranslation();
  return (
    <div className={styles.page__header}>
      <div className={styles.wrapper}>
        <Link to={href || "/members/all-members"} className={styles.back__btn}>
          <IoIosArrowBack className={styles.btn_icon} />
        </Link>
        <span>{t(`userinfo.${title}`)}</span>
      </div>
    </div>
  );
}
