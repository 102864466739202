import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { toast } from "react-toastify";

export const getBadges = createAsyncThunk(
  "badges/getBadges",
  async (
    {
      pagination,
      itemsPerPage,
      searchKeyWord,
      sortType,
      sortOption,
      badgesType,
    },
    thunkAPI
  ) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await api.get(`badges`, {
        params: {
          page: pagination,
          perPage: itemsPerPage,
          keyword: searchKeyWord,
          sortType,
          sortOption,
          type:
            String(badgesType).charAt(0).toUpperCase() +
            String(badgesType).slice(1),
        },
      });
      return res?.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Error !");
      return rejectWithValue(error?.response?.data);
    }
  }
);
