import React, { useEffect, useState } from "react";
import {
  DeleteCell,
  DeleteModal,
  EditCell,
  Error,
  MainPageDesign,
  CopyTextButton,
} from "../../components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllItems } from "../../redux/services/categoriesServices";
import { toggleModal } from "../../redux/features/modalSlice";
import { toast } from "react-toastify";
import { useApi } from "../../hooks/useApi";
import ADS from "../../components/Modals/ADS";
import { getAds } from "../../redux/services/adsServices";
import { FaLink } from "react-icons/fa";
import { Link } from "react-router-dom";

const Ride = () => {
  const { t } = useTranslation();
  const [currentItem, setCurrentItem] = useState(null);
  const dispatch = useDispatch();

  const {
    totalPages,
    error,
    ads,
    loading: tableLoading,
  } = useSelector((state) => state?.ads);

  const [options, setOptions] = useState({
    isPaginated: totalPages > 1,
    pagination: 1,
    itemsPerPage: 20,
    searchKeyWord: null,
    totalPages,
    sortType: null,
    sortOption: null,
  });

  useEffect(() => {
    setOptions({
      ...options,
      totalPages: totalPages,
      isPaginated: totalPages > 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, ads]);

  useEffect(() => {
    dispatch(getAds(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.pagination,
    options.searchKeyWord,
    options.sortOption,
    options?.sortType,
  ]);

  const { id } = useSelector((state) => state?.modal);

  useEffect(() => {
    if (!id) {
      setCurrentItem(null);
    }
  }, [id]);

  const table = {
    columns: [
      {
        Header: "table.headers.id",
        accessor: "random_id",
        sortKey: "id",
        sorting: true,
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center jsutify-content-center gap-2">
            <CopyTextButton text={cellInfo?.row?.original?.id || "---"} />
          </div>
        ),
      },
      {
        Header: "Name En",
        accessor: "nameEn",
        sorting: true,
        sortKey: "name",
      },
      {
        Header: "Name Ar",
        accessor: "nameAr",
        sorting: true,
        sortKey: "nameAr",
      },
      {
        Header: "Image",
        accessor: "path",
        Cell: (cellInfo) => {
          return (
            <>
              <img
                style={{
                  width: "64px",
                  height: "100%",
                  objectFit: "contain",
                  borderRadius: "4px",
                }}
                src={cellInfo?.row?.original?.path}
                alt=""
              />
              {/* <Svga className={"svga"} src={svgaIcon} /> */}
            </>
          );
        },
      },
      {
        Header: "table.headers.url",
        accessor: "",
        Cell: (cellInfo) => (
          <div
            style={{ fontSize: "1.5rem", color: "#1363df" }}
            className="d-flex align-items-center justify-content-center gap-2"
          >
            <Link target="_blank" to={cellInfo?.row?.original?.url}>
              <FaLink />
            </Link>
          </div>
        ),
      },
      {
        Header: "table.headers.action",
        accessor: "",
        Cell: (cellInfo) => (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <EditCell
              onClick={() => {
                setCurrentItem(cellInfo?.row?.original);
                dispatch(toggleModal("category-item-modal"));
              }}
            />
            <DeleteCell
              onClick={() => {
                setCurrentItem(cellInfo?.row?.original);
                dispatch(toggleModal("delete-modal"));
              }}
            />
          </div>
        ),
      },
    ],
    data: ads,
  };

  // onDelete
  const {
    loading: isSubmitting,
    onRequest,
    data,
  } = useApi(`admin-ads/delete/${currentItem?.id}`, "delete");

  useEffect(() => {
    if (data?.error) {
      toast.error(data?.error || "something wend wrong!");
      return;
    }
    if (data) {
      toast.success(data?.message || t("deleted successfully"));
      dispatch(toggleModal(""));
      dispatch(getAllItems());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error msg={error?.message} />;

  return (
    <>
      <MainPageDesign
        title={t("ads")}
        no={`${table?.data?.length || "0"} ${t("details.ads")}`}
        searchBar
        addButton
        data={table?.data || []}
        columns={table?.columns || []}
        onOpenPopup={() => dispatch(toggleModal("category-item-modal"))}
        tableLoading={tableLoading}
        options={options || {}}
        setOptions={setOptions}
        // ========================
      />
      {id === "delete-modal" && (
        <DeleteModal
          options={options}
          setOptions={setOptions}
          dispatch={dispatch}
          action={onRequest}
          afterAction={() => {
            setOptions({ ...options, pagination: 1 });
            dispatch(getAds(options));
          }}
          itemID={currentItem?.id}
          isSubmitting={isSubmitting}
          type={t("ad")}
          text={t("Doy You Really Want To Kick Out This AD?")}
          onClose={() => dispatch(toggleModal(""))}
        />
      )}
      {id === "category-item-modal" && (
        <ADS
          options={options}
          setOptions={setOptions}
          init={currentItem}
          id={currentItem?.id}
        />
      )}
    </>
  );
};

export default Ride;
