import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { toast } from "react-toastify";

export const getAllMembers = createAsyncThunk(
  "members/getAllMembers",
  async (
    { pagination, itemsPerPage, searchKeyWord, sortType, sortOption },
    thunkAPI
  ) => {
    const { rejectWithValue } = thunkAPI;
    //
    // ?${new URLSearchParams(data).toString()}
    //
    try {
      const res = await api.get(`/members/get-all-members`, {
        params: {
          page: pagination,
          perPage: itemsPerPage,
          keyword: searchKeyWord,
          sortType: sortType,
          sortOption: sortOption,
        },
      });
      // const res = await api.get(
      //   `/members/get-all-members?page=${pagination}&perPage=${itemsPerPage}${
      //     searchKeyWord ? `&keyword=${searchKeyWord}` : null
      //   }${sortOption ? `&sortOption=${sortOption}` : null}${
      //     sortType ? `&sortType=${sortType}` : null
      //   }
      //   `
      // );
      return res.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Error !");
      return rejectWithValue(error?.response?.data);
    }
  }
);
